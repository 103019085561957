import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../Styles/style";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import Iframe from "../../../../../../components/Models/IframeModal/Iframe";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { languagesData } from "../../../../../../assets/defaultLanguage";
import {
  MenuItem,
  Select,
  FormHelperText,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import QRCode from "qrcode.react";
import copyIcon from "../../../../../../assets/Icons/copyIcon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpIcon from "@mui/icons-material/Help";
import { Dialog } from "@material-ui/core";

import * as XLSX from "xlsx";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Step3 = ({
  stepperData,
  setStepperData,
  integrationChecked,
  setIntegrationChecked,
  importEmailChecked,
  setImportEmailChecked,
  setFormitableChecked,
  formitableChecked,
  uploadFileChecked,
  setUploadFileChecked,
  directInputChecked,
  setDirectInputChecked,
  languageData,
  setLanguageData,
  languageById,
  campaignTypeById,
  dataArray,
  setDataArray,
  totalEmails,
  id,
  campaignEmails,
  setCampaignEmails,
  setSelectedFile,
  selectedFile,
  qrCodeChecked,
  setQRCodeChecked,
  locationLoading,
  getLocationById,
  singleLocation,
  locationId,
  formitableDisabled,
  checkClicked,
}) => {
  const { t } = useTranslation();
  const landingURL = process.env.REACT_APP_LANDING_BASE_URL;
  const classes = useStyles();
  const textArea = useRef();

  const [languageError, setLanguageError] = useState(false);
  const fileInputRef = useRef(null);
  const [displayText, setDisplayText] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [displayCopiedText, setDisplayCopiedText] = useState(false);
  const [connectionFound, setConnectionFound] = useState(false);
  const [openIframeModel, setOpenIframeModel] = useState(false);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const currentUrl = window.location.href;

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setDisplayText(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isVisible]);

  useEffect(() => {
    if (singleLocation !== null) {
      if (singleLocation?.socialLink?.length > 0) {
        const formitableConnection = singleLocation?.socialLink?.find(
          (item) => item?.type === "formitable"
        );
        if (
          formitableConnection !== undefined &&
          formitableConnection !== null
        ) {
          setConnectionFound(true);
        } else {
          setConnectionFound(false);
        }
      } else {
        setConnectionFound(false);
      }
    }
  }, [singleLocation]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        let data;
        if (file.type === "text/csv") {
          const lines = fileContents.split("\n");
          data = lines.map((line) => line.replace("\r", "").split(","));
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const workbook = XLSX.read(fileContents, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          data = [];
          sheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            data.push(...jsonData);
          });
        } else {
          return;
        }
        const flatData = data.flat();

        setDataArray(flatData);
      };
      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        reader.readAsBinaryString(file);
      }
      setSelectedFile(file.name);
    } else {
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleLanguageChange = (event) => {
    setLanguageData(event.target.value);
    setLanguageError(false);
    setStepperData({
      ...stepperData,
      language: event.target.value,
    });
  };

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
    // if (callApi === true) {
    //   handleClose();
    //   getAllCampaigns();
    // }
  };

  const copyQRCodeLink = () => {
    const devQueryParam =
      process.env.REACT_APP_NODE_ENV === "development" ||
      process.env.REACT_APP_NODE_ENV === "beta"
        ? "?dev=true"
        : "";
    navigator.clipboard.writeText(
      `${landingURL}/${stepperData?.language}/campaign-qr/${id}/${devQueryParam}`
    );
    setDisplayText(true);
    setIsVisible(true);
  };

  const handleBlurLanguageData = () => {
    if (!languageData || languageData.length === 0) {
      setLanguageError(true);
    }
  };

  const handleCampaignTypeCheck = (event) => {
    switch (event.target.name) {
      // case "integration":
      //   setIntegrationChecked(event.target.checked);
      //   setImportEmailChecked(false);
      //   break;
      case "qr-code":
        setQRCodeChecked(true);
        setImportEmailChecked(false);
        setFormitableChecked(false);
        break;
      case "import-email":
        setImportEmailChecked(true);
        setQRCodeChecked(false);
        setFormitableChecked(false);
        break;
      case "formitable":
        setFormitableChecked(true);
        setQRCodeChecked(false);
        setImportEmailChecked(false);
        break;

      default:
        break;
    }
    setStepperData({
      ...stepperData,
      campaign_type: event.target.name,
    });
  };

  useEffect(() => {
    if (stepperData?.campaign_type === "import-email") {
      setImportEmailChecked(true);
      setQRCodeChecked(false);
    } else if (stepperData?.campaign_type === "formitable") {
      setQRCodeChecked(false);
      setImportEmailChecked(false);
      setFormitableChecked(true);
    } else {
      setQRCodeChecked(true);
      setImportEmailChecked(false);
    }

    setStepperData((prevData) => ({
      ...prevData,
      campaign_type: stepperData?.campaign_type,
    }));
  }, []);

  useEffect(() => {
    setDirectInputChecked(true);
  }, []);
  const handleOpenHelpModel = () => {
    setOpenIframeModel(true);
  };
  const handleCloseHelpModel = () => {
    setOpenIframeModel(false);
  };
  const handleCampaignEmails = (event) => {
    switch (event.target.name) {
      case "upload-file":
        setUploadFileChecked(event.target.checked);
        if (event.target.checked === false) {
          setDataArray([]);
          setSelectedFile(null);
        }
        // setDirectInputChecked(false);
        break;
      case "direct-input":
        setDirectInputChecked(event.target.checked);
        // setUploadFileChecked(false);
        break;

      default:
        break;
    }
  };

  const handleCampaignEmailsChange = (value) => {
    // const { value } = event.target;
    const formattedValue = value.replace(/,\s*/g, ",\n");
    setCampaignEmails(formattedValue);

    const emailsArray = formattedValue.split(",");
    const validEmailsArray = [];

    // Validate each email address and push the valid ones into the array
    for (let i = 0; i < emailsArray.length; i++) {
      const email = emailsArray[i].trim();
      if (isValidEmail(email)) {
        validEmailsArray.push(email);
      }
    }

    setStepperData({
      ...stepperData,
      campaignDataUserEmails: validEmailsArray.map((email) => email.trim()),
      validEmailCounter: validEmailsArray.length,
    });
  };

  const isValidEmail = (email) => {
    // Use a regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRemoveFile = () => {
    setDataArray([]);
    setSelectedFile(null);

    setUploadFileChecked(false);
  };

  return (
    <>
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseHelpModel}
        >
          {i18nextLng === "es" ? (
            <Iframe
              title={t("Review_Req_Tutorial")}
              onCancel={handleCloseHelpModel}
              iframe={
                <iframe
                  src="https://ask.obenan.com/fnsxugdcu"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="1200px"
                  height="500px"
                  onLoad={() => {}}
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
              }
            />
          ) : (
            <Iframe
              title={t("Review_Req_Tutorial")}
              onCancel={handleCloseHelpModel}
              iframe={
                <iframe
                  title="iframe"
                  loading="lazy"
                  src="https://ask.obenan.com/fnsxugdcu"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="1200px"
                  height="500px"
                  onLoad={() => {}}
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                />
              }
            />
          )}
        </Dialog>
      )}
      <Grid container component={Paper} sx={{ padding: "16px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ paddingTop: "16px" }}>
              <Typography className={classes.stepLocation}>
                {t("Recipients")}
                <span style={{ color: "#FC3652" }}>*</span>
              </Typography>
              <Typography className={classes.stepLocationSubhead}>
                {t("Recipients_subhead")}
              </Typography>
            </Box>
            {/* {id !== undefined && (
              <Box>
                <CommonButton
                  label={"Get QR"}
                  onSubmit={() => setOpenQRModal(true)}
                />
              </Box>
            )} */}
          </Box>
          <Box sx={{ paddingBottom: "13px" }}>
            <Grid container spacing={2} sx={{ display: "flex" }}>
              {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box
                  className={classes.socialBoxFeedback}
                  sx={{ height: "116px" }}
                >
                  <Checkbox
                    sx={{ marginTop: "4px" }}
                    checked={integrationChecked}
                    onChange={handleCampaignTypeCheck}
                    name="integration"
                    color="primary"
                  />
                  <Box sx={{ display: "block" }}>
                    <Typography className={classes.selectText}>
                      {t("Formitable_reservation")}
                    </Typography>
                    <Typography className={classes.selectsubText}>
                      {t("Formitable_reservation_subhead")}
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={6}
                md={locationId?.isFormitableEnabled ? 6 : 6}
                lg={locationId?.isFormitableEnabled ? 4 : 6}
                className={classes.socialBoxFeedbackStep3}
              >
                <Grid container>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Checkbox
                      sx={{ marginTop: "4px", padding: "0px" }}
                      checked={qrCodeChecked}
                      onChange={handleCampaignTypeCheck}
                      name="qr-code"
                      color="primary"
                      disabled={
                        id !== undefined &&
                        currentUrl.includes("duplicate") === false
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={11} lg={11}>
                    <Typography
                      className={classes.selectText}
                      sx={{
                        color:
                          id !== undefined &&
                          currentUrl.includes("duplicate") === false &&
                          qrCodeChecked === false
                            ? "gray !important"
                            : "#1B2430",
                      }}
                    >
                      {t("generate_qr_code")}
                    </Typography>
                    <Typography
                      className={classes.selectsubText}
                      sx={{
                        color:
                          id !== undefined &&
                          currentUrl.includes("duplicate") === false &&
                          qrCodeChecked === false
                            ? "gray !important"
                            : "#1B2430",
                      }}
                    >
                      {t("qr_subHead")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={locationId?.isFormitableEnabled ? 6 : 6}
                lg={locationId?.isFormitableEnabled ? 4 : 6}
                className={classes.socialBoxFeedbackStep3}
              >
                <Grid container>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Checkbox
                      sx={{ marginTop: "4px", padding: "0px" }}
                      checked={importEmailChecked}
                      onChange={handleCampaignTypeCheck}
                      name="import-email"
                      color="primary"
                      disabled={
                        id !== undefined &&
                        currentUrl.includes("duplicate") === false
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={11} lg={11}>
                    <Typography
                      className={classes.selectText}
                      sx={{
                        color:
                          id !== undefined &&
                          currentUrl.includes("duplicate") === false &&
                          importEmailChecked === false
                            ? "gray !important"
                            : "#1B2430",
                      }}
                    >
                      {t("Import_email_address")}
                    </Typography>
                    <Typography
                      className={classes.selectsubText}
                      sx={{
                        color:
                          id !== undefined &&
                          currentUrl.includes("duplicate") === false &&
                          importEmailChecked === false
                            ? "gray !important"
                            : "#1B2430",
                      }}
                    >
                      {t("Import_email_address_subhead")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {locationId?.isFormitableEnabled && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  className={classes.socialBoxFeedbackStep3}
                >
                  <Grid container>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                      <Checkbox
                        sx={{ marginTop: "4px", padding: "0px" }}
                        checked={formitableChecked}
                        onChange={handleCampaignTypeCheck}
                        name="formitable"
                        color="primary"
                        disabled={
                          formitableDisabled
                            ? true
                            : id !== undefined &&
                              currentUrl.includes("duplicate") === false
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10.5} lg={10.5}>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          className={classes.selectText}
                          sx={{
                            color:
                              id !== undefined &&
                              currentUrl.includes("duplicate") === false &&
                              formitableChecked === false
                                ? "gray !important"
                                : "#1B2430",
                          }}
                        >
                          Formitable
                        </Typography>
                        {/*  {formitableDisabled &&
                          id === undefined &&
                          currentUrl.includes("duplicate") === false && ( */}
                        <BootstrapTooltip
                          title={`${t("formitable_text3")} Formitable ${t(
                            "formitable_text4"
                          )} ${singleLocation?.internalName} ${t(
                            "formitable_text2"
                          )}`}
                        >
                          <ErrorOutlineIcon sx={{ marginLeft: "5px" }} />
                        </BootstrapTooltip>
                        {/*  )} */}
                      </Box>

                      <Typography
                        className={classes.selectsubText}
                        sx={{
                          color:
                            id !== undefined &&
                            currentUrl.includes("duplicate") === false &&
                            formitableChecked === false
                              ? "gray !important"
                              : "#1B2430",
                        }}
                      >
                        {`${t(
                          "Formitable_reservation_subheadOne"
                        )} Formitable ${t(
                          "Formitable_reservation_subheadTwo"
                        )}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
                      <BootstrapTooltip title={t("Help")}>
                        <HelpIcon
                          fontSize="small"
                          onClick={() => handleOpenHelpModel()}
                          sx={{ color: "#06BDFF", cursor: "pointer" }}
                        />
                      </BootstrapTooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>

          {importEmailChecked && (
            <Box sx={{ paddingY: "26px" }}>
              <Typography
                className={classes.stepLocation}
                sx={{ marginBottom: "12px" }}
              >
                {t("list_of_mails")}
                <span style={{ color: "#FC3652" }}>*</span>
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Box className={classes.socialBoxFeedback}>
                    <Checkbox
                      checked={uploadFileChecked}
                      onChange={handleCampaignEmails}
                      sx={{ marginTop: "4px" }}
                      name="upload-file"
                      color="primary"
                      disabled={
                        id !== undefined &&
                        currentUrl.includes("duplicate") === false
                          ? true
                          : false
                      }
                    />

                    <Typography className={classes.selectsubText}>
                      {t("Upload_own_file")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Box className={classes.socialBoxFeedback}>
                    <Checkbox
                      checked={directInputChecked}
                      onChange={handleCampaignEmails}
                      sx={{ marginTop: "4px" }}
                      name="direct-input"
                      color="primary"
                      disabled={
                        id !== undefined &&
                        currentUrl.includes("duplicate") === false
                          ? true
                          : false
                      }
                    />

                    <Typography className={classes.selectsubText}>
                      {t("Direct_Input")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {uploadFileChecked === true && (
                <Box>
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />

                  <Box mt={3}>
                    <CommonButton
                      onSubmit={handleButtonClick}
                      label={t("Browse_File")}
                    />
                  </Box>
                  <Box ml={3}>
                    {selectedFile ? (
                      <Box sx={{ display: "flex", paddingTop: "8px" }}>
                        <Typography>{selectedFile}</Typography>{" "}
                        <IconButton
                          sx={{
                            cursor: "pointer",
                            width: "9px",
                            height: "9px",
                            marginLeft: "8px",
                            marginTop: "2px",
                          }}
                          onClick={() => handleRemoveFile()}
                        >
                          <DeleteIcon
                            fontSize="small"
                            sx={{ color: "#FF1616" }}
                          />
                        </IconButton>
                      </Box>
                    ) : (
                      <p>{t("No_file_chosen")}</p>
                    )}
                  </Box>
                </Box>
              )}
              {directInputChecked === true &&
                (id === undefined || currentUrl.includes("duplicate")) && (
                  <>
                    <Box mt={3}>
                      <TextField
                        id="outlined-basic"
                        multiline
                        rows={5.8}
                        maxRows={10}
                        ref={textArea}
                        variant="outlined"
                        placeholder={t("Enter_email_here")}
                        sx={{ marginBottom: "12px" }}
                        value={campaignEmails}
                        fullWidth
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            handleCampaignEmailsChange(
                              event.target.value + ","
                            );
                          }
                        }}
                        onChange={(e) =>
                          handleCampaignEmailsChange(e.target.value)
                        }
                      />
                    </Box>
                    <Box mb={3}>{`${stepperData?.validEmailCounter} ${t(
                      "validEmailText"
                    )}`}</Box>
                    <Box className={classes.reviewsubHeadBox}>
                      {totalEmails > 5000 ? (
                        <Typography className={classes.reviewsubHeadTextError}>
                          {t("max_upload_text")}
                        </Typography>
                      ) : (
                        <Typography className={classes.reviewsubHeadText}>
                          {t("review_reques_email_subhead") +
                            " " +
                            totalEmails +
                            " " +
                            t("email_addresses")}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
            </Box>
          )}

          {qrCodeChecked &&
            (id === undefined || currentUrl.includes("duplicate")) && (
              <Box
                sx={{
                  width: "100%",
                  height: "44px",
                  paddingY: "26px",
                  backgroundColor: "#E0F7FF",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <Box>
                  <InfoOutlinedIcon
                    sx={{
                      color: "#06BDFF",
                      marginLeft: "15px",
                      marginTop: "5px",
                    }}
                  />
                </Box>
                <Box sx={{ marginLeft: "15px" }}>
                  <Typography>{t("qr_message")}</Typography>
                </Box>
              </Box>
            )}

          {formitableChecked && locationId?.isFormitableEnabled && (
            <Box sx={{ paddingY: "26px" }}>
              <Typography
                className={classes.stepLocation}
                sx={{
                  color:
                    id !== undefined &&
                    currentUrl.includes("duplicate") === false &&
                    importEmailChecked === false
                      ? "gray !important"
                      : "#1B2430",
                  marginBottom: "12px",
                }}
              >
                {t("place_id")}
              </Typography>

              <Typography
                className={classes.stepLocationSubhead}
                sx={{
                  color:
                    id !== undefined &&
                    currentUrl.includes("duplicate") === false &&
                    importEmailChecked === false
                      ? "gray !important"
                      : "#1B2430",
                }}
              >
                {t("place_id_subHeading")}
              </Typography>

              <Box
                // className={
                //   id===undefined
                //     ? classes.formitableBox
                //     : classes.formitableBoxDisabled
                // }

                className={classes.formitableBox}
              >
                <Typography
                  sx={{
                    // color: id !== undefined ? "gray !important" : "#1B2430",
                    color: "#1B2430",
                  }}
                >
                  {singleLocation !== null ? singleLocation?.placeId : ""}
                </Typography>
                <Box
                  className={
                    // id===undefined ? classes.copyBox : classes.copyBoxDisabled
                    classes.copyBox
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(
                      singleLocation !== null ? singleLocation?.placeId : ""
                    );
                    setDisplayCopiedText(true);
                    setTimeout(() => {
                      window.open("https://app.formitable.com/apps", "_blank");
                    }, 400);

                    setTimeout(() => {
                      setDisplayCopiedText(false);
                    }, 3000);
                  }}
                >
                  <ContentCopyIcon
                    // className={classes.copyIconStyle}
                    fontSize="small"
                    sx={{
                      // color: id===undefined ? "#06BDFF" : "gray"
                      color: "#06BDFF",
                    }}
                  />
                  <Typography
                    className={
                      // id===undefined
                      //   ? classes.copyTextStyle
                      //   : classes.copyTextStyleDisabled

                      classes.copyTextStyle
                    }
                  >
                    {displayCopiedText ? t("Copied") : t("copy_text")}{" "}
                  </Typography>
                </Box>
              </Box>

              {connectionFound ? (
                <>
                  <Button
                    //  onClick={createReviewSource}
                    className={
                      // id===undefined
                      //   ? classes.connectedButton
                      //   : classes.connectedButtonDisabled
                      classes.connectedButton
                    }
                    // disabled={id !== undefined}
                  >
                    <CheckCircleOutlineIcon
                      fontSize="small"
                      sx={{
                        color:
                          (id === undefined ||
                            currentUrl.includes("duplicate")) &&
                          "#FFFFFF",
                        marginRight: "3px",
                      }}
                    />
                    {t("connected_Text")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      getLocationById(locationId, true);
                    }}
                    className={
                      // id===undefined
                      //   ? classes.checkConnectionButton
                      //   : classes.checkConnectionButtonDisabled
                      classes.checkConnectionButton
                    }
                    // disabled={id !== undefined}
                  >
                    {locationLoading ? (
                      <CircularProgress
                        style={{
                          color: "#FFFF",
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    ) : (
                      t("check_Connection")
                    )}
                  </Button>

                  {checkClicked && (
                    <Box
                      sx={{
                        width: "100%",
                        height: "44px",
                        paddingY: "26px",
                        backgroundColor: "#FFF1D7",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        marginTop: "14PX",
                      }}
                    >
                      <Box>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#FAB431",
                            marginLeft: "15px",
                            marginTop: "5px",
                          }}
                        />
                      </Box>
                      <Box sx={{ marginLeft: "15px" }}>
                        <Typography>
                          {t("no_connection_formitable_message")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}

          <Box sx={{ paddingY: "16px" }}>
            <Typography className={classes.stepLocation}>
              {t("Choose_language")}
              <span style={{ color: "#FC3652" }}>*</span>
            </Typography>
            <Typography className={classes.stepLocationSubhead}>
              {t("Choose_language_review")}
            </Typography>

            <Select
              required
              // defaultValue={id===undefined && "English"}
              style={{ width: "100%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={languageData}
              onChange={handleLanguageChange}
              onBlur={handleBlurLanguageData}
              // disabled={id !== undefined ? true : false}
            >
              {languagesData.map((item) => (
                <MenuItem value={item.value}>{item.language}</MenuItem>
              ))}
            </Select>
            {languageError && (
              <FormHelperText sx={{ color: "red" }}>
                {t("This_field_is_required")}
              </FormHelperText>
            )}
          </Box>
          {id !== undefined &&
            currentUrl.includes("duplicate") === false &&
            stepperData?.campaign_type === "qr-code" && (
              //           const devQueryParam =
              // process.env.REACT_APP_NODE_ENV === "development" ||
              // process.env.REACT_APP_NODE_ENV === "beta"
              //   ? "?dev=true"
              //   : "";
              <>
                <Box mt={5}>
                  <Box>
                    <QRCode
                      id="qrCodeEl"
                      size={250}
                      value={`${landingURL}/${
                        stepperData?.language
                      }/campaign-qr/${id}${
                        process.env.REACT_APP_NODE_ENV === "development" ||
                        process.env.REACT_APP_NODE_ENV === "beta"
                          ? "?dev=true"
                          : ""
                      }`}
                    />
                  </Box>

                  <Box
                    sx={{
                      marginTop: "15px",
                      marginBottom: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ marginLeft: "-10px" }}>
                      <CommonButton
                        label={t("download_now")}
                        onSubmit={downloadQRCode}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                      onClick={copyQRCodeLink}
                    >
                      <Box sx={{ marginTop: "3px" }}>
                        <img src={copyIcon} />
                      </Box>
                      <Typography
                        sx={{
                          color: "#06BDFF",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginLeft: "7px",
                        }}
                      >
                        {"Copy QR link"}
                      </Typography>
                      {displayText && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "gray",
                            lineHeight: "15px",
                            paddingLeft: "10px",
                            fontWeight: 500,

                            display: isVisible ? "block" : "none",
                          }}
                        >
                          {t("Copied")}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
        </Grid>
      </Grid>
      {/* <QRCodeModal
        open={openQRModal}
        setOpenQRModal={setOpenQRModal}
        campaignQRId={id}
      /> */}
    </>
  );
};

export default Step3;
