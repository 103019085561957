import React, { useRef, useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from "recharts";
import {
  Grid,
  Typography,
  Box,
  Autocomplete,
  Tooltip as MuiToolTip,
  tooltipClasses,
  TextField,
} from "@mui/material";
import { useStyles } from "../../Style/style";
import { useTranslation } from "react-i18next";
import NoDataFound from "./NoDataFound/NoDataFoundOnlineReputation";
import { WaveSpinner } from "../../../../../components/Loaders/wavaloader";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import dayjs from "dayjs";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <MuiToolTip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const GroupedChart = ({
  startDate,
  endDate,
  downloadFormat,
  setDownloadFormat,
  reviewAverageRatingData,
  reviewAverageRatingLoading,
  ReviewsAndAverageRatingRef,
  setFeaturesObj,
  featuresObj,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const gridItemRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(null);
  const [reviewTooltipData, setReviewTooltipData] = useState(null);
  const [ratingSortedData, setRatingSortedData] = useState([]);
  const [downloadValue, setDownloadValue] = useState(null);
  const [maxReviews, setMaxReviews] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);
  useEffect(() => {
    if (reviewAverageRatingData?.length > 0) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const durationInMonths = end.diff(start, "days");
      setDuration(durationInMonths);
      let sortedData = [];

      if (durationInMonths <= 60) {
        sortedData = reviewAverageRatingData?.sort((a, b) => {
          const dateA = new Date(a.dateOfAverage);
          const dateB = new Date(b.dateOfAverage);
          return dateA - dateB;
        });
      } else {
        sortedData = reviewAverageRatingData.sort((a, b) => {
          const [yearA, monthA, dayA] = a.dateOfAverage.split("-").map(Number);
          const [yearB, monthB, dayB] = b.dateOfAverage.split("-").map(Number);

          if (yearA !== yearB) {
            return yearA - yearB;
          } else if (monthA !== monthB) {
            return monthA - monthB;
          } else {
            return dayA - dayB;
          }
        });
      }

      const groupedData = handleGroupedData(sortedData);
      const maxReviews = Math.max(...groupedData.map((item) => item.reviews));
      setMaxReviews(maxReviews);
      setRatingSortedData(groupedData);
    }
  }, [reviewAverageRatingData]);

  useEffect(() => {
    if (gridItemRef.current) {
      const gridItemWidth = gridItemRef.current.clientWidth;

      const newChartWidth = gridItemWidth - 20;

      setChartWidth(newChartWidth);
    }
  }, [ratingSortedData]);

  const handleGroupedData = (data) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const durationInMonths = end.diff(start, "days");
    const groupedData = data.reduce((acc, item) => {
      let xAxisLabel = "";

      if (!item.dateOfAverage) {
        return acc;
      }

      if (durationInMonths > 60) {
        const [year, month] = item.dateOfAverage.split("-");

        const monthYear = new Date(`${year}-${month}-01`).toLocaleString(
          "default",
          {
            month: "short",
            year: "numeric",
          }
        );
        xAxisLabel = monthYear;
      } else {
        const [year, month, day] = item.dateOfAverage.split("-");
        const date = new Date(`${year}-${month}-${day}`);
        if (!isNaN(date.getTime())) {
          const monthName = date.toLocaleString("default", {
            month: "short",
          });
          xAxisLabel = `${day} ${monthName} ${year.split("-")[0].slice(2)}`;
        }
      }

      if (xAxisLabel) {
        if (item?.hasOwnProperty("locationId")) {
          if (!acc[xAxisLabel]) {
            acc[xAxisLabel] = {
              xAxisLabel,
              locationId: item?.locationId,
              reviews: 0,
              count: 0,
              sumRating: 0,
              avgRating: 0,
            };
          }
        } else {
          if (!acc[xAxisLabel]) {
            acc[xAxisLabel] = {
              xAxisLabel,
              groupId: item?.groupId,
              reviews: 0,
              count: 0,
              sumRating: 0,
              avgRating: 0,
            };
          }
        }

        acc[xAxisLabel].count++;
        acc[xAxisLabel].sumRating += item.rating || 0;
        acc[xAxisLabel].reviews += item.reviews;
      }

      return acc;
    }, {});

    Object.values(groupedData).forEach((item) => {
      if (item.count > 0) {
        item.avgRating = item.sumRating / item.count;
      }
    });
    return Object.values(groupedData);
  };

  const handleChartMouseEnter = (event) => {
    if (event && event.activeTooltipIndex !== -1) {
      const dataIndex = event.activeTooltipIndex;
      setReviewTooltipData(ratingSortedData[dataIndex]);
    }
  };

  const handleKeywordsDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "reviewsRatingGraph") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];

  const calculateTicks = () => {
    const tickInterval =
      (maxReviews === 0 ? 1 : maxReviews - 0) /
      (ratingSortedData?.length === 1 ? 1 : ratingSortedData?.length - 1);
    const ticks = [];
    for (let i = 0; i < ratingSortedData?.length; i++) {
      const tickValue = 0 + i * tickInterval;
      ticks.push(Math.round(tickValue));
    }
    if (ticks?.length === 1 && ticks[0] === 0) {
      let arr = [0, 1];
      let removeDuplicates = [...new Set(arr)];
      return removeDuplicates;
    } else {
      let removeDuplicates = [...new Set(ticks)];
      return removeDuplicates;
    }
  };

  return (
    <Grid
      container
      className={classes.mainContainer}
      ref={ReviewsAndAverageRatingRef}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container mb={3}>
          <Grid item xs={9.7} sm={9.7} md={9.7} lg={9.7}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.subHeadRatingReviewDetail}>
                {t("Graph_Reviews")}
              </Typography>
              <BootstrapTooltip
                title={t("Reviews_and_Average_Rating_Graph_Tooltip")}
              >
                <Typography
                  sx={{
                    marginLeft: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>
          <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
            <Autocomplete
              id="tags-outlined"
              value={downloadValue}
              options={downloadOptions}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              onChange={handleKeywordsDownloadChange}
              renderInput={(params) => (
                <TextField {...params} placeholder={t("actionss")} />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      {reviewAverageRatingLoading ? (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "300px",
            alignItems: "center",
            borderLeft: "1px solid #9d9d9d",
            borderBottom: "1px solid #9d9d9d",
            paddingX: "8px",
          }}
        >
          <WaveSpinner />
        </Box>
      ) : reviewAverageRatingData?.length > 0 &&
        ratingSortedData?.length > 0 ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} ref={gridItemRef}>
            {chartWidth && (
              <LineChart
                width={chartWidth}
                height={300}
                data={ratingSortedData}
                ref={chartRef}
                onMouseMove={handleChartMouseEnter}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="xAxisLabel"
                  type="category"
                  interval={duration <= 60 ? 5 : 0}
                />
                <YAxis yAxisId="left" orientation="left" domain={[1, 5]} />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  domain={[0, maxReviews]}
                  ticks={calculateTicks()}
                />
                {console.log(calculateTicks(), "calculateTicks")}

                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        reviewTooltipData !== null && (
                          <div className={classes.customTooltip}>
                            <Typography className={classes.toolTipStyleReview}>
                              {reviewTooltipData !== null &&
                                reviewTooltipData?.xAxisLabel}
                            </Typography>
                            <Typography className={classes.toolTipStyleReview}>
                              {`${t("Reviews")}: ${
                                reviewTooltipData !== null &&
                                reviewTooltipData?.reviews
                              }`}
                            </Typography>
                            <Typography className={classes.toolTipStyleReview}>
                              {`${t("ratings")}: ${
                                reviewTooltipData !== null &&
                                reviewTooltipData?.avgRating?.toFixed(2)
                              }`}
                            </Typography>
                          </div>
                        )
                      );
                    }

                    return null;
                  }}
                />

                <Legend />

                <Line
                  type="monotone"
                  dataKey="avgRating"
                  name={t("ratings")}
                  yAxisId="left"
                  stroke="#13CF8F"
                  dot={{ strokeWidth: 4, fill: "#13CF8F" }}
                />
                <Line
                  type="monotone"
                  dataKey="reviews"
                  name={t("Reviews")}
                  yAxisId="right"
                  stroke="#F8A92B"
                  dot={{ strokeWidth: 4, fill: "#F8A92B" }}
                />
              </LineChart>
            )}
          </Grid>
        </Grid>
      ) : (
        <NoDataFound />
      )}
    </Grid>
  );
};

export default GroupedChart;
