import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import TitleHeader from "../../../components/CustomComponents/title";
import LocationNotFound from "../../../components/CustomComponents/Errors/NoLocationFound";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Grid, Box, Dialog, Tooltip } from "@mui/material";
import { useStyles } from "./Style/style";
import EmotionAiRequestModel from "../../../components/Models/DeleteModal/EmotionAiModel";
import Delete from "../../../components/Models/DeleteModal/Delete";
import useAuth from "../../../hooks/useAuth";
import FilterHeader from "./Components/FilterHeader";
import axios from "axios";
import Review from "./Components/ReviewSummary";
import ReviewsDetail from "./Components/ReviewsDetail";
import SentimentsNotFound from "../../../components/CustomComponents/Errors/NoSentiment";
import ActionPlans from "./Components/ActionPlans";
import Loader from "../../../components/Loaders/Loader";
import ReviewModel from "../../../components/Models/DeleteModal/ReviewModel";
import EmailReportModal from "./Components/ExportDashboard/Modals/EmailReportModal";
import ExportFileDownloadModal from "./Components/ExportDashboard/Modals/ExportFileDownloadModal";
import FetchEmailsModel from "./Components/ExportDashboard/Modals/FetchEmailModal";
import LocationGroupsModel from "../../../components/Models/DeleteModal/LocationGroupsModel";
import SecheduledReports from "../../../components/Models/DeleteModal/SecheluedReports";
import SecheduleReport from "../../../components/Models/DeleteModal/ScheduleReport";
import QuestionMark from "../../../assets/Icons/Question.svg";
import { countriesData } from "../../../assets/countriesList";
import { languageOptions } from "../../../assets/defaultLanguage";

import Iframe from "../../../components/Models/IframeModal/Iframe";

const today = new Date(new Date().setHours(23, 59, 59, 999));

const previousYear = new Date(
  today.getFullYear() - 1,
  today.getMonth(),
  today.getDate()
);
var milliseconds = moment.duration(
  moment(today).diff(previousYear),
  "milliseconds"
);
const nextStart = moment(previousYear).subtract(milliseconds);
const newwww = new Date(nextStart);

const Index = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signOut, permissionsAcess, user } = useAuth();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [allLocations, setAllLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [range, setRange] = useState({
    label: `${t("In_Range")}`,
    value: "between",
  });
  const [compareRange, setCompareRange] = useState({
    label: `${t("In_Range")}`,
    value: "between",
  });
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [startDate, setStartDate] = useState(previousYear);
  const [endDate, setEndDate] = useState(today);
  const [nextStartDate, setNextStartDate] = useState(newwww);
  const [nextEndDate, setnextEndDate] = useState(previousYear);
  const [filterObj, setFilterObj] = useState({});
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [googleReviewData, setGoogleReviewData] = useState([]);
  const [cancelTokenSources, setCancelTokenSources] = useState([]);
  const [ratingVarianceLoading, setRatingVarianceLoading] = useState(false);
  const [ratingVarianceData, setRatingVaranceData] = useState([]);
  const [reviewVelocityData, setReviewVelocityData] = useState([]);
  const [platformOverviewLoading, setPlatformOverviewLoading] = useState(false);
  const [platformOverviewData, setPlatformOverviewData] = useState(null);
  const [platformData, setPlatformData] = useState(null);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [ratingBreakDownData, setRatingBreakdownData] = useState([]);
  const [keywordsLoading, setKeywordsLoading] = useState(false);
  const [reputationLoading, setReputationLoading] = useState(false);
  const [onlineReputationData, setOnlineReputationData] = useState([]);
  const [reviewTopics, setReviewTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [keywordsData, setKeywordsData] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [openRequest, setOpenRequest] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [topicsLoading, setTopicLoading] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageSchedular, setLanguageSchedular] = useState("");
  const [actionPlans, setActionPlans] = useState([]);
  const [actionPlansLoading, setActionPlansLoading] = useState(false);
  const [openReviewModel, setOpenReviewModel] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filterBreakdownLocation, setFilterBreakdownLocation] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [emoationAIDetailsLoading, setEmotionAIDetailsLoading] =
    useState(false);
  const [emotionAIDetailsData, setEmotionAIDetailsData] = useState([]);
  const [varianceType, setVarianceType] = useState("");
  const [thirdPartySourceId, setThirdPartySourceId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [ratingTeXTKeywords, setRatingTextKeywords] = useState("");
  const [ratingBreakdownValue, setRatingBreakdownValue] = useState("");
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [apiCallsFrom, setApiCallFrom] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [platformLocations, setPlatformLocations] = useState([]);
  const [platformGroups, setPlatformGroups] = useState([]);
  const [fromPlatform, setFromPlatform] = useState(null);
  const [keywordsSentiments, setKeywordsSentiment] = useState("");
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  const [selectAllGroups, setSelectAllGroups] = useState(false);
  const [reviewsTitle, setReviewsTitle] = useState(null);
  const [allReviewSource, setAllReviewSource] = useState([]);
  const [typeParam, setTypeParam] = useState(false);
  const [openCompareModel, setOpenCompareModel] = useState(false);
  const [openReportEmailModal, setOpenReportEmailModal] = useState(false);
  const [companyUser, setCompanyUser] = useState(true);
  const [others, setOthers] = useState(false);
  const [prevTooltipContent, setPrevTooltipContent] = useState("");
  const [pdfChecked, setPdfChecked] = useState(true);
  const [xlsChecked, setXlsChecked] = useState(false);
  const [pdfDownloadChecked, setPdfDownloadChecked] = useState(true);
  const [xlsDownloadChecked, setXlsDownloadChecked] = useState(false);
  const [openIframeModel, setOpenIframeModel] = useState(false);
  const [locationGroups, setLocationGroups] = useState(null);
  const languageData = localStorage.getItem("i18nextLng");
  const stateData = JSON.parse(localStorage.getItem("user"));
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [recipientError, setRecipientError] = useState(true);
  const [recipientEmailError, setRecipientEmailError] = useState(true);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientValue, setRecipientValue] = useState([]);
  const [displayReportError, setDisplayReportError] = useState(false);
  const [openDownloadPdfModal, setOpenDownloadPdfModal] = useState(false);
  const emailReportRef = useRef(null);
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
  const [addNewUserEmials, setAddNewUserEmails] = useState("");
  const [validEmailsArray, setValidEmailsArray] = useState([]);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [totalEmailsArr, setTotalEmailsArr] = useState([]);
  const [totalModalEmailsArr, setTotalModalEmailsArr] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllCheckedModal, setSelectAllCheckedModal] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalEmailsSent, setIsModalEmailsSent] = useState(true);
  const [exportReportLoading, setExportReportLoading] = useState(false);
  const [autoSelectHeader, setAutoSelectHeader] = useState(false);
  const [emailSubject, setEmailSubject] = useState(
    `EmotionAI report of ${companyData?.name}`
  );
  const [yesChecked, setYesChecked] = useState(true);
  const [noChecked, setNoChecked] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState("");
  const [emailBody, setEmailBody] = useState(
    `Dear customer Please find attached emotion AI report for the ${dayjs(
      startDate
    ).format("MMM DD, YYYY")} to ${dayjs(endDate).format("MMM DD, YYYY")}`
  );
  const [groupsLoading, setGroupsLoading] = useState(false);

  const [reviewDistributionData, setReviewDistributionData] = useState([]);
  const [reviewDistributionTableData, setReviewDistributionTableData] =
    useState([]);

  const [reviewDistributionLoading, setReviewDistributionLoading] = useState(
    []
  );
  const [reviewAverageRatingData, setReviewAverageRatingData] = useState([]);
  const [reviewAverageRatingLoading, setReviewAverageRatingLoading] = useState(
    []
  );
  const [locationViewPermission, setLocationViewPermission] = useState(false);
  const [locationAddPermission, setLocationAddPermission] = useState(false);

  const [groupsReviewsData, setGroupsReviewsData] = useState([]);
  const [groupsActionPlansData, setGroupsActionPlansData] = useState([]);
  const [groupsReviewsFromatedData, setGroupsReviewsFromatedData] = useState(
    []
  );
  const [groupsPageNumber, setGroupsPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scheduledRportsPageNumber, setScheduledRportsPageNumber] = useState(0);
  const [rowsPerPageScheduled, setRowsPerPageScheduled] = useState(10);
  const [groupsActionPlansFromatedData, setGroupsActionPlansFromatedData] =
    useState([]);
  const [openLocationGroupsModel, setOpenLocationGroupsModel] = useState(false);
  const [openSchduleModal, setOpenSchduleModal] = useState(false);
  const [openCreateSchedule, setOpenCreateSchedule] = useState(false);
  const [scheduleReportLoading, setScheduleReportLoading] = useState(false);
  const [repeatValue, setRepeatValue] = useState("no");
  const [scheduledReportsLoading, setScheduledReportsLoading] = useState(false);
  const [scheduledReports, setScheduledReports] = useState(null);
  const [openDeleteScheduledReportModel, setOpenDeleteScheduledReportModel] =
    useState();
  const [deletedIds, setDeletedId] = useState(null);
  const [deleteScheduleReportLoading, setDeleteScheduleReportLoading] =
    useState(false);
  const [singleReportData, setSingleReportData] = useState(null);
  const [selectedEditId, setSelectedEditId] = useState(null);
  const [updateReportLoading, setUpdateReportLoading] = useState();
  const [emailTitle, setEmailTitle] = useState("");
  const [startDateRangeSchedule, setStartDateRangeSchedule] =
    useState(startDate);
  const [endDateRangeSchedule, setEndDateRangeSchedule] = useState(endDate);
  const [nextStartDateSchedule, setNextStartDateSchedule] =
    useState(nextStartDate);
  const [nextEndDateSchedule, setNextEndDateSchedule] = useState(nextEndDate);
  const [scheduleLocations, setScheduleLocations] = useState(locations);
  const [scheduleGroups, setScheduleGroups] = useState(selectedGroups);
  const [scheduleLanguage, setScheduleLanguage] = useState("");
  const [sentimentValue, setSentimentValue] = React.useState(null);
  const [scheduleSentiments, setScheduleSentiments] = useState(sentimentValue);
  const [startDateSchedule, setStartDateSchedule] = useState(null);
  const [endDateSchedule, setEndDateSchedule] = useState(null);
  const [dateRange, setDateRange] = useState("custom");
  const [openUpdateStatusModel, setOpenUpdateStatusModel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [pauseValue, setPauseValue] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [reportFormat, setReportFormat] = useState("pdf");
  const [reviewsChecked, setReviewsChecked] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [callApi, setCallApi] = useState(false);
  const [callApiFirstTime, setCallApiFirstTime] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [images, setImages] = useState([]);
  const [sectionOption, setSectionOption] = useState({
    id: 1,
    value: "completeReport",
    title: t("complete_ai_report"),
  });
  const [callTranslatedApis, setCallTranslatedApis] = useState(false);
  const [sectionFormat, setSectionFormat] = useState("");
  const [fromHeader, setFromHeader] = useState(false);
  const [topicsData, setTopicsData] = useState([]);
  const [topicsDataLoading, setTopicsDataLoading] = useState(false);
  const [featuresObj, setFeaturesObj] = useState({
    ratingVariance: true,
    reviewsRatingGraph: true,
    onlineReputation: true,
    platformOverview: true,
    reviewDistribution: true,
    ratingBreakdown: true,
    emotionAITopics: true,
    reviewVelocity: true,
    actionPlan: true,
    latestImpression: true,
    keywordCloud: true,
    ratingBreakdownReviewReplied: true,
  });

  const [countries, setCountries] = useState([]);
  const [oldGroups, setOldGroups] = useState([]);
  const [reportScheduleLocations, setReportScheduleLocations] = useState([]);
  const [selectAllLocations, setSelectAllLocations] = useState(false);
  const [selectedTopicName, setSelectedTopicName] = useState(null);

  const featuresArray = [
    {
      id: 1,
      name: t("Graph_Reviews"),
      key: "reviewsRatingGraph",
    },
    {
      id: 2,
      name: t("rating_variance"),
      key: "ratingVariance",
    },
    {
      id: 3,
      name: t("online_reputation"),
      key: "emotionAITopics",
    },
    /*  {
      id: 4,
      name: t("online_reputation"),
      key: "onlineReputation",
    }, */
    {
      id: 5,
      name: t("platform_overview"),
      key: "platformOverview",
    },

    {
      id: 6,
      name: t("Review_Distribution"),
      key: "reviewDistribution",
    },
    {
      id: 7,
      name: t("reviews_rating_breakdown"),
      key: "ratingBreakdown",
    },
    {
      id: 8,
      name: t("replies_rating_breakdown"),
      key: "ratingBreakdownReviewReplied",
    },
    {
      id: 9,
      name: t("review_velocity"),
      key: "reviewVelocity",
    },
    {
      id: 10,
      name: t("keywords_cloud"),
      key: "keywordCloud",
    },
    {
      id: 11,
      name: t("latest_impression"),
      key: "latestImpression",
    },
    {
      id: 12,
      name: t("action_planss"),
      key: "actionPlan",
    },
  ];
  const featuresArrayWithoutTopics = [
    {
      id: 1,
      name: t("Graph_Reviews"),
      key: "reviewsRatingGraph",
    },
    {
      id: 2,
      name: t("rating_variance"),
      key: "ratingVariance",
    },

    {
      id: 3,
      name: t("online_reputation"),
      key: "onlineReputation",
    },
    {
      id: 4,
      name: t("platform_overview"),
      key: "platformOverview",
    },

    {
      id: 5,
      name: t("Review_Distribution"),
      key: "reviewDistribution",
    },

    {
      id: 6,
      name: t("reviews_rating_breakdown"),
      key: "ratingBreakdown",
    },
    {
      id: 7,
      name: t("replies_rating_breakdown"),
      key: "ratingBreakdownReviewReplied",
    },
    {
      id: 8,
      name: t("review_velocity"),
      key: "reviewVelocity",
    },
    {
      id: 9,
      name: t("keywords_cloud"),
      key: "keywordCloud",
    },
    {
      id: 10,
      name: t("latest_impression"),
      key: "latestImpression",
    },
    {
      id: 11,
      name: t("action_planss"),
      key: "actionPlan",
    },
  ];

  const manageSectionOptions = [
    { id: 1, value: "completeReport", title: t("complete_ai_report") },
    { id: 2, value: "specificReport", title: t("specific_section") },
  ];

  useEffect(() => {
    if (openLocationGroupsModel === true) {
      getAllLocationGroups();
    }
  }, [openLocationGroupsModel, groupsPageNumber, rowsPerPage]);

  useEffect(() => {
    if (openSchduleModal === true) {
      getAllScheduledReports();
    }
  }, [scheduledRportsPageNumber, rowsPerPageScheduled, openSchduleModal]);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/emotionAi"
      );
      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
      let filteredLocationPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredLocationPermissions?.map((item) => {
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationViewPermission(true);
        }
        if (item?.permissionName === "create") {
          setLocationAddPermission(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (
      (languageData !== undefined && languageData !== "en") ||
      (stateData !== undefined && stateData?.language === null)
    ) {
      LanguageChange();
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (locations?.length > 0 && callApi === true) {
        locations?.map((item) => {
          getLocationReviewSummary(item?.id);
          getActionPlansData(item?.id);
        });
      }
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [callApi]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (locations?.length > 0 && callTranslatedApis === true) {
        locations?.map((item) => {
          getLocationReviewSummary(item?.id);
          getActionPlansData(item?.id);
        });
      }
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [callTranslatedApis]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (selectedGroups?.length > 0 && callApi === true) {
        selectedGroups?.map((item) => {
          getLocationGroupByIdForSummary(item?.id);
          getLocationGroupByIdForActionPlan(item?.id);
        });
      }
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [callApi]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (selectedGroups?.length > 0 && callTranslatedApis === true) {
        selectedGroups?.map((item) => {
          getLocationGroupByIdForSummary(item?.id);
          getLocationGroupByIdForActionPlan(item?.id);
        });
      }
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [callTranslatedApis]);
  useEffect(() => {
    window.addEventListener("languageChange", getTranslatedLatestImpressions);

    return () => {
      window.removeEventListener(
        "languageChange",
        getTranslatedLatestImpressions
      );
    };
  }, []);

  useEffect(() => {
    if (downloadFormat === "download") {
      handleOpenExportFileDownloadModal(false);
    } else if (downloadFormat === "email") {
      handleOpenReportEmailModal(false);
    } else if (downloadFormat === "schedule") {
      handleOpenScheduleModal();
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (groupsReviewsData?.length > 0) {
      const groupedData = {};
      groupsReviewsData.forEach((item) => {
        const { groupId, ...rest } = item;
        if (!groupedData[groupId]) {
          groupedData[groupId] = {
            groupId: groupId,
            groups: [],
          };
        }
        groupedData[groupId].groups.push(rest);
      });
      const result = Object.values(groupedData);
      setGroupsReviewsFromatedData(result);
    }
  }, [groupsReviewsData]);

  useEffect(() => {
    if (groupsActionPlansData?.length > 0) {
      const groupedData = {};
      groupsActionPlansData.forEach((item) => {
        const { groupId, ...rest } = item;
        if (!groupedData[groupId]) {
          groupedData[groupId] = {
            groupId: groupId,
            groups: [],
          };
        }
        groupedData[groupId].groups.push(rest);
      });

      const result = Object.values(groupedData);
      setGroupsActionPlansFromatedData(result);
    }
  }, [groupsActionPlansData]);

  useEffect(() => {
    if (
      callApi === true &&
      range !== null &&
      (selectedGroups?.length > 0 || locations?.length > 0) &&
      startDate !== null &&
      endDate !== null
    ) {
      if (
        companyData !== undefined &&
        companyData !== null &&
        companyData?.id === 175
      ) {
        getTopicsData();
      }
      getLocationRatingVariance();
      getLocationRatingPlatformOveriew();
      getLocationRatingBreakdown();
      if (
        companyData !== undefined &&
        companyData !== null &&
        companyData?.id !== 175
      ) {
        getOnlineReputationData();
      }
      getReviewDistibutionData();
      getReviewDistibutionDataForTable();
      getReviewAndAverageRatingData();
      getKeywordsData();
    }
  }, [callApi]);

  useEffect(() => {
    if (
      callApiFirstTime === true &&
      allLocations?.length > 0 &&
      range !== null &&
      (selectedGroups?.length > 0 || locations?.length > 0) &&
      startDate !== null &&
      endDate !== null
    ) {
      getLocationRatingVariance();
      getLocationRatingPlatformOveriew();
      getLocationRatingBreakdown();
      if (
        companyData !== undefined &&
        companyData !== null &&
        companyData?.id !== 175
      ) {
        getOnlineReputationData();
      }
      getReviewDistibutionData();
      getReviewDistibutionDataForTable();
      getReviewAndAverageRatingData();
      getKeywordsData();
      if (
        companyData !== undefined &&
        companyData !== null &&
        companyData?.id === 175
      ) {
        getTopicsData();
      }

      getLocationReviewSummary(locations[0]?.id);
      getActionPlansData(locations[0]?.id);
    }
    return () => setCallApiFirstTime(false);
  }, [callApiFirstTime]);

  useEffect(() => {
    if (
      range !== null &&
      (selectedGroups?.length > 0 || locations?.length > 0) &&
      startDate !== null &&
      endDate !== null
    ) {
      setScheduleGroups(selectedGroups);
      setScheduleLanguage(language);
      setScheduleLocations(locations);
      setScheduleSentiments(sentimentValue);
      setStartDateRangeSchedule(startDate);
      setEndDateRangeSchedule(endDate);
      setNextStartDateSchedule(nextStartDate);
      setNextEndDateSchedule(nextEndDate);
      setEmailSubject(`EmotionAI report of ${companyData?.name}`);
      setEmailBody(
        `Dear customer Please find attached emotion AI report for the ${dayjs(
          startDate
        ).format("MMM DD, YYYY")} to ${dayjs(endDate).format("MMM DD, YYYY")}`
      );
    }
  }, [
    locations,
    startDate,
    endDate,
    sentimentValue,
    language,
    selectedGroups,
    nextEndDate,
    nextStartDate,
    compareRange,
  ]);

  useEffect(() => {
    if (startDate !== null && endDate !== null && selectedTopic !== null) {
      if (
        companyData !== undefined &&
        companyData !== null &&
        companyData?.id !== 175
      ) {
        getOnlineReputationData();
      }
    }
  }, [selectedTopic]);

  useEffect(() => {
    getSelectedCustomer();
    getAllLocations();
    getAllGroups();
    getAllThirdPartySource();
  }, []);

  useEffect(() => {
    const combinedArray = [...selectedItems, ...validEmailsArray];

    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
  }, [selectedItems, validEmailsArray]);

  useEffect(() => {
    // Combine the arrays
    const combinedArray = [...dataArray];
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );
    setSelectedItems(distinctEmails);
    setTotalModalEmailsArr(distinctEmails);
  }, [dataArray]);

  useEffect(() => {
    const nextEndDate = startDate;
    var milliseconds = moment.duration(
      dayjs(endDate).diff(dayjs(startDate)),
      "milliseconds"
    );
    const nextDate = dayjs(startDate).subtract(milliseconds);

    const parsedNextStartDate = dayjs(nextStartDate);
    const previousNextStartDate = parsedNextStartDate.subtract(1, "day");
    const formattedPreviousNextStartDate =
      dayjs(nextStartDate).format("MMM DD, YYYY");

    const parsedStartDate = dayjs(startDate);
    const previousStartDate = parsedStartDate.subtract(1, "day");
    const formattedPreviousStartDate = previousStartDate.format("MMM DD, YYYY");
    const tooltipContentVs = `vs ${formattedPreviousNextStartDate} - ${formattedPreviousStartDate}`;
    setTooltipContent(tooltipContentVs);
  }, [startDate, endDate, nextStartDate]);

  useEffect(() => {
    const parsedStartDate = dayjs(nextStartDate);
    const previousStartDate = parsedStartDate.subtract(1, "day");
    const formattedPreviousStartDate =
      dayjs(nextStartDate).format("MMM DD, YYYY");

    //   const formattedStartDate = dayjs(nextStartDate).format("MMM DD, YYYY");
    const formattedEndDate = dayjs(nextEndDate).format("MMM DD, YYYY");
    const parsedEndDate = dayjs(nextEndDate);
    const previousEndDate = parsedEndDate.subtract(1, "day");
    const formattedPreviousEndDate = previousEndDate.format("MMM DD, YYYY");

    const tooltipContent = `From ${formattedPreviousStartDate} to ${formattedPreviousEndDate}`;
    setPrevTooltipContent(tooltipContent);
  }, [nextStartDate, nextEndDate]);

  function monthDiff(dateFrom, dateTo) {
    // Convert dayjs objects to native Date objects
    const fromDate = dateFrom instanceof dayjs ? dateFrom.toDate() : dateFrom;
    const toDate = dateTo instanceof dayjs ? dateTo.toDate() : dateTo;

    return (
      toDate.getMonth() -
      fromDate.getMonth() +
      12 * (toDate.getFullYear() - fromDate.getFullYear())
    );
  }

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      let startDateOnee = dayjs(startDate);
      const startDateOne = startDateOnee.subtract(1, "days");
      const endDateOne = dayjs(endDate);
      // const endDateOne = endDateOnee.subtract(1, "days");
      const today = dayjs();

      if (
        (range !== null && range?.value === "gt") ||
        (compareRange !== null && compareRange?.value === "gt")
      ) {
        const difference = startDateOnee.diff(today);

        const diffInMonths = monthDiff(startDateOnee, today);

        if (diffInMonths === 12 || diffInMonths > 12) {
          const newStartDate = startDateOne.subtract(1, "year");
          setNextStartDate(newStartDate.toDate());
        } else {
          const diffInDays = today.diff(startDateOnee, "days");

          if (diffInMonths < 12 || diffInDays < 30) {
            if (diffInMonths === 0 && diffInDays < 30) {
              const newStartDate = startDateOne.subtract(diffInDays, "days");
              setNextStartDate(newStartDate.toDate());
            } else {
              const newStartDate = startDateOne.subtract(
                diffInMonths,
                "months"
              );
              setNextStartDate(newStartDate.toDate());
            }
          }
        }

        setnextEndDate(startDateOnee.toDate());
      } else if (
        (range !== null && range?.value === "lt") ||
        (compareRange !== null && compareRange?.value === "lt")
      ) {
        const diff = monthDiff(endDateOne, today);

        if (diff === 12 || diff > 12) {
          const newEndDate = endDateOne.add(1, "year");
          setnextEndDate(newEndDate.toDate());
        } else {
          setnextEndDate(today.toDate());
        }

        setNextStartDate(endDateOne.toDate());
      } else {
        const diffInMonths = monthDiff(startDateOnee, endDateOne);
        const diffInDays = endDateOne.diff(startDateOnee, "days");
        if (diffInMonths < 12 || diffInDays > 0) {
          if (diffInDays > 0) {
            const newStartDate = startDateOne.subtract(diffInDays, "days");
            setNextStartDate(newStartDate.toDate());
            setnextEndDate(startDateOne.endOf("day").toDate());
          } else {
            const newStartDate = startDateOne.subtract(diffInMonths, "months");

            setNextStartDate(newStartDate.toDate());
            setnextEndDate(startDateOnee.toDate());
          }
        } else {
          const yearsToSubtract = Math.floor(diffInMonths / 12);
          const remainingMonths = diffInMonths % 12;

          const newStartDate = startDateOne
            .subtract(yearsToSubtract, "year")
            .subtract(remainingMonths, "months");
          setNextStartDate(newStartDate.toDate());
          setnextEndDate(startDateOnee.toDate());
        }
      }
    }
  }, [startDate, endDate]);

  /*  useEffect(() => {
    if (range !== null && compareRange !== null) {
      setStartDate(previousYear);
      setEndDate(today);
      setNextStartDate(newwww);
      setnextEndDate(previousYear);
    }
  }, [compareRange, range]); */

  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
        if (res?.data.data?.emotionAIStaticTopics === true) {
          let arr = ["Service", "Food", "Value", "Environment"];
          setReviewTopics(arr);
        }
      }
    } catch (error) {}
  };

  const getTranslatedLatestImpressions = () => {
    setGroupsReviewsData([]);
    setGroupsReviewsFromatedData([]);
    setGroupsActionPlansData([]);
    setGroupsActionPlansFromatedData([]);
    setGoogleReviewData([]);
    setActionPlans([]);

    setCallTranslatedApis(true);

    setTimeout(() => {
      setCallTranslatedApis(false);
    }, 5000);
  };
  const LanguageChange = async () => {
    let userLng = "";
    Object.keys(languageOptions).find((item) => {
      userLng = languageOptions[`${languageData}`]?.name;
    });

    try {
      const res = await api.patch(`/user/${user.id}`, {
        firstName: stateData?.firstName,
        lastName: stateData?.lastName,
        email: stateData?.email,
        language: userLng,
        role: stateData?.role,
      });

      if (res.status === 200) {
        localStorage.setItem("user", JSON.stringify(res.data.data));

        const UpdatedLanguage = Object.keys(languageOptions).find(
          (item) =>
            languageOptions[`${item}`]?.name === res?.data?.data?.language
        );
        //     toast.success(t("Language_updated_successfully"));

        localStorage.setItem("i18nextLng", UpdatedLanguage);
      }
    } catch (error) {}
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllLocationGroups = async () => {
    setGroupsLoading(true);
    try {
      const res = await api.get(
        `groups?pageNumber=${groupsPageNumber + 1}&limit=${rowsPerPage}`
      );
      if (res.status === 200) {
        setLocationGroups(res?.data?.data);
        setGroupsLoading(false);
      }
    } catch (error) {
      setGroupsLoading(false);
      setLocationGroups(null);
    }
  };

  const getAllScheduledReports = async () => {
    setScheduledReportsLoading(true);
    try {
      const res = await api.get(
        `reportSchedules?page=${
          scheduledRportsPageNumber + 1
        }&limit=${rowsPerPageScheduled}&locationIds=&reportType=EmotionAI`
      );
      if (res.status === 200) {
        setScheduledReports(res?.data?.data);
        setScheduledReportsLoading(false);
      }
    } catch (error) {
      setScheduledReportsLoading(false);
      setScheduledReports(null);
    }
  };
  const getSingleSelectedReportById = async () => {
    if (selectedEditId !== null) {
      try {
        const res = await api.get(`reportSchedules/${selectedEditId}`);
        if (res.status === 200) {
          setSingleReportData(res?.data?.data);
        }
      } catch (error) {
        setSingleReportData(null);
      }
    }
  };

  const handleDownloadEmotionAiReport = async () => {
    let startDateNew = dayjs(startDateRangeSchedule);
    let startDatee = new Date(startDateNew);
    let endDateNew = dayjs(endDateRangeSchedule);
    let endDatee = new Date(endDateNew);

    try {
      setExportReportLoading(true);

      let locationIds = [];
      if (scheduleLocations?.length > 0) {
        scheduleLocations?.map((item) => {
          locationIds.push(item?.id);
        });
      }
      let groupIds = [];
      if (scheduleGroups?.length > 0) {
        scheduleGroups?.map((item) => {
          groupIds.push(item?.id);
        });
      }
      let filterObj = {
        locationIds: locationIds,
        groupIds: groupIds,
        endDate: endDatee,
        startDate: startDatee,
        dateRange: dateRange,
      };

      if (scheduleSentiments !== null) {
        filterObj["sentimentAnalysis"] = scheduleSentiments?.value;
        // filterObj["sentimentValue"] = scheduleSentiments?.value;
      }
      if (scheduleLanguage !== null && scheduleLanguage !== "") {
        filterObj["languageCode"] = scheduleLanguage?.value;
      }
      let tempFeatureObj = featuresObj;
      if (companyData?.id !== 175) {
        tempFeatureObj.emotionAITopics = false;
      } else {
        tempFeatureObj.onlineReputation = false;
      }

      const res = await api.post("/emotionAIExports", {
        filter: filterObj,
        groupIds: groupIds,
        locationIds: locationIds,
        endDate: endDatee,
        startDate: startDatee,
        fileType:
          pdfDownloadChecked && xlsDownloadChecked
            ? "both"
            : pdfDownloadChecked && xlsDownloadChecked === false
            ? "pdf"
            : "xlsx",
        includeReviews: reviewsChecked,
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        includedSections: tempFeatureObj,
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
      });

      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          res?.data?.data?.forEach((item) => {
            window.open(item?.report);
          });
        }

        toast.success("Emotion AI report downloaded!");
        setOpenDownloadPdfModal(false);
        setExportReportLoading(false);
        handleResetEmailModel();
        handleCloseEmailReportModal();
        setDownloadFormat("");
        handleResetScheduleFiltersModel();
        setPdfDownloadChecked(true);
        setXlsDownloadChecked(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setExportReportLoading(false);
    }
  };

  const handleEmailEmotionAiReport = async () => {
    try {
      setExportReportLoading(true);
      let startDateNew = dayjs(startDateRangeSchedule);
      let startDatee = new Date(startDateNew);
      let endDateNew = dayjs(endDateRangeSchedule);
      let endDatee = new Date(endDateNew);

      let locationIds = [];
      if (scheduleLocations?.length > 0) {
        scheduleLocations?.map((item) => {
          locationIds.push(item?.id);
        });
      }
      let groupIds = [];
      if (scheduleGroups?.length > 0) {
        scheduleGroups?.map((item) => {
          groupIds.push(item?.id);
        });
      }
      let filterObj = {
        locationIds: locationIds,
        groupIds: groupIds,
        endDate: endDatee,
        startDate: startDatee,
        dateRange: dateRange,
      };

      if (scheduleSentiments !== null) {
        filterObj["sentimentAnalysis"] = scheduleSentiments?.value;
        //  filterObj["sentimentValue"] = scheduleSentiments?.value;
      }
      if (scheduleLanguage !== null && scheduleLanguage !== "") {
        filterObj["languageCode"] = scheduleLanguage?.value;
      }

      let emails = [...totalEmailsArr];

      if (selectedCompanyUsers?.length > 0) {
        selectedCompanyUsers?.map((item) => {
          emails.push(item?.User?.email);
        });
      }
      let tempFeatureObj = featuresObj;
      if (companyData?.id !== 175) {
        tempFeatureObj.emotionAITopics = false;
      } else {
        tempFeatureObj.onlineReputation = false;
      }

      const res = await api.post("/emotionAIExports", {
        filter: filterObj,
        groupIds: groupIds,
        locationIds: locationIds,
        endDate: endDatee,
        startDate: startDatee,
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        emails: emails?.toString(),
        subject: emailSubject,
        body: emailBody,
        includeReviews: reviewsChecked,
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        includedSections: tempFeatureObj,
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
      });

      if (res.status === 200) {
        setExportReportLoading(false);
        handleResetEmailModel();
        handleCloseEmailReportModal();
        setDownloadFormat("");
        handleResetScheduleFiltersModel();
        setPdfDownloadChecked(true);
        setXlsDownloadChecked(false);
        toast.success("User will receive email shortly", {
          autoClose: 7000,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setExportReportLoading(false);
    }
  };

  const getAllLocations = async () => {
    setLocationLoading(true);

    try {
      const res = await api.get(
        `sentimentalAnalysis/location/allLocationWithStatus/${
          companyData !== undefined && companyData !== null
            ? companyData?.id
            : user?.companyId
        }`
      );
      if (res.status === 200) {
        setLocationLoading(false);
        setAllLocations(res?.data?.message);
        if (res?.data?.message.length > 0) {
          const filteredLocations = res?.data?.message?.filter(
            (item) => item?.statusOfSentimentalAnalysis === "done"
          );

          if (filteredLocations?.length > 0) {
            const defaultlocation = filteredLocations?.find(
              (item, index) => item.defaultLocationId !== null
            );
            if (defaultlocation !== null && defaultlocation !== undefined) {
              setLocations([defaultlocation]);
              setDefaultLocation(defaultlocation);
            } else {
              setLocations([filteredLocations[0]]);
              setDefaultLocation(filteredLocations[0]);
            }

            setDisplayError(false);
          } else {
            setDisplayError(true);
          }

          const countryWiseLocations = getCountryWiseLocations(
            countriesData,
            res?.data?.message
          );

          setCountries(countryWiseLocations);
          setCallApiFirstTime(true);
        }
      }
    } catch (error) {
      setLocationLoading(false);
      setLocations([]);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  console.log(countries, "countries");
  const getCountryWiseLocations = (countriesData, locationsArray) => {
    return countriesData
      .map((country) => {
        const locations = locationsArray
          .filter(
            (location) =>
              location.countryCode === country.code ||
              location.country === country.name
          )
          .map((location) => location);
        return {
          countryName: country.name,
          countryCode: country.code,
          locationsArray: locations,
        };
      })
      .filter((country) => country.locationsArray.length > 0);
  };
  const getAllGroups = async () => {
    try {
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);
        setSelectAll(false);
      }
    } catch (error) {
      setAllGroups(null);
      setSelectAll(false);
    }
  };
  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  const getActionPlansData = async (locationId) => {
    try {
      setActionPlansLoading(true);
      const response = await api.get(
        `actionPlans/action-plan-google?id=${locationId}`
      );

      if (response.status === 200) {
        let obj = {
          locationId: locationId,
          response: response?.data?.data,
        };
        actionPlans?.push(obj);

        setActionPlans([...actionPlans]);
        if (selectedGroups?.length === 0) {
          setActionPlansLoading(false);
        }
        //
      }
    } catch (error) {
      setActionPlansLoading(false);
    }
  };

  const getLocationReviewSummary = async (locationId) => {
    const newCancelTokenSource = addCancelTokenSource();

    try {
      setSummaryLoading(true);
      const response = await api.get(
        `sumrizedReviews/summrize-review-google?id=${locationId}`,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (response.status === 200) {
        let obj = {
          locationId: locationId,
          response: response?.data?.data,
        };
        googleReviewData?.push(obj);

        setGoogleReviewData([...googleReviewData]);
        if (selectedGroups?.length === 0) {
          setSummaryLoading(false);
        }
        //
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setGoogleReviewData(null);
        setSummaryLoading(false);
      } else {
        setGoogleReviewData(null);
        setSummaryLoading(false);
      }
      setGoogleReviewData(null);
      setSummaryLoading(false);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getLocationReviewSummaryForGroups = async (locationId, groupId) => {
    const newCancelTokenSource = addCancelTokenSource();

    try {
      setSummaryLoading(true);
      const response = await api.get(
        `sumrizedReviews/summrize-review-google?id=${locationId}`,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (response.status === 200) {
        let obj = {
          groupId: groupId,
          locationId: locationId,
          response: response?.data?.data,
        };

        groupsReviewsData?.push(obj);

        setGroupsReviewsData([...groupsReviewsData]);
        setSummaryLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setSummaryLoading(false);
        //
      } else {
        setSummaryLoading(false);
      }
      setSummaryLoading(false);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getLocationActionPlanForGroups = async (locationId, groupId) => {
    const newCancelTokenSource = addCancelTokenSource();

    try {
      setActionPlansLoading(true);
      const response = await api.get(
        `actionPlans/action-plan-google?id=${locationId}`
      );
      if (response.status === 200) {
        let obj = {
          groupId: groupId,
          locationId: locationId,
          response: response?.data?.data,
        };

        groupsActionPlansData?.push(obj);

        setGroupsActionPlansData([...groupsActionPlansData]);
        setActionPlansLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setActionPlansLoading(false);
        //
      } else {
        setActionPlansLoading(false);
      }
      setActionPlansLoading(false);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getReviewAndAverageRatingData = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    try {
      setReviewAverageRatingLoading(true);
      const res = await api.post(
        "emotionAi/reviewsRatingGraph",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setReviewAverageRatingData(res?.data?.data);

        setReviewAverageRatingLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setReviewDistributionLoading(false);
        setReviewAverageRatingData([]);
      } else {
        setReviewAverageRatingLoading(false);
      }
      setReviewAverageRatingLoading(false);
      setReviewAverageRatingData([]);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getTopicsData = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    try {
      setTopicsDataLoading(true);
      const res = await api.post(
        "emotionAi/emotionAiTopics",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        console.log(res, "res");
        setTopicsData(res?.data?.data);

        setTopicsDataLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setTopicsDataLoading(false);
        setTopicsData([]);
      } else {
        setTopicsDataLoading(false);
      }
      setTopicsDataLoading(false);
      setTopicsData([]);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getReviewDistibutionData = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    try {
      setReviewDistributionLoading(true);
      const res = await api.post(
        "emotionAi/reviewDistribution",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setReviewDistributionData(res?.data?.data?.response);

        setReviewDistributionLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setReviewDistributionLoading(false);
        setReviewDistributionData([]);
      } else {
        setReviewDistributionLoading(false);
      }
      setReviewDistributionLoading(false);
      setReviewDistributionData([]);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getReviewDistibutionDataForTable = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    try {
      setReviewDistributionLoading(true);
      const res = await api.post(
        "emotionAi/ratingDistributionTable",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setReviewDistributionTableData(res?.data?.data);

        setReviewDistributionLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setReviewDistributionLoading(false);
        setReviewDistributionTableData([]);
      } else {
        setReviewDistributionLoading(false);
      }
      setReviewDistributionLoading(false);
      setReviewDistributionTableData([]);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getLocationRatingVariance = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    try {
      setRatingVarianceLoading(true);
      const res = await api.post(
        "emotionAi/ratingReviewsVariance",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setRatingVaranceData(res?.data?.data);
        setReviewVelocityData(res?.data?.data);

        const filterLocation = locations.filter(
          (location) =>
            !res?.data?.data.some((item) => item.locationId === location.id)
        );
        setFilteredLocations(filterLocation);
        setRatingVarianceLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setRatingVarianceLoading(false);
        setRatingVaranceData([]);
        setReviewVelocityData([]);
      } else {
        setRatingVarianceLoading(false);
        setRatingVaranceData([]);
        setReviewVelocityData([]);
      }
      setRatingVarianceLoading(false);
      setRatingVaranceData([]);
      setReviewVelocityData([]);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getLocationRatingPlatformOveriew = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    try {
      setPlatformOverviewLoading(true);
      const res = await api.post(
        "emotionAi/platformOverview",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        if (res?.data?.data?.overview?.length > 0) {
          const groupedData = {};
          res?.data?.data?.overview?.forEach((item) => {
            const {
              locationId,
              thirdPartyReviewSourcesId,
              rating,
              reviewCount,
              satisfactoryLevel,
              lastMonthRating,
              lastMonthReviewCount,
              lastMonthSatisfactoryLevel,
              reviewReplyCount,
            } = item;

            if (reviewCount > 0) {
              if (!groupedData[thirdPartyReviewSourcesId]) {
                groupedData[thirdPartyReviewSourcesId] = {
                  locationId,
                  thirdPartyReviewSourcesId,
                  totalRating: 0,
                  totalLastMonthRating: 0,
                  totalReviewCount: 0,
                  totalLastMonthReviewCount: 0,
                  totalSatisfactoryLevel: 0,
                  totalLastMonthSatisfactoryLevel: 0,
                  totalReviewReplyCount: 0,
                  count: 0,
                  countInc: 0,
                };
              }

              // Add the values to the existing group
              groupedData[thirdPartyReviewSourcesId].totalRating += rating;
              groupedData[thirdPartyReviewSourcesId].totalLastMonthRating +=
                lastMonthRating;
              groupedData[thirdPartyReviewSourcesId].totalReviewCount +=
                reviewCount;
              groupedData[
                thirdPartyReviewSourcesId
              ].totalLastMonthReviewCount += lastMonthReviewCount;
              groupedData[thirdPartyReviewSourcesId].totalReviewReplyCount +=
                parseInt(reviewReplyCount);

              // If satisfactoryLevel is not null, add it to the totalSatisfactoryLevel
              if (satisfactoryLevel !== null) {
                groupedData[thirdPartyReviewSourcesId].totalSatisfactoryLevel +=
                  satisfactoryLevel;
              }
              if (lastMonthSatisfactoryLevel !== null) {
                groupedData[
                  thirdPartyReviewSourcesId
                ].totalLastMonthSatisfactoryLevel += lastMonthSatisfactoryLevel;
              }

              // Increment the count
              groupedData[thirdPartyReviewSourcesId].count++;
              groupedData[thirdPartyReviewSourcesId].countInc++;
            }
          });

          // Calculate the average rating for each group
          for (const key in groupedData) {
            const group = groupedData[key];
            if (group.totalReviewCount > 0) {
              group.totalRating /= group.count;
              group.totalLastMonthRating /= group.countInc;

              // If totalSatisfactoryLevel is still 0, set it to null
              if (group.totalSatisfactoryLevel === 0) {
                group.totalSatisfactoryLevel = null;
              } else {
                group.totalSatisfactoryLevel /= group.count;
              }

              if (group.totalLastMonthSatisfactoryLevel === 0) {
                group.totalLastMonthSatisfactoryLevel = null;
              } else {
                group.totalLastMonthSatisfactoryLevel /= group.count;
              }
            }
          }

          // Convert the grouped data object back into an array
          const resultArray = Object.values(groupedData);

          setPlatformOverviewData(resultArray);
          setPlatformData(res?.data?.data);
        }
        setPlatformOverviewLoading(false);
      }
    } catch (error) {
      setPlatformOverviewData(null);
      setPlatformData(null);

      setPlatformOverviewLoading(false);
      if (axios.isCancel(error)) {
        //
      } else {
        setPlatformOverviewLoading(false);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getLocationRatingBreakdown = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    try {
      setRatingLoading(true);
      const res = await api.post(
        "emotionAi/ratingBreakdown",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setRatingBreakdownData(res?.data?.data);
        const filterLocation = locations.filter(
          (location) =>
            !res?.data?.data.some((item) => item.locationId === location.id)
        );
        setFilterBreakdownLocation(filterLocation);
        setRatingLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        //
      } else {
        setRatingLoading(false);
        setRatingBreakdownData([]);
      }
      setRatingLoading(false);
      setRatingBreakdownData([]);
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getOnlineReputationData = async () => {
    setReputationLoading(true);
    const newCancelTokenSource = addCancelTokenSource();

    const locationIds = [];
    const groupIds = [];
    const locationsCount = Math.min(10, locations.length);
    for (let i = 0; i < locationsCount; i++) {
      locationIds.push(locations[i].id);
    }
    const remainingCount = 10 - locationsCount;
    for (let i = 0; i < remainingCount; i++) {
      if (i < selectedGroups.length) groupIds.push(selectedGroups[i].id);
    }
    let bodyForLpq = {
      groupIds,
      locationIds,
      endDate,
      startDate,
      topicText: selectedTopic !== null ? selectedTopic : "",
      sentimentAnalysis: sentimentValue !== null ? sentimentValue?.value : "",
      languageCode: language !== null && language !== "" ? language?.value : "",
      nextEndDate: nextEndDate !== null ? nextEndDate : "",
      nextStartDate: nextStartDate !== null ? nextStartDate : "",
    };
    let bodyForOthers = {
      groupIds,
      locationIds,
      endDate,
      startDate,
      topicId: selectedTopic !== null ? selectedTopic?.topicId : "",
      sentimentAnalysis: sentimentValue !== null ? sentimentValue?.value : "",
      languageCode: language !== null && language !== "" ? language?.value : "",
      nextEndDate: nextEndDate !== null ? nextEndDate : "",
      nextStartDate: nextStartDate !== null ? nextStartDate : "",
    };
    try {
      setReputationLoading(true);
      const res = await api.post(
        "/emotionAi/onlineReputation",
        companyData?.emotionAIStaticTopics === true
          ? bodyForLpq
          : bodyForOthers,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setOnlineReputationData(res?.data?.data);
        setReputationLoading(false);
        if (res?.data?.data.length === 0) {
          setGraphData([]);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setOnlineReputationData([]);
        setGraphData([]);
        setReputationLoading(false);
      } else {
        setOnlineReputationData([]);
        setGraphData([]);
        setReputationLoading(false);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getReviewTopics = async (topicText) => {
    if (topicText?.length >= 3) {
      let startDateNew = startDate.toISOString();
      let endDateNew = endDate.toISOString();
      const newCancelTokenSource = addCancelTokenSource();
      let locationIds = [];
      if (locations?.length > 0) {
        locations?.map((item) => {
          locationIds.push(item?.id);
        });
      }
      try {
        setTopicLoading(true);
        const res = await api.get(
          `reviewTopics?locationId=${
            locationIds?.length > 0 ? locationIds?.toString() : ""
          }&attributes=topicText,topicId&search=${topicText}&startDate=${startDateNew}&endDate=${endDateNew}`,

          {
            cancelToken: newCancelTokenSource.token,
          }
        );
        if (res.status === 200) {
          setReviewTopics(res?.data?.data?.data);
          setTimeout(() => {
            setTopicLoading(false);
          }, 1000);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          setReviewTopics([]);
        } else {
          setTimeout(() => {
            setTopicLoading(false);
            setReviewTopics([]);
          }, 1000);
        }
        setReviewTopics([]);
        setTopicLoading(false);
      } finally {
        removeCancelTokenSource(newCancelTokenSource);
      }
    }
  };
  const getKeywordsData = async () => {
    const newCancelTokenSource = addCancelTokenSource();
    let groupIds = [];
    if (selectedGroups?.length > 0) {
      selectedGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    let locationIds = [];
    if (locations?.length > 0) {
      locations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    try {
      setKeywordsLoading(true);
      const res = await api.post(
        "/emotionAi/keywordCloud",
        {
          groupIds,
          locationIds,
          endDate,
          startDate,
          sentimentAnalysis:
            sentimentValue !== null ? sentimentValue?.value : "",
          languageCode:
            language !== null && language !== "" ? language?.value : "",
          nextEndDate: nextEndDate !== null ? nextEndDate : "",
          nextStartDate: nextStartDate !== null ? nextStartDate : "",
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setKeywordsLoading(false);
        if (res?.data?.data?.response?.length > 0) {
          let firstTenArrayItems = [];
          const firstTenItems = res?.data?.data?.response?.slice(0, 10);
          firstTenItems.map((item) => {
            if (
              item?.positiveCount > 0 ||
              item?.negativeCount > 0 ||
              item?.neutralCount > 0
            ) {
              firstTenArrayItems.push(item);
            }
          });
          setKeywordsData(firstTenArrayItems);
        } else {
          setKeywordsData(res?.data?.data?.response);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setKeywordsData([]);
      } else {
        setKeywordsData([]);
        setKeywordsLoading(false);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getLocationGroupByIdForSummary = async (groupId) => {
    try {
      const res = await api.get(`groups/${groupId}`);
      if (res.status === 200) {
        if (res?.data?.data?.groups?.length > 0) {
          res?.data?.data?.groups?.map((item) => {
            getLocationReviewSummaryForGroups(
              item?.locationId,
              res?.data?.data?.id
            );
          });
        }
      }
    } catch (error) {}
  };
  const getLocationGroupByIdForActionPlan = async (groupId) => {
    try {
      const res = await api.get(`groups/${groupId}`);
      if (res.status === 200) {
        if (res?.data?.data?.groups?.length > 0) {
          res?.data?.data?.groups?.map((item) => {
            getLocationActionPlanForGroups(
              item?.locationId,
              res?.data?.data?.id
            );
          });
        }
      }
    } catch (error) {}
  };

  const handleChangeTopic = (value) => {
    if (value !== null) {
      setSelectedTopic(value);
    } else {
      setSelectedTopic(null);
    }
  };
  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item.groups.some((group) => group.locationId === targetId)
    );
  }

  const handleChangeGroups = (value, autoSelectValue) => {
    setSelectedGroups(value);

    if (autoSelectValue) {
      let newLocations = [...locations];
      const results = oldGroups.filter(
        ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
      );

      if (value?.length > 0) {
        value?.map((group) => {
          group?.groups?.map((groupItem) => {
            allLocations?.map((location) => {
              if (groupItem?.locationId === location?.id) {
                newLocations?.push(location);
              } else {
                setSelectAll(false);
                results?.map((group) => {
                  group?.groups?.map((groupItem) => {
                    locations?.map((location) => {
                      const filteredArray = filterArrayById(
                        value,
                        location?.id
                      );
                      if (
                        groupItem?.locationId === location?.id &&
                        filteredArray?.length === 0
                      ) {
                        newLocations = newLocations?.filter(
                          (item) => item?.id !== location?.id
                        );
                      }
                    });
                  });
                });
              }
            });
          });
        });
      } else {
        results?.map((group) => {
          group?.groups?.map((groupItem) => {
            locations?.map((location) => {
              if (groupItem?.locationId === location?.id) {
                newLocations = newLocations?.filter(
                  (item) => item?.id !== location?.id
                );
              }
            });
          });
        });
      }

      const uniqueData = removeDuplicates(newLocations);

      setLocations(uniqueData);
    }

    setOldGroups(value);
  };
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }

  const handleChangeGroupsForLocations = (locations) => {
    if (locations?.length > 0 || selectedGroups?.length > 0) {
      const filteredArray2 = selectedGroups?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectAllGroups(false);
      setSelectedGroups(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      // setLocationError(false);
    } else {
      //  setSelectAllGroups(false);
      // /  setLocationError(true);
      setSelectedGroups([]);
    }
  };
  /*   function isSentimentalAnalysisDone(arrayOne) {
    if (arrayOne?.length > 0) {
      console.log(arrayOne, "arrayOne");
      return arrayOne?.every((item) => {
        const correspondingItem = allLocations?.find(
          (el) => el.id === item.locationId
        );
        console.log();
        return (
          correspondingItem &&
          correspondingItem.statusOfSentimentalAnalysis === "done"
        );
      });
    }
  } */

  function isSentimentalAnalysisDone(arrayOne) {
    if (arrayOne?.length > 0) {
      console.log(arrayOne, "arrayOne");
      const filteredArray = arrayOne.filter((item) => {
        const correspondingItem = allLocations?.find(
          (el) => el.id === item.locationId
        );

        if (correspondingItem) {
          return (
            correspondingItem.statusOfSentimentalAnalysis === "done" ||
            correspondingItem.reviewFoundStatus === false
          );
        }
        return false;
      });
      return filteredArray.length === arrayOne.length;
    }
    return false;
  }

  const handleChangeLanguage = (value) => {
    setGoogleReviewData([]);
    setActionPlans([]);
    setLanguage(value);
  };
  const handleChangeLocation = (loc) => {
    setCallApi(false);
    setLocations(loc);

    if (loc?.length > 0) {
      if (loc.length > 1) {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
  };

  const handleChangeSentiment = (value) => {
    setGoogleReviewData([]);
    setActionPlans([]);
    setSentimentValue(value);
  };

  const handleChangeRange = (value, fromModel) => {
    setCallApi(false);
    setGoogleReviewData([]);
    setActionPlans([]);
    if (fromModel !== true) {
      setStartDate(previousYear);
      setEndDate(today);
      setNextStartDate(newwww);
      setnextEndDate(previousYear);
    }

    setRange(value);
  };
  const handleChangeCompareRange = (value) => {
    setCallApi(false);
    setGoogleReviewData([]);
    setActionPlans([]);
    setStartDate(previousYear);
    setEndDate(today);
    setNextStartDate(newwww);
    setnextEndDate(previousYear);
    setCompareRange(value);
  };
  const handleChangeNextStartDate = (e) => {
    setGoogleReviewData([]);
    setActionPlans([]);
    setNextStartDate(e);
  };
  const handleChangeStartDate = (e) => {
    setGoogleReviewData([]);
    setActionPlans([]);
    setStartDate(e);
  };

  const handleChangeEndDateSchedule = (e) => {
    setEndDateSchedule(e);
  };
  const handleChangeStartDateSchedule = (e) => {
    setStartDateSchedule(e);
    if (e !== null && e !== "") {
      let date = dayjs(e);
      let oneDaysBefore = date.subtract(1, "day").endOf("day");
      let oneDaysBeforeDateEnd = oneDaysBefore.toDate();
      if (dateRange === "Yesterday") {
        let date = dayjs(e);
        let oneDaysBefore = date.subtract(1, "day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(oneDaysBeforeDate);

        let newNextStartDate = oneDaysBefore.subtract(1, "day");
        setNextEndDateSchedule(newNextStartDate.endOf("day").toDate());
        let newNextStartDateDate = newNextStartDate.toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneDaysBeforeDate
          ).format("MMM DD, YYYY")}`
        );
      } else if (dateRange === "Today") {
        let date = dayjs(e);
        let oneDaysBefore = date.startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();
        setEndDateRangeSchedule(date);
        setStartDateRangeSchedule(oneDaysBeforeDate);

        let newNextStartDate = oneDaysBefore.subtract(1, "day");

        let newNextStartDateDate = newNextStartDate.toDate();
        let newNextEndDate = oneDaysBefore.subtract(1, "day").endOf("day");
        setNextEndDateSchedule(newNextEndDate);

        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneDaysBeforeDate
          ).format("MMM DD, YYYY")}.`
        );
      } else if (dateRange === "2days") {
        let date = dayjs(e);
        let twoDaysBefore = date.subtract(2, "day").startOf("day");
        let twoDaysBeforeDate = twoDaysBefore.toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(twoDaysBeforeDate);

        let newNextStartDate = twoDaysBefore.subtract(2, "day").startOf("day");

        let newNextStartDateDate = newNextStartDate.toDate();
        setNextStartDateSchedule(newNextStartDateDate);

        let newNextEndDate = twoDaysBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            twoDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDateEnd).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (dateRange === "7days") {
        let date = dayjs(e);
        let sevenDaysBefore = date.subtract(7, "day").startOf("day");
        let sevenDaysBeforeDate = sevenDaysBefore.toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(sevenDaysBeforeDate);
        let newNextEndDate = sevenDaysBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        let newNextStartDate = sevenDaysBefore
          .subtract(7, "day")
          .startOf("day");
        let newNextStartDateDate = newNextStartDate.toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setNextStartDateSchedule(newNextStartDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sevenDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDateEnd).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (dateRange === "week") {
        let date = dayjs(e);

        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate.subtract(
          weekBeforeSelectedDate.day() - 1,
          "day"
        );
        let followingSunday = previousMonday.add(6, "day").endOf("day");
        setEndDateRangeSchedule(followingSunday.toDate());
        setStartDateRangeSchedule(previousMonday.toDate());
        let newNextEndDate = previousMonday.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);

        let sevenDaysBefore = previousMonday.subtract(7, "day");
        setNextStartDateSchedule(sevenDaysBefore.toDate());
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            previousMonday.toDate()
          ).format("MMM DD, YYYY")} to ${dayjs(followingSunday.toDate()).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (dateRange === "month") {
        let date = dayjs(e);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
        setStartDateRangeSchedule(startOfMonthBefore.toDate());
        let newNextEndDate = startOfMonthBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        let oneMoreMonthBefore = startOfMonthBefore.subtract(1, "month");
        setNextStartDateSchedule(oneMoreMonthBefore?.toDate());
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            startOfMonthBefore.toDate()
          ).format("MMM DD, YYYY")} to ${dayjs(
            endOfMonthBefore.toDate()
          ).format("MMM DD, YYYY")}`
        );
      } else if (dateRange === "14days") {
        let date = dayjs(e);
        let fourteenDaysBefore = date.subtract(14, "day").startOf("day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(fourteenDaysBeforeDate);
        let newNextEndDate = fourteenDaysBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        let newNextStartDate = fourteenDaysBefore
          .subtract(14, "day")
          .startOf("day");
        let newNextStartDateDate = newNextStartDate.toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            fourteenDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDateEnd).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (dateRange === "30days") {
        let date = dayjs(emailBody);
        let oneMonthBefore = date.subtract(30, "day").startOf("day");
        let oneMonthBeforeDate = oneMonthBefore.toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(oneMonthBeforeDate);
        let newNextEndDate = oneMonthBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        let newNextStartDate = oneMonthBefore
          .subtract(30, "day")
          .startOf("day");
        let newNextStartDateDate = newNextStartDate.toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneMonthBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDateEnd).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (dateRange === "60days") {
        let date = dayjs(e);

        let sixtyDaysBefore = date.subtract(60, "day").startOf("day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.toDate();

        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(sixtyDaysBeforeDate);

        let newNextStartDate = sixtyDaysBefore
          .subtract(60, "day")
          .startOf("day");
        let newNextStartDateDate = newNextStartDate.toDate();

        let newNextEndDate = sixtyDaysBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sixtyDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (dateRange === "90days") {
        let date = dayjs(e);

        let ninetyDaysBefore = date.subtract(90, "day").startOf("day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.toDate();

        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(ninetyDaysBeforeDate);

        let newNextStartDate = ninetyDaysBefore
          .subtract(90, "day")
          .startOf("day");
        let newNextStartDateDate = newNextStartDate.toDate();

        let newNextEndDate = ninetyDaysBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            ninetyDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(oneDaysBeforeDateEnd).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (dateRange === "6months") {
        let date = dayjs(e);

        let sixMonthsBefore = date.subtract(6, "month").startOf("month");
        let sixMonthsBeforeDate = sixMonthsBefore.toDate();
        setStartDateRangeSchedule(sixMonthsBeforeDate);

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());

        let newNextStartDate = sixMonthsBefore
          .subtract(6, "month")
          .startOf("month");
        let newNextStartDateDate = newNextStartDate.toDate();

        let newNextEndDate = sixMonthsBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sixMonthsBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(
            endOfMonthBefore.toDate()
          ).format("MMM DD, YYYY")}`
        );
      } else if (dateRange === "12months") {
        let date = dayjs(e);

        let oneYearBefore = date.subtract(12, "month").startOf("month");
        let oneYearBeforeDate = oneYearBefore.toDate();
        setStartDateRangeSchedule(oneYearBeforeDate);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());

        let newNextStartDate = oneYearBefore
          .subtract(12, "month")
          .startOf("month");
        let newNextStartDateDate = newNextStartDate.toDate();

        let newNextEndDate = oneYearBefore.subtract(1, "day").endOf("day");
        let newNextEndDateDate = newNextEndDate.toDate();
        setNextEndDateSchedule(newNextEndDateDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneYearBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(endOfMonthBefore).format(
            "MMM DD, YYYY"
          )}`
        );
      }
      /*   else if (dateRange === "custom") {
        setEndDateRangeSchedule(endDate);
        setNextEndDateSchedule(nextEndDate);
        setNextStartDateSchedule(nextStartDate);

        setStartDateRangeSchedule(startDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            startDate
          ).format("MMM DD, YYYY")} to ${dayjs(endDate).format("MMM DD, YYYY")}`
        );
      } */
    }
  };

  const handleChangeRangeEndDateSchedule = (e) => {
    setEndDateRangeSchedule(e);
    setEmailBody(
      `Dear customer Please find attached emotion AI report for the ${dayjs(
        startDateRangeSchedule
      ).format("MMM DD, YYYY")} to ${dayjs(e).format("MMM DD, YYYY")}`
    );
    let startDateOnee = dayjs(startDateRangeSchedule);
    const startDateOne = startDateOnee.subtract(1, "days");

    const endDateOne = dayjs(e);
    const diffInMonths = monthDiff(startDateOnee, endDateOne);
    const diffInDays = endDateOne.diff(startDateOnee, "days");
    if (diffInMonths < 12 || diffInDays > 0) {
      if (diffInDays > 0) {
        const newStartDate = startDateOne.subtract(diffInDays, "days");

        setNextStartDateSchedule(newStartDate.toDate());
        setNextEndDateSchedule(startDateOnee.toDate());
      } else {
        const newStartDate = startDateOne.subtract(diffInMonths, "months");

        setNextStartDateSchedule(newStartDate.toDate());
        setNextEndDateSchedule(startDateOnee.toDate());
      }
    } else {
      const yearsToSubtract = Math.floor(diffInMonths / 12);
      const remainingMonths = diffInMonths % 12;

      const newStartDate = startDateOnee
        .subtract(yearsToSubtract, "year")
        .subtract(remainingMonths, "months");
      setNextStartDateSchedule(newStartDate.toDate());
      setNextEndDateSchedule(startDateOnee.toDate());
    }
  };
  const handleChangeDateRangesfromRangeModel = (startValue, endValue) => {
    setStartDateRangeSchedule(startValue);
    setEndDateRangeSchedule(endValue);
    setEmailBody(
      `Dear customer Please find attached emotion AI report for the ${dayjs(
        startValue
      ).format("MMM DD, YYYY")} to ${dayjs(endValue).format("MMM DD, YYYY")}`
    );

    let startDateOnee = dayjs(startValue);
    const startDateOne = startDateOnee.subtract(1, "days");
    const endDateOne = dayjs(endValue);
    const diffInMonths = monthDiff(startDateOnee, endDateOne);
    const diffInDays = endDateOne.diff(startDateOnee, "days");
    console.log(diffInDays, diffInMonths, "diffInMonths");
    if (diffInMonths < 12 || diffInDays > 0) {
      if (diffInDays > 0) {
        const newStartDate = startDateOne.subtract(diffInDays, "days");
        console.log(newStartDate, startDateOne, "newStartDate");

        setNextStartDateSchedule(newStartDate.toDate());
        setNextEndDateSchedule(startDateOne.endOf("day").toDate());
      } else {
        const newStartDate = startDateOne.subtract(diffInMonths, "months");

        setNextStartDateSchedule(newStartDate.toDate());
        setNextEndDateSchedule(startDateOnee.toDate());
      }
    } else {
      const yearsToSubtract = Math.floor(diffInMonths / 12);
      const remainingMonths = diffInMonths % 12;

      const newStartDate = startDateOnee
        .subtract(yearsToSubtract, "year")
        .subtract(remainingMonths, "months");
      setNextStartDateSchedule(newStartDate.toDate());
      setNextEndDateSchedule(startDateOnee.toDate());
    }
  };
  const handleChangeRangeStartDateSchedule = (e) => {
    setStartDateRangeSchedule(e);
    setEmailBody(
      `Dear customer Please find attached emotion AI report for the ${dayjs(
        e
      ).format("MMM DD, YYYY")} to ${dayjs(endDateRangeSchedule).format(
        "MMM DD, YYYY"
      )}`
    );

    let startDateOnee = dayjs(e);
    const startDateOne = startDateOnee.subtract(1, "days");
    const endDateOne = dayjs(endDateRangeSchedule);
    const diffInMonths = monthDiff(startDateOnee, endDateOne);
    const diffInDays = endDateOne.diff(startDateOnee, "days");
    console.log(diffInDays, diffInMonths, "diffInMonths");
    if (diffInMonths < 12 || diffInDays > 0) {
      if (diffInDays > 0) {
        const newStartDate = startDateOne.subtract(diffInDays, "days");
        console.log(newStartDate, startDateOne, "newStartDate");

        setNextStartDateSchedule(newStartDate.toDate());
        setNextEndDateSchedule(startDateOne.endOf("day").toDate());
      } else {
        const newStartDate = startDateOne.subtract(diffInMonths, "months");

        setNextStartDateSchedule(newStartDate.toDate());
        setNextEndDateSchedule(startDateOnee.toDate());
      }
    } else {
      const yearsToSubtract = Math.floor(diffInMonths / 12);
      const remainingMonths = diffInMonths % 12;

      const newStartDate = startDateOnee
        .subtract(yearsToSubtract, "year")
        .subtract(remainingMonths, "months");
      setNextStartDateSchedule(newStartDate.toDate());
      setNextEndDateSchedule(startDateOnee.toDate());
    }
  };

  console.log(
    startDateRangeSchedule,
    endDateRangeSchedule,
    nextStartDateSchedule,
    nextEndDateSchedule,
    "nextEndDateSchedule"
  );

  const handleChangeNextEndDate = (e) => {
    setGoogleReviewData([]);
    setActionPlans([]);
    setnextEndDate(e);
  };
  const handleChangeEndDate = (e) => {
    setGoogleReviewData([]);
    setActionPlans([]);
    let arr = [];
    arr.push(dayjs(startDate).format(), dayjs(e).format());
    let tempfilterObj = { ...filterObj };
    if (tempfilterObj.hasOwnProperty("filters[date][$lt]")) {
      delete tempfilterObj["filters[date][$lt]"];
    }
    if (tempfilterObj.hasOwnProperty("filters[date][$gt]")) {
      delete tempfilterObj["filters[date][$gt]"];
    }
    tempfilterObj["filters[date][$between]"] = arr?.toString() || "";
    setFilterObj(tempfilterObj);
    setEndDate(e);
  };
  const handleOpenEditUser = () => {
    setOpenModalFilter(true);
  };
  const handleCloseModalFilter = () => {
    setOpenModalFilter(false);
  };

  const handleOpenCompareDatesModel = () => {
    setOpenCompareModel(true);
  };
  const handleCloseCompareDatesModel = () => {
    setOpenCompareModel(false);
  };
  const removeCancelTokenSource = (source) => {
    setCancelTokenSources((prevSources) =>
      prevSources.filter((cancelTokenSource) => cancelTokenSource !== source)
    );
  };
  const addCancelTokenSource = () => {
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSources((prevSources) => [
      ...prevSources,
      newCancelTokenSource,
    ]);
    return newCancelTokenSource;
  };

  const cancelAllRequests = () => {
    cancelTokenSources.forEach((cancelTokenSource) => {
      cancelTokenSource.cancel("New request is made");
    });
  };
  const getsentimentalLocation = async () => {
    setRequestLoading(true);
    let locationIds = [];
    if (selectedLocation.length > 0) {
      selectedLocation?.map((location) => {
        locationIds.push(location?.id);
      });
    }
    try {
      const res = await api.patch(
        "sentimentalAnalysis/location/requestForSentimentalAnalysis",
        {
          locationIds: locationIds,
        }
      );
      if (res.status === 200) {
        handleCloseNewRequestModel();
        getAllLocations();

        toast.success(res?.data?.message, {});
        setRequestLoading(false);
        setSelectedLocation([]);
      }
    } catch (error) {
      handleCloseNewRequestModel();
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
      setSelectedLocation([]);
    }
  };

  function handleDisplayExportLocationName(locationId) {
    let locationName = "";

    if (allLocations && allLocations.length > 0) {
      const location = allLocations.find((item) => item?.id === locationId);
      locationName = location ? location.internalName : "";
    }

    return locationName;
  }
  function handleDisplayNames(locationId, fullWidth) {
    let location = {};
    let locationName = "";
    if (allLocations?.length > 0) {
      location = allLocations?.find((item) => item?.id === locationId);
    }

    locationName = (
      <div style={{ width: fullWidth ? "100%" : "240px" }}>
        <span
          className={classes.ratingHeadingsLoc}
          style={{
            fontWeight: fullWidth ? 700 : 400,
            fontSize: fullWidth ? "18px" : "14px",
          }}
        >
          {location?.internalName}
        </span>
        <br />
        <i className={classes.ratingHeadingsLocAddress}>
          {((location?.addressLine1 !== "" &&
            location?.addressLine1 !== null) ||
            (location?.city !== null && location?.city !== "")) &&
            "(" +
              (location?.addressLine1 !== "" && location?.addressLine1 !== null
                ? location?.addressLine1
                : "") +
              (location?.addressLine1 !== "" &&
              location?.addressLine1 !== null &&
              location?.city !== null &&
              location?.city !== ""
                ? ","
                : "") +
              (location?.city !== null && location?.city !== ""
                ? ` ${location?.city} `
                : "") +
              ")"}
        </i>
      </div>
    );

    return locationName;
  }

  function handleDisplayGroupNames(groupId, styling) {
    let group = {};
    let groupName = "";

    if (allGroups?.rows?.length > 0) {
      group = allGroups?.rows?.find((item) => item?.id === groupId);
    }

    groupName = (
      <div>
        <span
          className={
            styling === true
              ? classes.ratingHeadingsLocBold
              : classes.ratingHeadingsLoc
          }
        >
          {group?.name
            ? group.name.charAt(0).toUpperCase() + group.name.slice(1)
            : ""}
        </span>
      </div>
    );

    return groupName;
  }

  function handleDisplayGroupNameSimple(groupId) {
    let group = {};

    if (allGroups?.rows?.length > 0) {
      group = allGroups?.rows?.find((item) => item?.id === groupId);
    }

    return group?.name
      ? group.name.charAt(0).toUpperCase() + group.name.slice(1)
      : "";
  }
  const resetCompareModel = () => {
    setStartDate(previousYear);
    setEndDate(today);
    setNextStartDate(newwww);
    setnextEndDate(previousYear);
    setRange({
      label: `${t("In_Range")}`,
      value: "between",
    });
    setCompareRange({
      label: `${t("In_Range")}`,
      value: "between",
    });
  };

  const handleApplyFilter = () => {
    if (callApi === true) {
      setCallApi(false);
    } else {
      setGroupsReviewsData([]);
      setGroupsReviewsFromatedData([]);
      setGroupsActionPlansData([]);
      setGroupsActionPlansFromatedData([]);
      setGoogleReviewData([]);
      setActionPlans([]);

      setCallApi(true);
    }
  };

  const resetHandler = () => {
    setGroupsReviewsData([]);
    setGroupsReviewsFromatedData([]);
    setGroupsActionPlansData([]);
    setGroupsActionPlansFromatedData([]);
    setGoogleReviewData([]);
    setActionPlans([]);
    setStartDate(previousYear);
    setEndDate(today);
    setRange({
      label: `${t("In_Range")}`,
      value: "between",
    });
    if (defaultLocation !== null) {
      setLocations([defaultLocation]);
    }

    setSentimentValue(null);
    setLanguage("");
    setSelectedGroups([]);
    setSelectAllGroups(false);
    setCallApiFirstTime(true);
    setAutoSelectHeader(false);
    setSelectAllLocations(false);
  };

  const handleOpenNewRequestModel = () => {
    setOpenRequest(true);
  };
  const handleCloseNewRequestModel = () => {
    setOpenRequest(false);
  };

  function kFormatter(num) {
    if (num >= 1000 && num < 1000000) {
      return (Math.floor(num / 100) / 10).toFixed(1) + "K"; // convert to K for numbers from >= 1000 < 1 million
    } else if (num >= 1000000) {
      return (Math.abs(num) / 1.0e6).toFixed(2) + "M"; // convert to M for numbers >= 1 million
    } else if (num < 1000) {
      return Math.sign(num) * Math.abs(num); // if value < 1000, return the number as is
    }
  }

  const handleCloseReviewModel = () => {
    setPageNumber(0);
    setReviewsData([]);
    setEmotionAIDetailsData([]);
    setVarianceType("");
    setThirdPartySourceId("");
    setSelectedLocationId("");
    setSelectedGroupId("");
    setRatingTextKeywords("");
    setRatingBreakdownValue("");
    setReviewsTitle(null);
    setApiCallFrom(null);
    setOpenReviewModel(false);
    setFromPlatform(false);
    setKeywordsSentiment("");
    setSelectedTopicName(null);
  };
  const getAllThirdPartySource = async () => {
    try {
      const res = await api.get(`/thirdPartyReviewSource/`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllReviewSource(res.data.data);
      }
    } catch (error) {}
  };
  const handleOpenReviewModel = (key, platform, name, nameKey) => {
    let locationName;
    let groupName;
    let platformName;
    if (nameKey === "locationId") {
      if (locations.length > 0) {
        locationName = locations?.find((item) => item?.id === name);
        setReviewsTitle(locationName?.internalName);
      } else {
        locationName = allLocations?.find((item) => item?.id === name);
        setReviewsTitle(locationName?.internalName);
      }
    } else if (nameKey === "groupId") {
      if (selectedGroups?.length > 0) {
        groupName = selectedGroups?.find((item) => item?.id === name);
        setReviewsTitle(groupName?.name);
      } else {
        groupName = allGroups?.rows?.find((item) => item?.id === name);
        setReviewsTitle(groupName?.name);
      }
    } else if (nameKey === "platform") {
      platformName = allReviewSource?.results?.find(
        (item) => item?.id === name
      );
      setReviewsTitle(platformName?.name);
    } else {
      setReviewsTitle(null);
    }
    setOpenReviewModel(true);
    setApiCallFrom(key);
    if (key === "old" && nameKey === "groupId") {
      setTypeParam(true);
    } else {
      setTypeParam(false);
    }
    if (platform !== undefined && platform === true) {
      if (locations?.length > 0) {
        let ids = [];
        locations?.map((item) => {
          ids?.push(item?.id);
        });
        setPlatformLocations(ids);
        setFromPlatform(true);
      }
      if (selectedGroups?.length > 0) {
        let ids = [];
        selectedGroups?.map((item) => {
          ids?.push(item?.id);
        });
        setPlatformGroups(ids);
        setFromPlatform(true);
      }
    } else {
      setPlatformLocations([]);
      setPlatformGroups([]);
      setFromPlatform(false);
    }
  };

  const handleOpenReportEmailModal = () => {
    setOpenReportEmailModal(true);
  };
  const handleCloseEmailReportModal = () => {
    setOpenReportEmailModal(false);
    handleResetEmailModel();

    setPdfChecked(true);
    setXlsChecked(false);
    setFromHeader(false);
  };
  const handleCloseExportFileDownloadModal = () => {
    setOpenDownloadPdfModal(false);
    setDownloadFormat("");

    setPdfDownloadChecked(true);
    setXlsDownloadChecked(false);
    handleResetEmailModel();
    setFromHeader(false);
  };

  const handleOpenExportFileDownloadModal = () => {
    setOpenDownloadPdfModal(true);
  };

  const handleClose = () => {
    setAnchorBulk(null);
  };
  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };
  const handleClickMenuItem = (value) => {
    setFromHeader(true);
    if (value === "pdf") {
      handleOpenExportFileDownloadModal(true);
    } else if (value === "email") {
      handleOpenReportEmailModal(true);
    } else {
      handleOpenScheduleModal(true);
    }
  };
  const handleOpenScheduleModal = () => {
    setOpenSchduleModal(true);
  };
  const handleCloseScheduleModal = () => {
    setOpenSchduleModal(false);
    handleResetEmailModel();
    setFromHeader(false);
  };
  const handleOpenCreateScheduleModal = (id) => {
    setOpenCreateSchedule(true);
    setSelectedEditId(id);
  };
  const handleCloseCreateScheduleModal = () => {
    setOpenCreateSchedule(false);
    handleResetEmailModel();
  };
  const handleAddNewUserEmailsChange = (value) => {
    const formattedValue = value.replace(/,\s*/g, ",\n");
    setAddNewUserEmails(formattedValue);

    const emailsArray = formattedValue.split(",");
    const validEmailsArray = [];

    // Validate each email address and push the valid ones into the array
    for (let i = 0; i < emailsArray.length; i++) {
      const email = emailsArray[i].trim();
      if (isValidEmail(email)) {
        validEmailsArray.push(email);
        setValidEmailsArray(validEmailsArray);
      }
    }

    const combinedArray = [...selectedItems, ...validEmailsArray];

    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitOk = () => {
    const combinedArray = [...selectedItems, ...validEmailsArray];
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    if (distinctEmails?.length > 0) {
      setTotalEmailsArr(distinctEmails);
    }
    handleCloseEmailsModal();
    if (selectedItems.length <= 0) {
      setDataArray([]);
      setSelectedFile(null);
    }
  };

  const handleScheduleReport = async () => {
    setScheduleReportLoading(true);
    let startDateNew = dayjs(startDateRangeSchedule).startOf("day").toDate();
    let endDateNew = dayjs(endDateRangeSchedule).toDate();
    let nextStartDateNew = dayjs(nextStartDateSchedule).startOf("day").toDate();
    let nextEndDateNew = dayjs(nextEndDateSchedule).toDate();
    let locationIds = [];
    if (scheduleLocations?.length > 0) {
      scheduleLocations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    let filterObj = {
      locationIds: locationIds,
      groupIds: groupIds,
      endDate: endDateNew,
      startDate: startDateNew,
      nextStartDate: nextStartDateNew,
      nextEndDate: nextEndDateNew,
      dateRange: dateRange,
    };

    if (scheduleSentiments !== null) {
      filterObj["sentimentAnalysis"] = scheduleSentiments?.value;
      // filterObj["sentimentValue"] = scheduleSentiments?.value;
    }
    if (scheduleLanguage !== null && scheduleLanguage !== "") {
      filterObj["languageCode"] = scheduleLanguage?.value;
    }

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }
    let tempFeatureObj = featuresObj;
    if (companyData?.id !== 175) {
      tempFeatureObj.emotionAITopics = false;
    } else {
      tempFeatureObj.onlineReputation = false;
    }

    try {
      const res = await api.post(
        `/reportSchedules`,

        {
          filter: filterObj,
          locationIds: locationIds,
          language:
            languageSchedular !== null && languageSchedular !== ""
              ? languageSchedular?.language
              : "",
          startDate: startDateSchedule,
          endDate: endDateSchedule,
          fileType:
            pdfChecked && xlsChecked
              ? "both"
              : pdfChecked && xlsChecked === false
              ? "pdf"
              : "xlsx",
          emailSubject,
          emailBody,
          emails: emails,
          repeatType: repeatValue,
          reportType: "EmotionAI",
          status: "pending",
          title: emailTitle,
          includeReviews: reviewsChecked,
          includedSections: tempFeatureObj,
          customLogo:
            uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
          noReviewsNotification:
            yesChecked && noChecked === false
              ? true
              : yesChecked === false && noChecked === true
              ? false
              : true,
        }
      );
      if (res.status === 201) {
        handleCloseCreateScheduleModal();
        getAllScheduledReports();
        setScheduleReportLoading(false);

        toast.success(`${t("Schedule_created")}`);
        handleResetEmailModel();
        setOpenCreateSchedule(false);
      }
    } catch (error) {
      setScheduleReportLoading(false);
      if (
        error?.response?.data?.data ===
        "Report Schedule Already Exists With this Name"
      ) {
        setDisplayReportError(true);
      } else {
        toast.error(error?.response?.data?.data);
      }
    }
  };

  const handleResetScheduleFiltersModel = () => {
    setScheduleGroups(selectedGroups);
    setScheduleLanguage(language);
    setScheduleLocations(locations);
    setScheduleSentiments(sentimentValue);
    setStartDateRangeSchedule(startDate);
    setEndDateRangeSchedule(endDate);
    setNextStartDateSchedule(nextStartDate);
    setNextEndDateSchedule(nextEndDate);
    setDateRange("custom");
  };

  const handleOpenUpdateStatusModel = (rowData, statusValue) => {
    setOpenUpdateStatusModel(true);
    setSelectedRowData(rowData);
    setPauseValue(statusValue);
  };
  const handleCloseUpdateStatusModel = () => {
    setOpenUpdateStatusModel(false);
    setSelectedRowData(null);
    setPauseValue(false);
  };
  const handleUpdateStatus = async () => {
    /*   let startDateNew = dayjs(startDateRangeSchedule).startOf("day").toDate();
    let endDateNew = dayjs(endDateRangeSchedule).toDate();
    let nextStartDateNew = dayjs(nextStartDateSchedule).startOf("day").toDate();
    let nextEndDateNew = dayjs(nextEndDateSchedule).toDate();
    let locationIds = [];
    if (scheduleLocations?.length > 0) {
      scheduleLocations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    let filterObj = {
      locationIds: locationIds,
      groupIds: groupIds,
      endDate: endDateNew,
      startDate: startDateNew,
      nextStartDate: nextStartDateNew,
      nextEndDate: nextEndDateNew,
      dateRange: dateRange,
      includeReviews: reviewsChecked,
    }; */

    /*   if (scheduleSentiments !== null) {
      filterObj["sentimentAnalysis"] = scheduleSentiments?.value;
      //   filterObj["sentimentValue"] = scheduleSentiments?.value;
    }
    if (scheduleLanguage !== null && scheduleLanguage !== "") {
      filterObj["languageCode"] = scheduleLanguage?.value;
    }

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    } */

    try {
      setStatusLoading(true);
      const res = await api.patch(`reportSchedules/${selectedRowData?.id}`, {
        filter: selectedRowData?.filter,
        locationIds: selectedRowData?.filter?.locationIds,
        startDate: selectedRowData?.startDate,
        endDate: selectedRowData?.endDate,
        fileType: selectedRowData?.fileType,
        emailSubject: selectedRowData?.emailSubject,
        language: selectedRowData?.language,
        emailBody: selectedRowData?.emailBody,
        emails: JSON.parse(selectedRowData?.emails),
        repeatType: selectedRowData?.repeatType,
        reportType: selectedRowData?.reportType,
        status: selectedRowData?.status,
        isPaused: pauseValue === true ? true : false,
        title: selectedRowData?.title,
        reportScheduleLocations: selectedRowData?.ReportScheduleLocations,
        includeReviews: reviewsChecked,
        includedSections: selectedRowData?.includedSections,
        customLogo: selectedRowData?.customLogo,
        noReviewsNotification: selectedRowData?.noReviewsNotification,
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setStatusLoading(false);
        handleCloseUpdateStatusModel();
        getAllScheduledReports();
      }
    } catch (error) {
      setStatusLoading(false);
      handleCloseUpdateStatusModel();
      toast.error(error?.response?.data?.data);
    }
  };
  const handleUpdateScheduledReport = async () => {
    let startDateNew = dayjs(startDateRangeSchedule).startOf("day").toDate();
    let endDateNew = dayjs(endDateRangeSchedule).toDate();
    let nextStartDateNew = dayjs(nextStartDateSchedule).startOf("day").toDate();
    let nextEndDateNew = dayjs(nextEndDateSchedule).toDate();
    let locationIds = [];
    if (scheduleLocations?.length > 0) {
      scheduleLocations?.map((item) => {
        locationIds.push(item?.id);
      });
    }

    const reportLoc = [];
    if (reportScheduleLocations.length > 0) {
      locationIds.forEach((itemOne) => {
        reportScheduleLocations.forEach((itemTwo) => {
          if (itemOne === itemTwo?.location?.id) {
            reportLoc.push(itemTwo);
          }
        });
      });
    }
    let groupIds = [];
    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    let filterObj = {
      locationIds: locationIds,
      groupIds: groupIds,
      endDate: endDateNew,
      startDate: startDateNew,
      nextStartDate: nextStartDateNew,
      nextEndDate: nextEndDateNew,
      dateRange: dateRange,
    };

    if (scheduleSentiments !== null) {
      filterObj["sentimentAnalysis"] = scheduleSentiments?.value;
      //   filterObj["sentimentValue"] = scheduleSentiments?.value;
    }
    if (scheduleLanguage !== null && scheduleLanguage !== "") {
      filterObj["languageCode"] = scheduleLanguage?.value;
    }

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }
    let tempFeatureObj = featuresObj;
    if (companyData?.id !== 175) {
      tempFeatureObj.emotionAITopics = false;
    } else {
      tempFeatureObj.onlineReputation = false;
    }

    try {
      setUpdateReportLoading(true);
      const res = await api.patch(`reportSchedules/${selectedEditId}`, {
        filter: filterObj,
        locationIds: locationIds,
        startDate: startDateSchedule,
        endDate: endDateSchedule,
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        emailSubject,
        emailBody,
        emails: emails,
        repeatType: repeatValue,
        reportType: "EmotionAI",
        status: singleReportData?.status,
        title: emailTitle,
        reportScheduleLocations: reportLoc,
        includeReviews: reviewsChecked,
        includedSections: tempFeatureObj,
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
        noReviewsNotification:
          yesChecked && noChecked === false
            ? true
            : yesChecked === false && noChecked === true
            ? false
            : true,
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setUpdateReportLoading(false);
        handleCloseCreateScheduleModal();
        setSelectedEditId(null);
        getAllScheduledReports();
      }
    } catch (error) {
      setUpdateReportLoading(false);
      if (
        error?.response?.data?.data ===
        "Report Schedule Already Exists With this Name"
      ) {
        setDisplayReportError(true);
      } else {
        toast.error(error?.response?.data?.data);
      }
    }
  };

  const handleDeleteScheduleReport = async () => {
    try {
      setDeleteScheduleReportLoading(true);
      const res = await api.delete(`/reportSchedules/${deletedIds}`);
      if (res.status === 200) {
        toast.success(t("report_deleted"));
        setDeleteScheduleReportLoading(false);
        if (scheduledRportsPageNumber === 0) {
          getAllScheduledReports();
        }
        setScheduledRportsPageNumber(0);
        handleCloseDeleteScheduledReportModel();
      }
    } catch (error) {
      setDeletedId(null);

      toast.error(error?.response?.data?.message);
      handleCloseDeleteScheduledReportModel();
      setDeleteScheduleReportLoading(false);
    }
  };
  const handleReviewsChecked = (e) => {
    setReviewsChecked(e.target.checked);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        let data;
        if (file.type === "text/csv") {
          const lines = fileContents.split("\n");
          data = lines.map((line) => line.replace("\r", "").split(","));
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const workbook = XLSX.read(fileContents, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          data = [];
          sheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            data.push(...jsonData);
          });
        } else {
          return;
        }
        const flatData = data.flat();

        setTimeout(() => {
          setFileLoading(false);
        }, 1000);
        setDataArray(flatData);
      };
      setFileLoading(true);

      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        reader.readAsBinaryString(file);
      }
      setOpenEmailModal(true);
      setSelectedFile(file.name);
    } else {
      setTimeout(() => {
        setFileLoading(false);
      }, 1000);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleCloseEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenEmailModal(false);

    setSelectAllCheckedModal(false);
  };
  const handleResetOthers = () => {
    setTotalEmailsArr([]);
    setSelectedItems([]);
    setValidEmailsArray([]);
    setAddNewUserEmails([]);
    setSelectedFile(null);
    setDataArray([]);
  };
  const handleChangeLanguageForScheduler = (value) => {
    setLanguageSchedular(value);
  };
  const handleResetEmailModel = () => {
    setUploadedImages([]);
    setImages([]);
    setOpenEmailModal(false);
    setDeletedId(null);
    setStartDateSchedule(null);
    setEndDateSchedule(null);
    setEmailTitle("");
    setSingleReportData(null);
    setSelectedEditId(null);
    setDownloadFormat("");
    setCompanyUser(true);
    setOthers(false);
    setXlsChecked(false);
    setPdfChecked(true);
    setYesChecked(true);
    setNoChecked(false);
    setRepeatValue("no");
    setTotalEmailsArr([]);
    setSelectedItems([]);
    setValidEmailsArray([]);
    setAddNewUserEmails([]);
    setSelectedFile(null);
    setDataArray([]);
    setSelectedCompanyUsers([]);
    setOthers(false);
    setFromHeader(false);
    setEmailSubject(`EmotionAI report of ${companyData?.name}`);
    setEmailBody(
      `Dear customer Please find attached emotion AI report for the ${dayjs(
        startDate
      ).format("MMM DD, YYYY")} to ${dayjs(endDate).format("MMM DD, YYYY")}`
    );
    setStartDateRangeSchedule(startDate);
    setEndDateRangeSchedule(endDate);
    setNextStartDateSchedule(nextStartDate);
    setNextEndDateSchedule(nextEndDate);
    setDateRange("custom");
    setScheduleLocations(locations);
    setScheduleGroups(selectedGroups);
    setScheduleSentiments(sentimentValue);
    setScheduleLanguage(language);
    setReviewsChecked(false);
    setFeaturesObj({
      ratingVariance: true,
      reviewsRatingGraph: true,
      onlineReputation: true,
      platformOverview: true,
      reviewDistribution: true,
      ratingBreakdown: true,
      emotionAITopics: true,
      reviewVelocity: true,
      actionPlan: true,
      latestImpression: true,
      keywordCloud: true,
      ratingBreakdownReviewReplied: true,
    });
    setSelectedFeatures([]);
    setSectionOption({
      id: 1,
      value: "completeReport",
      title: t("complete_ai_report"),
    });
    setSectionFormat("completeReport");
  };
  const handleReopenModal = () => {
    setOpenEmailModal(true);
  };
  const handleRemoveFile = () => {
    setDataArray([]);
    setSelectedFile(null);
    setSelectedItems([]);
  };

  const handleCancelEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenEmailModal(false);
    if (selectedItems.length <= 0) {
      setDataArray([]);
      setSelectedFile(null);
      setSelectedItems([]);
    }
    setSelectAllCheckedModal(false);
  };
  const handleOpenLocationGroupsModel = () => {
    setOpenLocationGroupsModel(true);
  };
  const handleCloseLocationGroupsModel = () => {
    setOpenLocationGroupsModel(false);
    setRowsPerPage(10);
    setGroupsPageNumber(0);
    setLocationGroups(null);

    setSelectAll(false);
  };

  const handleOpenDeleteScheduledReportModel = (deletedIds) => {
    setDeletedId(deletedIds);
    setOpenDeleteScheduledReportModel(true);
  };
  const handleCloseDeleteScheduledReportModel = () => {
    setOpenDeleteScheduledReportModel(false);
    setDeletedId(null);
  };

  const handleOpenTemplateMenu = () => {
    setOpenIframeModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenIframeModel(false);
  };

  const handleChangeFeature = (value) => {
    let tempFeatureObj = featuresObj;
    if (value?.length > 0) {
      Object.keys(tempFeatureObj)?.map((itemTwo) => {
        const valueExist = value?.some((item) => item?.key === itemTwo);
        if (valueExist) {
          tempFeatureObj[itemTwo] = true;
        } else {
          tempFeatureObj[itemTwo] = false;
        }
      });
    } else {
      Object.keys(tempFeatureObj)?.map((itemTwo) => {
        tempFeatureObj[itemTwo] = false;
      });
    }
    setFeaturesObj(tempFeatureObj);
  };

  const handleChangeManageSections = (event, newValue) => {
    setSectionOption(newValue);
    setSectionFormat(newValue?.value);

    if (newValue !== null) {
      if (newValue.value === "completeReport") {
        handleChangeFeature(
          companyData !== undefined &&
            companyData !== null &&
            companyData?.id === 175
            ? featuresArray
            : featuresArrayWithoutTopics
        );
        setSelectedFeatures([]);
      } else if (newValue.value === "specificReport") {
        setFeaturesObj({
          ratingVariance: false,
          reviewsRatingGraph: false,
          onlineReputation: false,
          platformOverview: false,
          reviewDistribution: false,
          ratingBreakdown: false,
          reviewVelocity: false,
          actionPlan: false,
          latestImpression: false,
          keywordCloud: false,
          emotionAITopics: false,
          ratingBreakdownReviewReplied: false,
        });
      }
    }
  };

  return (
    <>
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseTemplateMenu}
        >
          <Iframe
            title={t("EmotionAI_Tutorial")}
            onCancel={handleCloseTemplateMenu}
            iframe={
              <iframe
                title="iframe"
                loading="lazy"
                src="https://ask.obenan.com/f4tkmskma"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="1200px"
                height="500px"
                onLoad={() => {}}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            }
          />
        </Dialog>
      )}
      {openUpdateStatusModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openUpdateStatusModel}
          onClose={handleCloseUpdateStatusModel}
        >
          <Delete
            title={pauseValue ? t("pause_report") : t("resume_report")}
            description={
              pauseValue
                ? t("pause_confirmation_text")
                : t("resume_confirmation_text")
            }
            onConfirm={handleUpdateStatus}
            onCancel={handleCloseUpdateStatusModel}
            loading={statusLoading}
            schedule={true}
          />
        </Dialog>
      )}
      {openLocationGroupsModel && (
        <LocationGroupsModel
          title={t("location_groups")}
          open={openLocationGroupsModel}
          handleCloseNewRequestModel={handleCloseLocationGroupsModel}
          allLocations={allLocations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          getsentimentalLocation={getsentimentalLocation}
          requestLoading={requestLoading}
          groupsPageNumber={groupsPageNumber}
          setGroupsPageNumber={setGroupsPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          locationGroups={locationGroups}
          groupsLoading={groupsLoading}
          selectAll={selectAll}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
        />
      )}
      {openSchduleModal && (
        <SecheduledReports
          title={t("Secheduled_Reports")}
          open={openSchduleModal}
          handleCloseNewRequestModel={handleCloseScheduleModal}
          handleOpenCreateScheduleModal={handleOpenCreateScheduleModal}
          allLocations={allLocations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          getsentimentalLocation={getsentimentalLocation}
          scheduledReportsLoading={scheduledReportsLoading}
          scheduledRportsPageNumber={scheduledRportsPageNumber}
          setScheduledRportsPageNumber={setScheduledRportsPageNumber}
          rowsPerPageScheduled={rowsPerPageScheduled}
          setRowsPerPageScheduled={setRowsPerPageScheduled}
          scheduledReports={scheduledReports}
          handleOpenDeleteScheduledReportModel={
            handleOpenDeleteScheduledReportModel
          }
          selectAll={selectAll}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
          handleOpenUpdateStatusModel={handleOpenUpdateStatusModel}
        />
      )}
      {openCreateSchedule && (
        <SecheduleReport
          open={openCreateSchedule}
          title={t("Sechedule_Report")}
          handleCloseNewRequestModel={handleCloseCreateScheduleModal}
          others={others}
          setOthers={setOthers}
          companyUser={companyUser}
          setCompanyUser={setCompanyUser}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          recipientError={recipientError}
          setRecipientError={setRecipientError}
          recipientEmailError={recipientEmailError}
          setRecipientEmailError={setRecipientEmailError}
          recipientEmail={recipientEmail}
          setRecipientEmail={setRecipientEmail}
          recipientValue={recipientValue}
          setRecipientValue={setRecipientValue}
          emailReportRef={emailReportRef}
          setSelectedCompanyUsers={setSelectedCompanyUsers}
          selectedCompanyUsers={selectedCompanyUsers}
          totalEmailsArr={totalEmailsArr}
          isModalEmailsSent={isModalEmailsSent}
          handleAddNewUserEmailsChange={handleAddNewUserEmailsChange}
          addNewUserEmials={addNewUserEmials}
          handleResetOthers={handleResetOthers}
          handleButtonClick={handleButtonClick}
          handleFileUpload={handleFileUpload}
          selectedFile={selectedFile}
          fileInputRef={fileInputRef}
          handleReopenModal={handleReopenModal}
          handleRemoveFile={handleRemoveFile}
          exportReportLoading={exportReportLoading}
          setEmailSubject={setEmailSubject}
          emailSubject={emailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          handleScheduleReport={handleScheduleReport}
          scheduleReportLoading={scheduleReportLoading}
          repeatValue={repeatValue}
          setRepeatValue={setRepeatValue}
          getSingleSelectedReportById={getSingleSelectedReportById}
          singleReportData={singleReportData}
          handleUpdateScheduledReport={handleUpdateScheduledReport}
          updateReportLoading={updateReportLoading}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          startDateSchedule={startDateSchedule}
          setStartDateSchedule={setStartDateSchedule}
          endDateSchedule={endDateSchedule}
          setEndDateSchedule={setEndDateSchedule}
          handleChangeStartDateSchedule={handleChangeStartDateSchedule}
          handleChangeEndDateSchedule={handleChangeEndDateSchedule}
          setDateRange={setDateRange}
          dateRange={dateRange}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          handleChangeRangeEndDateSchedule={handleChangeRangeEndDateSchedule}
          endDateRangeSchedule={endDateRangeSchedule}
          handleChangeRangeStartDateSchedule={
            handleChangeRangeStartDateSchedule
          }
          startDateRangeSchedule={startDateRangeSchedule}
          setStartDateRangeSchedule={setStartDateRangeSchedule}
          setEndDateRangeSchedule={setEndDateRangeSchedule}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          setScheduleLanguage={setScheduleLanguage}
          scheduleLanguage={scheduleLanguage}
          setScheduleSentiments={setScheduleSentiments}
          scheduleSentiments={scheduleSentiments}
          allLocations={allLocations}
          allGroups={allGroups}
          startDate={startDate}
          endDate={endDate}
          setNextEndDateSchedule={setNextEndDateSchedule}
          nextStartDateSchedule={nextStartDateSchedule}
          nextEndDateSchedule={nextEndDateSchedule}
          setNextStartDateSchedule={setNextStartDateSchedule}
          nextEndDate={nextEndDate}
          nextStartDate={nextStartDate}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          reportFormat={reportFormat}
          setReportFormat={setReportFormat}
          locations={locations}
          selectedGroups={selectedGroups}
          reviewsChecked={reviewsChecked}
          setReviewsChecked={setReviewsChecked}
          handleReviewsChecked={handleReviewsChecked}
          displayReportError={displayReportError}
          setDisplayReportError={setDisplayReportError}
          featuresArray={
            companyData !== undefined &&
            companyData !== null &&
            companyData?.id === 175
              ? featuresArray
              : featuresArrayWithoutTopics
          }
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
          handleChangeFeature={handleChangeFeature}
          sectionOption={sectionOption}
          setSectionOption={setSectionOption}
          sectionFormat={sectionFormat}
          setSectionFormat={setSectionFormat}
          handleChangeManageSections={handleChangeManageSections}
          manageSectionOptions={manageSectionOptions}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
          setFeaturesObj={setFeaturesObj}
          setReportScheduleLocations={setReportScheduleLocations}
          countries={countries}
          defaultLocation={defaultLocation}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          autoSelectHeader={autoSelectHeader}
          isSentimentalAnalysisDone={isSentimentalAnalysisDone}
          selectAllGroups={selectAllGroups}
          setSelectAllLocations={setSelectAllLocations}
          selectAllLocations={selectAllLocations}
          yesChecked={yesChecked}
          setYesChecked={setYesChecked}
          noChecked={noChecked}
          setNoChecked={setNoChecked}
          handleChangeDateRangesfromRangeModel={
            handleChangeDateRangesfromRangeModel
          }
          handleChangeDateRangesfromRangeModel={
            handleChangeDateRangesfromRangeModel
          }
        />
      )}

      {openDownloadPdfModal && (
        <ExportFileDownloadModal
          open={openDownloadPdfModal}
          title={t("download_report_title")}
          handleCloseNewRequestModel={handleCloseExportFileDownloadModal}
          handleDownloadEmotionAiReport={handleDownloadEmotionAiReport}
          pdfChecked={pdfDownloadChecked}
          setPdfChecked={setPdfDownloadChecked}
          xlsChecked={xlsDownloadChecked}
          setXlsChecked={setXlsDownloadChecked}
          emailReportRef={emailReportRef}
          exportReportLoading={exportReportLoading}
          setExportReportLoading={setExportReportLoading}
          reviewsChecked={reviewsChecked}
          setReviewsChecked={setReviewsChecked}
          handleReviewsChecked={handleReviewsChecked}
          setDateRange={setDateRange}
          dateRange={dateRange}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          handleChangeRangeEndDateSchedule={handleChangeRangeEndDateSchedule}
          endDateRangeSchedule={endDateRangeSchedule}
          handleChangeRangeStartDateSchedule={
            handleChangeRangeStartDateSchedule
          }
          startDateRangeSchedule={startDateRangeSchedule}
          setStartDateRangeSchedule={setStartDateRangeSchedule}
          setEndDateRangeSchedule={setEndDateRangeSchedule}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          setScheduleLanguage={setScheduleLanguage}
          scheduleLanguage={scheduleLanguage}
          setScheduleSentiments={setScheduleSentiments}
          scheduleSentiments={scheduleSentiments}
          allLocations={allLocations}
          allGroups={allGroups}
          startDate={startDate}
          endDate={endDate}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          setEmailBody={setEmailBody}
          setEmailTitle={setEmailTitle}
          emailTitle={emailTitle}
          featuresArray={
            companyData !== undefined &&
            companyData !== null &&
            companyData?.id === 175
              ? featuresArray
              : featuresArrayWithoutTopics
          }
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
          handleChangeFeature={handleChangeFeature}
          fromHeader={fromHeader}
          sectionOption={sectionOption}
          setSectionOption={setSectionOption}
          sectionFormat={sectionFormat}
          handleChangeManageSections={handleChangeManageSections}
          manageSectionOptions={manageSectionOptions}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
          countries={countries}
          defaultLocation={locations}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          autoSelectHeader={autoSelectHeader}
          isSentimentalAnalysisDone={isSentimentalAnalysisDone}
          selectAll={selectAllGroups}
          setSelectAllLocations={setSelectAllLocations}
          selectAllLocations={selectAllLocations}
        />
      )}

      {openEmailModal && (
        <FetchEmailsModel
          title={t("fetched_emails")}
          subTitle={t("model_subHeading")}
          open={openEmailModal}
          handleCloseNewRequestModel={handleCloseEmailsModal}
          handleCancelEmailsModal={handleCancelEmailsModal}
          dataArray={dataArray}
          totalModalEmailsArr={totalModalEmailsArr}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectAllChecked={selectAllCheckedModal}
          setSelectAllChecked={setSelectAllCheckedModal}
          handleSubmitOk={handleSubmitOk}
          isLoading={fileLoading}
          isModalEmailsSent={isModalEmailsSent}
          setIsModalEmailsSent={setIsModalEmailsSent}
        />
      )}
      {openReportEmailModal && (
        <EmailReportModal
          open={openReportEmailModal}
          title={t("email_this_report")}
          handleCloseNewRequestModel={handleCloseEmailReportModal}
          others={others}
          setOthers={setOthers}
          companyUser={companyUser}
          setCompanyUser={setCompanyUser}
          handleEmailEmotionAiReport={handleEmailEmotionAiReport}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          recipientError={recipientError}
          setRecipientError={setRecipientError}
          recipientEmailError={recipientEmailError}
          setRecipientEmailError={setRecipientEmailError}
          recipientEmail={recipientEmail}
          setRecipientEmail={setRecipientEmail}
          recipientValue={recipientValue}
          setRecipientValue={setRecipientValue}
          emailReportRef={emailReportRef}
          setSelectedCompanyUsers={setSelectedCompanyUsers}
          selectedCompanyUsers={selectedCompanyUsers}
          totalEmailsArr={totalEmailsArr}
          isModalEmailsSent={isModalEmailsSent}
          handleAddNewUserEmailsChange={handleAddNewUserEmailsChange}
          addNewUserEmials={addNewUserEmials}
          handleResetOthers={handleResetOthers}
          handleButtonClick={handleButtonClick}
          handleFileUpload={handleFileUpload}
          selectedFile={selectedFile}
          fileInputRef={fileInputRef}
          handleReopenModal={handleReopenModal}
          handleRemoveFile={handleRemoveFile}
          exportReportLoading={exportReportLoading}
          setEmailSubject={setEmailSubject}
          emailSubject={emailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          handleReviewsChecked={handleReviewsChecked}
          reviewsChecked={reviewsChecked}
          setReviewsChecked={setReviewsChecked}
          setDateRange={setDateRange}
          dateRange={dateRange}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          handleChangeRangeEndDateSchedule={handleChangeRangeEndDateSchedule}
          endDateRangeSchedule={endDateRangeSchedule}
          handleChangeRangeStartDateSchedule={
            handleChangeRangeStartDateSchedule
          }
          startDateRangeSchedule={startDateRangeSchedule}
          setStartDateRangeSchedule={setStartDateRangeSchedule}
          setEndDateRangeSchedule={setEndDateRangeSchedule}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          setScheduleLanguage={setScheduleLanguage}
          scheduleLanguage={scheduleLanguage}
          setScheduleSentiments={setScheduleSentiments}
          scheduleSentiments={scheduleSentiments}
          allLocations={allLocations}
          allGroups={allGroups}
          startDate={startDate}
          endDate={endDate}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          featuresArray={
            companyData !== undefined &&
            companyData !== null &&
            companyData?.id === 175
              ? featuresArray
              : featuresArrayWithoutTopics
          }
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
          handleChangeFeature={handleChangeFeature}
          fromHeader={fromHeader}
          sectionOption={sectionOption}
          setSectionOption={setSectionOption}
          sectionFormat={sectionFormat}
          handleChangeManageSections={handleChangeManageSections}
          manageSectionOptions={manageSectionOptions}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
          countries={countries}
          defaultLocation={defaultLocation}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          autoSelectHeader={autoSelectHeader}
          isSentimentalAnalysisDone={isSentimentalAnalysisDone}
          selectAllGroups={selectAllGroups}
          setSelectAllLocations={setSelectAllLocations}
          selectAllLocations={selectAllLocations}
        />
      )}

      {openReviewModel && (
        <ReviewModel
          open={openReviewModel}
          typeParam={typeParam}
          title={`${t("Reviewss")}`}
          reviewsTitle={reviewsTitle}
          handleCloseReviewModel={handleCloseReviewModel}
          reviewsData={reviewsData}
          setReviewsData={setReviewsData}
          location={locations}
          sentimentValue={sentimentValue}
          topicId={selectedTopic?.topicId}
          startDate={startDate}
          endDate={endDate}
          language={language}
          emoationAIDetailsLoading={emoationAIDetailsLoading}
          setEmotionAIDetailsLoading={setEmotionAIDetailsLoading}
          emotionAIDetailsData={emotionAIDetailsData}
          setEmotionAIDetailsData={setEmotionAIDetailsData}
          varianceType={varianceType}
          setVarianceType={setVarianceType}
          thirdPartySourceId={thirdPartySourceId}
          selectedLocationId={selectedLocationId}
          reviewsLoading={reviewsLoading}
          setReviewsLoading={setReviewsLoading}
          ratingTeXTKeywords={ratingTeXTKeywords}
          ratingBreakdownValue={ratingBreakdownValue}
          handleDisplayNames={handleDisplayNames}
          isLoadingMoreData={isLoadingMoreData}
          setIsLoadingMoreData={setIsLoadingMoreData}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          apiCallsFrom={apiCallsFrom}
          platformLocations={platformLocations}
          platformGroups={platformGroups}
          fromPlatform={fromPlatform}
          keywordsSentiments={keywordsSentiments}
          selectedGroupId={selectedGroupId}
          locations={locations}
          selectedGroups={selectedGroups}
          allReviewSource={allReviewSource}
          nextStartDate={nextStartDate}
          nextEndDate={nextEndDate}
          selectedTopicName={selectedTopicName}
        />
      )}
      {openRequest && (
        <EmotionAiRequestModel
          title={t("new_req_des")}
          open={openRequest}
          handleCloseNewRequestModel={handleCloseNewRequestModel}
          allLocations={allLocations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          getsentimentalLocation={getsentimentalLocation}
          requestLoading={requestLoading}
          userData={user}
        />
      )}

      {openDeleteScheduledReportModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDeleteScheduledReportModel}
          onClose={handleCloseDeleteScheduledReportModel}
        >
          <Delete
            title={t("scheduled_report_delete")}
            description={t("confirm_delete_scheduled_report")}
            onConfirm={handleDeleteScheduleReport}
            onCancel={handleCloseDeleteScheduledReportModel}
            loading={deleteScheduleReportLoading}
          />
        </Dialog>
      )}

      {locationLoading ? (
        <Loader />
      ) : displayError ? (
        <>
          <TitleHeader
            showButton={false}
            title={t("Emotion_AI")}
            handleDefaultTemplate={handleOpenNewRequestModel}
            extraButtonName={t("Contact_Support")}
            emotionAiRequestButton={true}
            addPermission={addPermission}
            viewPermission={viewPermission}
          />

          <Box className={classes.locationbox}>
            <SentimentsNotFound
              text={t("Emotion_AI")}
              subtext={t("No_emotionai_descr")}
              onSubmit={handleOpenNewRequestModel}
            />
          </Box>
        </>
      ) : allLocations?.length > 0 ? (
        <>
          {" "}
          <TitleHeader
            showButton={false}
            title={t("Emotion_AI")}
            IframeButtonName={
              <Tooltip title={t("Help")}>
                <img src={QuestionMark} />
              </Tooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
            handleDefaultTemplate={handleOpenNewRequestModel}
            extraButtonName={t("Contact_Support")}
            groupButtonFromEmotionAi={true}
            locationViewPermission={locationViewPermission}
            groupButtonName={t("manage_groups")}
            handleNavigationToGroups={handleOpenLocationGroupsModel}
            emotionAiEcportButton={true}
            name={t("export")}
            emotionAiRequestButton={true}
            addPermission={addPermission}
            viewPermission={viewPermission}
            anchorBulk={anchorBulk}
            handleClose={handleClose}
            handleClickMenu={handleClickMenu}
            handleClickMenuItem={handleClickMenuItem}
            sheduleMenu={locations?.length > 0 ? true : false}
          />
          {/********* Filter Header **************/}
          <Grid container spacing={2}>
            <FilterHeader
              allLocations={allLocations}
              setAllLocations={setAllLocations}
              locations={locations}
              setLocations={setLocations}
              setRange={setRange}
              range={range}
              handleChangeRange={handleChangeRange}
              startDate={startDate}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
              endDate={endDate}
              openModalFilter={openModalFilter}
              handleOpenEditUser={handleOpenEditUser}
              handleChangeLocation={handleChangeLocation}
              handleCloseModalFilter={handleCloseModalFilter}
              handleOpenCompareDatesModel={handleOpenCompareDatesModel}
              handleCloseCompareDatesModel={handleCloseCompareDatesModel}
              openCompareModel={openCompareModel}
              resetHandler={resetHandler}
              resetCompareModel={resetCompareModel}
              handleChangeSentiment={handleChangeSentiment}
              sentimentValue={sentimentValue}
              handleChangeLanguage={handleChangeLanguage}
              language={language}
              errorMessage={errorMessage}
              allGroups={allGroups}
              setSelectedGroups={setSelectedGroups}
              selectedGroups={selectedGroups}
              handleChangeGroups={handleChangeGroups}
              handleChangeGroupsForLocations={handleChangeGroupsForLocations}
              selectAll={selectAllGroups}
              setSelectAll={setSelectAllGroups}
              handleChangeNextStartDate={handleChangeNextStartDate}
              handleChangeNextEndDate={handleChangeNextEndDate}
              compareRange={compareRange}
              handleChangeCompareRange={handleChangeCompareRange}
              nextEndDate={nextEndDate}
              nextStartDate={nextStartDate}
              handleApplyFilter={handleApplyFilter}
              setCallApi={setCallApi}
              countries={countries}
              resetStart={previousYear}
              resetEnd={today}
              resetNextStart={newwww}
              resetNextEnd={previousYear}
              locationAddPermission={locationAddPermission}
              locationViewPermission={locationViewPermission}
              defaultLocation={defaultLocation}
              setAutoSelectHeader={setAutoSelectHeader}
              autoSelectHeader={autoSelectHeader}
              isSentimentalAnalysisDone={isSentimentalAnalysisDone}
              setSelectAllLocations={setSelectAllLocations}
              selectAllLocations={selectAllLocations}
            />
          </Grid>
          {/********* Location Review Summary  **************/}
          <Grid container ref={emailReportRef}>
            <Grid container className={classes.containerSpacing}>
              <ReviewsDetail
                ratingVarianceData={ratingVarianceData}
                reviewVelocityData={reviewVelocityData}
                ratingVarianceLoading={ratingVarianceLoading}
                allLocations={allLocations}
                platformOverviewData={platformOverviewData}
                platformData={platformData}
                platformOverviewLoading={platformOverviewLoading}
                ratingBreakDownData={ratingBreakDownData}
                ratingLoading={ratingLoading}
                onlineReputationData={onlineReputationData}
                reputationLoading={reputationLoading}
                keywordsLoading={keywordsLoading}
                reviewTopics={reviewTopics}
                selectedTopic={selectedTopic}
                handleChangeTopic={handleChangeTopic}
                kFormatter={kFormatter}
                getReviewTopics={getReviewTopics}
                setReviewTopics={setReviewTopics}
                topicsLoading={topicsLoading}
                keywordsData={keywordsData}
                handleOpenReviewModel={handleOpenReviewModel}
                locations={locations}
                setLocations={setLocations}
                startDate={startDate}
                endDate={endDate}
                filteredLocations={filteredLocations}
                filterBreakdownLocation={filterBreakdownLocation}
                graphData={graphData}
                setGraphData={setGraphData}
                sentimentValue={sentimentValue}
                language={language}
                setVarianceType={setVarianceType}
                setSelectedLocationId={setSelectedLocationId}
                setSelectedGroupId={setSelectedGroupId}
                setThirdPartySourceId={setThirdPartySourceId}
                setRatingTextKeywords={setRatingTextKeywords}
                setRatingBreakdownValue={setRatingBreakdownValue}
                handleDisplayNames={handleDisplayNames}
                handleDisplayGroupNames={handleDisplayGroupNames}
                handleDisplayGroupNameSimple={handleDisplayGroupNameSimple}
                setApiCallFrom={setApiCallFrom}
                tooltipContent={tooltipContent}
                setKeywordsSentiment={setKeywordsSentiment}
                allGroups={allGroups}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                handleOpenExportFileDownloadModal={
                  handleOpenExportFileDownloadModal
                }
                emailReportRef={emailReportRef}
                reviewDistributionData={reviewDistributionData}
                reviewDistributionLoading={reviewDistributionLoading}
                reviewAverageRatingData={reviewAverageRatingData}
                reviewAverageRatingLoading={reviewAverageRatingLoading}
                viewPermission={viewPermission}
                pdfChecked={pdfChecked}
                pdfDownloadChecked={pdfDownloadChecked}
                openDownloadPdfModal={openDownloadPdfModal}
                openReportEmailModal={openReportEmailModal}
                handleDisplayExportLocationName={
                  handleDisplayExportLocationName
                }
                prevTooltipContent={prevTooltipContent}
                reviewDistributionTableData={reviewDistributionTableData}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
                topicsData={topicsData}
                topicsDataLoading={topicsDataLoading}
                setSelectedTopicName={setSelectedTopicName}
              />
            </Grid>
            {(googleReviewData?.length > 0 ||
              groupsReviewsFromatedData?.length > 0) && (
              <Grid container className={classes.containerSpacing}>
                <Review
                  summaryLoading={summaryLoading}
                  googleReviewData={googleReviewData}
                  locations={locations}
                  downloadFormat={downloadFormat}
                  setDownloadFormat={setDownloadFormat}
                  viewPermission={viewPermission}
                  pdfChecked={pdfChecked}
                  pdfDownloadChecked={pdfDownloadChecked}
                  openDownloadPdfModal={openDownloadPdfModal}
                  openEmailModal={openEmailModal}
                  handleDisplayNames={handleDisplayNames}
                  groupsReviewsFromatedData={groupsReviewsFromatedData}
                  handleDisplayGroupNames={handleDisplayGroupNames}
                  handleDisplayExportLocationName={
                    handleDisplayExportLocationName
                  }
                  handleDisplayGroupNameSimple={handleDisplayGroupNameSimple}
                  setFeaturesObj={setFeaturesObj}
                  featuresObj={featuresObj}
                />
              </Grid>
            )}

            {(actionPlans?.length > 0 ||
              groupsActionPlansFromatedData?.length > 0) && (
              <Grid container className={classes.containerSpacing}>
                <ActionPlans
                  actionPlans={actionPlans}
                  actionPlansLoading={actionPlansLoading}
                  locations={locations}
                  downloadFormat={downloadFormat}
                  setDownloadFormat={setDownloadFormat}
                  viewPermission={viewPermission}
                  pdfChecked={pdfChecked}
                  pdfDownloadChecked={pdfDownloadChecked}
                  openDownloadPdfModal={openDownloadPdfModal}
                  openReportEmailModal={openReportEmailModal}
                  handleDisplayNames={handleDisplayNames}
                  groupsActionPlansFromatedData={groupsActionPlansFromatedData}
                  handleDisplayExportLocationName={
                    handleDisplayExportLocationName
                  }
                  handleDisplayGroupNameSimple={handleDisplayGroupNameSimple}
                  handleDisplayGroupNames={handleDisplayGroupNames}
                  setFeaturesObj={setFeaturesObj}
                  featuresObj={featuresObj}
                />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <TitleHeader
            showButton={false}
            title={t("Emotion_AI")}
            handleDefaultTemplate={handleOpenNewRequestModel}
            extraButtonName={t("Contact_Support")}
          />
          <Box className={classes.locationbox}>
            <LocationNotFound
              text={t("No_Location_Added")}
              subtext={t("add_location_text")}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Index;
