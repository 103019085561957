import React, { useEffect, useState, useRef } from "react";
import { api } from "../../../contexts/JWTContext";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  Select,
  MenuItem,
  tooltipClasses,
  Autocomplete,
  TextField,
  createFilterOptions,
  IconButton,
} from "@mui/material";
import { Alert } from "@mui/lab";
import LogoImageModal from "../../../pages/UserPages/EmotionAi/Components/ExportDashboard/Modals/UploadModels/CoverImageModal.js";
import { useLocation } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { languagesData } from "../../../assets/defaultLanguage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import Loader from "../../Loaders/Loader";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../pages/UserPages/EmotionAi/Style/style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import dayjs from "dayjs";
import Collapsible from "../../CustomComponents/Collapsible.js";
import EmotionAiFilterModal from "../../../pages/UserPages/EmotionAi/Components/Scheduler/FilterModelNew.js";
import FilterModelReviews from "../../../pages/UserPages/LocationSourceReviews/Components/Models/ScheduleFiltersModel";
import DatePickerComponent from "../../CustomComponents/DateRangePicker/RangePicker.js";
import FilterModal from "../FilterModalReviews/index.js";
import moment from "moment";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseNewRequestModel,
  handleSubmitOk,
  handleCancelEmailsModal,
  companyUser,
  setCompanyUser,
  others,
  setOthers,
  handleDownloadPdf,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  setSelectedCompanyUsers,
  selectedCompanyUsers,
  totalEmailsArr,
  isModalEmailsSent,
  handleAddNewUserEmailsChange,
  addNewUserEmials,
  handleResetOthers,
  handleButtonClick,
  handleFileUpload,
  selectedFile,
  fileInputRef,
  handleReopenModal,
  handleRemoveFile,
  exportReportLoading,
  setEmailSubject,
  emailSubject,
  setEmailBody,
  emailBody,
  handleScheduleReport,
  scheduleReportLoading,
  repeatValue,
  setRepeatValue,
  getSingleSelectedReportById,
  singleReportData,
  handleUpdateScheduledReport,
  updateReportLoading,
  emailTitle,
  setEmailTitle,
  startDateSchedule,
  setStartDateSchedule,
  endDateSchedule,
  setEndDateSchedule,
  handleChangeEndDateSchedule,
  setDateRange,
  dateRange,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setLanguageSchedular,
  handleChangeStartDateSchedule,
  handleChangeRangeEndDateSchedule,
  endDateRangeSchedule,
  handleChangeRangeStartDateSchedule,
  startDateRangeSchedule,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  setScheduleLanguage,
  scheduleLanguage,
  setScheduleSentiments,
  scheduleSentiments,
  setStartDateRangeSchedule,
  setEndDateRangeSchedule,
  allLocations,
  allGroups,
  startDate,
  endDate,
  setNextEndDateSchedule,
  nextStartDateSchedule,
  nextEndDateSchedule,
  setNextStartDateSchedule,
  nextEndDate,
  nextStartDate,
  handleResetScheduleFiltersModel,
  scheduleReviewSource,
  setScheduleReviewSource,
  scheduleTags,
  setScheduleTags,
  scheduleRatingValue,
  setScheduleRatingValue,
  scheduleReviewerName,
  setScheduleReviewerName,
  scheduleReply,
  setScheduleReply,
  allReviewSource,
  starRatings,
  allTags,
  haventReplied,
  handleChangeDateRange,
  setReportFormat,
  locations,
  selectedGroups,
  setStartDate,
  setEndDate,
  reviewsChecked,
  setReviewsChecked,
  handleReviewsChecked,
  displayError,
  setDisplayError,
  displayReportError,
  setDisplayReportError,
  featuresArray,
  setSelectedFeatures,
  selectedFeatures,
  handleChangeFeature,
  handleChangeManageSections,
  sectionFormat,
  manageSectionOptions,
  sectionOption,
  setSectionOption,
  setSectionFormat,
  uploadedImages,
  setUploadedImages,
  images,
  setImages,
  setFeaturesObj,
  setReportScheduleLocations,
  countries,
  defaultLocation,
  locationAddPermission,
  locationViewPermission,
  autoSelectHeader,
  isSentimentalAnalysisDone,
  selectAllGroups,
  setSelectAllLocations,
  selectAllLocations,
  modalFilters,
  modalFiltersDate,
  setModalFiltersDate,
  setModalFilters,
  checked,
  yesChecked,
  setYesChecked,
  noChecked,
  setNoChecked,
  handleChangeDateRangesfromRangeModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const textArea = useRef();
  const location = useLocation();
  const currentPath = location.pathname.replace(/\/+$/, "");
  const [users, setUsers] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState(["configureReport"]);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [isLoading, setIsLoading] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [selectAll, setSelectAll] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [dateRangeError, setDateRangeError] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [startDateInvalid, setStartDateInvalid] = useState(null);
  const [emailSubjectRequired, setEmailSubjectRequired] = useState(false);
  const [greaterError, setGreaterError] = useState(false);
  const [openModalFilter, setOpenFilterModel] = useState(false);
  const [openReviewsFilterModel, setOpenReviewsFilterModel] = useState(false);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);
  const [selectedFileForImage, setSelectedFileForImage] = useState(null);
  const [autoSelect, setAutoSelect] = useState(autoSelectHeader);
  const [selectAllFiltersLocations, setSelectAllFiltersLocations] =
    useState(selectAllLocations);
  const [selectAllFiltersGroups, setSelectAllFiltersGroups] =
    useState(selectAllGroups);
  const timePeriod = [
    { id: 0, name: "All", value: "All" },
    { id: 1, name: "today", value: "Today" },
    { id: 2, name: "yesterday", value: "Yesterday" },
    { id: 3, name: "2days", value: "2days" },
    { id: 4, name: "7days", value: "7days" },
    { id: 5, name: "week", value: "week" },
    { id: 6, name: "14days", value: "14days" },
    { id: 7, name: "30days", value: "30days" },
    { id: 8, name: "monthValue", value: "month" },
    { id: 9, name: "60days", value: "60days" },
    { id: 10, name: "90days", value: "90days" },
    { id: 11, name: "6months", value: "6months" },
    { id: 12, name: "12months", value: "12months" },
    { id: 12, name: "custom_text", value: "custom" },
  ];

  useEffect(() => {
    getAllUsers();
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
    /* if (setScheduleLanguage) {
      setScheduleLanguage(userLang);
    } */
    getSingleSelectedReportById();
  }, [open]);

  useEffect(() => {
    if (
      startDateSchedule &&
      endDateSchedule &&
      dayjs(startDateSchedule).isAfter(endDateSchedule)
    ) {
      setGreaterError(true);
    } else {
      setGreaterError(false);
    }
  }, [startDateSchedule, endDateSchedule]);

  useEffect(() => {
    if (singleReportData !== null && singleReportData !== undefined) {
      setDisplayReportError(false);
      const userLang = languagesData?.find(
        (item) => item?.value === i18nextLng
      );

      setDateRange(singleReportData?.filter?.dateRange);

      if (currentPath === "/user/reviews") {
        setModalFilters((prevFilters) => ({
          ...prevFilters,
          timePeriod: singleReportData?.filter?.dateRange,
          time: timePeriod.find(
            (item) => item.value === singleReportData?.filter?.dateRange
          ),
        }));
        if (singleReportData?.filter?.hasOwnProperty("filters")) {
          if (singleReportData?.filter?.filters?.hasOwnProperty("locationId")) {
            if (singleReportData?.filter?.filters?.locationId?.length > 0) {
              let locationArr = [];
              singleReportData?.filter?.filters?.locationId?.map((item) => {
                allLocations?.results?.map((itemTwo) => {
                  if (item === itemTwo?.id) {
                    locationArr?.push(itemTwo);
                  }
                });
              });
              let locationIds = locationArr?.map((item) => item.id);
              setModalFilters((prevFilters) => ({
                ...prevFilters,
                location: locationArr,
                locations: locationIds,
              }));
              setScheduleLocations(locationArr);
            } else {
              setModalFilters((prevFilters) => ({
                ...prevFilters,
                location: [],
                locations: [],
              }));
              setScheduleLocations([]);
            }
          }
        }
        if (singleReportData?.ReportScheduleLocations?.length > 0) {
          const uniqueLocations = [];
          const uniqueIds = new Set();

          for (const postLocation of singleReportData?.ReportScheduleLocations) {
            const locationId =
              postLocation?.Location !== null
                ? postLocation?.Location?.id
                : null;

            if (!uniqueIds.has(locationId)) {
              uniqueIds.add(locationId);
              uniqueLocations.push(postLocation);
            }
          }
          setReportScheduleLocations(uniqueLocations);
        } else {
          setReportScheduleLocations([]);
        }
        if (singleReportData?.filter?.hasOwnProperty("groupId")) {
          const groupId = singleReportData?.filter?.groupId;

          let filteredGroup = [];

          if (typeof groupId === "number") {
            filteredGroup = allGroups?.rows?.filter(
              (obj) => obj.id === groupId
            );
          } else if (Array.isArray(groupId)) {
            filteredGroup = allGroups?.rows?.filter((obj) =>
              groupId?.includes(obj?.id)
            );
          }

          setModalFilters((prevFilters) => ({
            ...prevFilters,
            groups: filteredGroup?.map((item) => item.id),
            group: filteredGroup,
          }));

          setScheduleGroups(filteredGroup);
          /*   const filteredGroup = allGroups?.rows?.filter((obj) =>
            singleReportData?.filter?.groupId.includes(obj.id)
          );
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            groups: filteredGroup?.map((item) => item.id),
            group: filteredGroup,
          }));
          setScheduleGroups(filteredGroup); */
        } else {
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            groups: [],
            group: [],
          }));
        }
        if (singleReportData?.filter?.hasOwnProperty("search")) {
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            reviewedBy: singleReportData?.filter?.search,
          }));
          setScheduleReviewerName(singleReportData?.filter?.search);
        } else {
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            reviewedBy: "",
          }));
        }
        if (singleReportData?.filter?.hasOwnProperty("isDraft")) {
          setScheduleReply({ name: `${t("Drafted_Action")}`, value: "true" });
        } else {
          if (singleReportData?.filter?.hasOwnProperty("haventReplied")) {
            if (singleReportData?.filter?.haventReplied === 0) {
              setScheduleReply({ name: `${t("Replied")}`, value: 0 });
            } else {
              setScheduleReply({ name: t("Haven't_Replied"), value: 1 });
            }
          }
        }
        if (singleReportData?.filter?.filters?.hasOwnProperty("ratingValue")) {
          let arrr =
            singleReportData?.filter?.filters?.ratingValue?.$equals?.split(",");
          let arr = [];
          starRatings?.map((item) => {
            arrr?.map((itemTwo) => {
              if (item?.value === parseInt(itemTwo)) {
                arr.push(item);
              }
            });
          });
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            starRatings: arr.map((item) => item.id),
            rate: arr,
          }));
          setScheduleRatingValue(arr);
        } else {
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            starRatings: [],
            rate: [],
          }));
        }

        if (singleReportData?.filter?.filters?.hasOwnProperty("reviewTags")) {
          let arrr =
            singleReportData?.filter?.filters?.reviewTags?.tagId?.$equals?.split(
              ","
            );
          let arr = [];
          allTags?.results?.map((item) => {
            arrr?.map((itemTwo) => {
              if (item?.id === parseInt(itemTwo)) {
                arr.push(item);
              }
            });
          });
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            tags: arr.map((item) => item.id),
          }));
          setScheduleTags(arr);
        } else {
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            tags: [],
          }));
        }
        if (
          singleReportData?.filter?.filters?.hasOwnProperty(
            "thirdPartyReviewSourcesId"
          )
        ) {
          let arrr =
            singleReportData?.filter?.filters?.thirdPartyReviewSourcesId?.$equals?.split(
              ","
            );
          let arr = [];
          allReviewSource.results?.map((item) => {
            arrr?.map((itemTwo) => {
              if (item?.id === parseInt(itemTwo)) {
                arr.push(item);
              }
            });
          });
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            sources: arr.map((item) => item.id),
            reviewSource: arr,
          }));
          setScheduleReviewSource(arr);
        } else {
          setModalFilters((prevFilters) => ({
            ...prevFilters,
            sources: [],
            reviewSource: [],
          }));
        }
        if (singleReportData?.filter?.dateRange !== "Today") {
          let str =
            singleReportData?.filter?.filters?.date?.$between?.split(",");
          if (str?.length > 0) {
            handleChangeRangeStartDateSchedule(str[0]);
            handleChangeRangeEndDateSchedule(str[1]);
          }
        } else {
          handleChangeRangeStartDateSchedule(
            singleReportData?.filter?.date?.$gte
          );
        }
      } else {
        let arrFeatures = [];
        setFeaturesObj(singleReportData?.includedSections);
        featuresArray?.map((item) => {
          Object.keys(singleReportData?.includedSections)?.map((itemTwo) => {
            if (
              item?.key === itemTwo &&
              singleReportData?.includedSections[itemTwo] === true
            ) {
              arrFeatures?.push(item);
              if (arrFeatures?.length === featuresArray) {
                setSelectAllFeatures(true);
              } else {
                setSelectAllFeatures(false);
              }
            }
          });
        });

        if (singleReportData?.ReportScheduleLocations?.length > 0) {
          const uniqueLocations = [];
          const uniqueIds = new Set();

          for (const postLocation of singleReportData?.ReportScheduleLocations) {
            const locationId = postLocation?.Location?.id;

            if (!uniqueIds.has(locationId)) {
              uniqueIds.add(locationId);
              uniqueLocations.push(postLocation);
            }
          }
          setReportScheduleLocations(uniqueLocations);
        } else {
          setReportScheduleLocations([]);
        }
        if (arrFeatures?.length === featuresArray?.length) {
          setSectionOption({
            id: 1,
            value: "completeReport",
            title: t("complete_ai_report"),
          });
          setSectionFormat("completeReport");
        } else {
          setSectionOption({
            id: 2,
            value: "specificReport",
            title: t("specific_section"),
          });
          setSectionFormat("specificReport");
        }
        if (singleReportData?.filter?.hasOwnProperty("languageCode")) {
          const userLangReportForData = languagesData?.find(
            (item) => item?.value === singleReportData?.filter?.languageCode
          );

          if (userLangReportForData !== undefined) {
            setScheduleLanguage(userLangReportForData);
          } else {
            setScheduleLanguage("");
          }
        } else {
          setScheduleLanguage("");
        }
        setSelectedFeatures(arrFeatures);
        setReviewsChecked(singleReportData?.includeReviews);
        setReportFormat(singleReportData?.fileType);
        setStartDateRangeSchedule(singleReportData?.filter?.startDate);
        setEndDateRangeSchedule(singleReportData?.filter?.endDate);
        setNextStartDateSchedule(singleReportData?.filter?.nextStartDate);
        setNextEndDateSchedule(singleReportData?.filter?.nextEndDate);
        if (singleReportData?.filter?.locationIds?.length > 0) {
          let locationArr = [];
          singleReportData?.filter?.locationIds?.map((item) => {
            allLocations?.map((itemTwo) => {
              if (item === itemTwo?.id) {
                locationArr?.push(itemTwo);
              }
            });
          });

          setScheduleLocations(locationArr);
        } else {
          setScheduleLocations([]);
        }

        if (singleReportData?.filter?.groupIds?.length > 0) {
          let groupsArr = [];
          singleReportData?.filter?.groupIds?.map((item) => {
            allGroups?.rows?.map((itemTwo) => {
              if (item === itemTwo?.id) {
                groupsArr?.push(itemTwo);
              }
            });
          });
          setScheduleGroups(groupsArr);
        } else {
          setScheduleGroups([]);
        }

        if (singleReportData?.filter?.hasOwnProperty("sentimentAnalysis")) {
          if (singleReportData?.filter?.sentimentAnalysis === "Neutral") {
            setScheduleSentiments({
              label: `${t("neutral")}`,
              value: "Neutral",
            });
          } else if (
            singleReportData?.filter?.sentimentAnalysis === "Negative"
          ) {
            setScheduleSentiments({
              label: `${t("negative")}`,
              value: "Negative",
            });
          } else {
            setScheduleSentiments({
              label: `${t("positive")}`,
              value: "Positive",
            });
          }
        } else {
          setScheduleSentiments(null);
        }
      }
      if (singleReportData?.noReviewsNotification) {
        setYesChecked(true);
        setNoChecked(false);
      } else {
        setYesChecked(false);
        setNoChecked(true);
      }
      setEmailSubject(singleReportData?.emailSubject);
      setEmailBody(singleReportData?.emailBody);
      setRepeatValue(singleReportData?.repeatType);
      setEmailTitle(singleReportData?.title);
      setStartDateSchedule(singleReportData?.startDate);
      setEndDateSchedule(singleReportData?.endDate);
      if (
        singleReportData?.customLogo !== null &&
        singleReportData?.customLogo !== ""
      ) {
        let tempImage = {
          name: singleReportData?.customLogo,
        };
        setSelectedFileForImage(tempImage);
        setUploadedImages([singleReportData?.customLogo]);
      } else {
        setSelectedFileForImage(null);
        setUploadedImages([]);
      }
      if (singleReportData?.fileType === "both") {
        setPdfChecked(true);
        setXlsChecked(true);
      } else if (singleReportData?.fileType === "pdf") {
        setPdfChecked(true);
        setXlsChecked(false);
      } else {
        setXlsChecked(true);
        setPdfChecked(false);
      }
      const userLangReport = languagesData?.find(
        (item) => item?.language === singleReportData?.language
      );
      if (userLangReport !== undefined) {
        setLanguageSchedular(userLangReport);
      } else {
        setLanguageSchedular(userLang);
      }
      const selectedEmails = JSON.parse(singleReportData?.emails);

      if (selectedEmails?.length > 0) {
        let emailsArr = [];
        let foundMatch = false; // Flag to track if a match is found for the current item in selectedEmails

        selectedEmails?.forEach((item) => {
          let matchFound = false; // Flag to check if a match is found for the current item in users array

          users?.forEach((itemTwo) => {
            if (item === itemTwo?.User?.email) {
              selectedCompanyUsers?.push(itemTwo);
              foundMatch = true; // Update flag as a match is found for this item in selectedEmails
              matchFound = true; // Update flag as a match is found for this item in users array
            }
          });

          if (!matchFound) {
            emailsArr?.push(item);
            /*    handleAddNewUserEmailsChange(item);
            setOthers(true); */
          }
        });
        if (emailsArr?.length > 0) {
          const commaSeparatedString = emailsArr.join(",\n");
          handleAddNewUserEmailsChange(commaSeparatedString);
          setOthers(true);
        }
      }
    }
  }, [singleReportData]);

  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
    //   setXlsChecked(false);
    if (e.target.checked) {
      setReportFormat("pdf");
    } else {
      setReportFormat("");
    }
  };

  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);
    //  setPdfChecked(false);
    if (e.target.checked) {
      setReportFormat("xlsx");
    } else {
      setReportFormat("");
    }
  };

  const handleSubmit = (data) => {
    let value = {
      target: {
        value: data.timePeriod,
      },
    };
    handleChangeDateRange(data.timePeriod, true);
    setModalFilters(data);
  };
  const handleChangeYesChecked = (e) => {
    setYesChecked(e.target.checked);
    //   setXlsChecked(false);
    if (e.target.checked) {
      setNoChecked(false);
    } else {
      setNoChecked(true);
    }
  };

  const handleChangeNoChecked = (e) => {
    setNoChecked(e.target.checked);
    //  setPdfChecked(false);
    if (e.target.checked) {
      setYesChecked(false);
    } else {
      setYesChecked(true);
    }
  };

  const handleChangeCompanyUser = (e) => {
    setCompanyUser(e.target.checked);
    // setOthers(false);
    if (e.target.checked === false) {
      setSelectedCompanyUsers([]);
    }
  };
  const handleChangeOthers = (e) => {
    setOthers(e.target.checked);
    if (e.target.checked === false) {
      handleResetOthers();
    }
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyUserRoles?companyId=${companyData?.id}&pageNumber=1&limit=500`
      );
      if (res.status === 200) {
        const activeUsers = res?.data?.data?.rows?.filter(
          (user) =>
            user?.User?.inActive === false &&
            (user?.User?.status === "active" ||
              user?.User?.status === "pending")
        );

        setUsers(activeUsers);
        setIsLoading(false);
      }
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailTitle(value);
    setTitleError(value.trim() === "");
    setDisplayReportError(false);
  };

  function monthDiff(dateFrom, dateTo) {
    // Convert dayjs objects to native Date objects
    const fromDate = dateFrom instanceof dayjs ? dateFrom.toDate() : dateFrom;
    const toDate = dateTo instanceof dayjs ? dateTo.toDate() : dateTo;

    return (
      toDate.getMonth() -
      fromDate.getMonth() +
      12 * (toDate.getFullYear() - fromDate.getFullYear())
    );
  }

  const handleDateRangChange = (e, fromModel, startDateValue, endDateValue) => {
    const value = fromModel ? e : e.target.value;
    setDateRange(value);
    setDateRangeError(value === "");
    const today = new Date();
    if (startDateSchedule !== null && startDateSchedule !== "") {
      let date = dayjs(startDateSchedule);
      let oneDaysBefore = date.subtract(1, "day").endOf("day");
      let oneDaysBeforeDateEnd = oneDaysBefore.toDate();
      if (value === "Yesterday") {
        let date = dayjs(startDateSchedule);
        let oneDaysBefore = date.subtract(1, "day");
        let oneDaysBeforeDate = oneDaysBefore.startOf("day").toDate();
        let oneDaysBeforeDateEnd = oneDaysBefore.endOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(oneDaysBeforeDate);
        setNextEndDateSchedule(oneDaysBeforeDate);
        let newNextStartDate = oneDaysBefore.subtract(1, "day");

        let newNextStartDateDate = newNextStartDate.toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "Today") {
        let date = dayjs(startDateSchedule);
        let oneDaysBefore = date.startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();
        setEndDateRangeSchedule(startDateSchedule);
        setStartDateRangeSchedule(oneDaysBeforeDate);

        let newNextStartDate = oneDaysBefore.subtract(1, "day");

        let newNextStartDateDate = newNextStartDate.toDate();
        setNextEndDateSchedule(newNextStartDate.endOf("day").toDate());
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneDaysBeforeDate
          ).format("MMM DD, YYYY")}`
        );
      } else if (value === "2days") {
        let date = dayjs(startDateSchedule);
        let twoDaysBefore = date.subtract(2, "day");
        let twoDaysBeforeDate = twoDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(twoDaysBeforeDate);
        let newEndDate = twoDaysBefore.subtract(1, "day").endOf("day");
        setNextEndDateSchedule(newEndDate);
        let newNextStartDate = twoDaysBefore.subtract(2, "day");

        let newNextStartDateDate = newNextStartDate.toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            twoDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "7days") {
        let date = dayjs(startDateSchedule);
        let sevenDaysBefore = date.subtract(7, "day");
        let sevenDaysBeforeDate = sevenDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(sevenDaysBeforeDate);
        let newNextEndDate = sevenDaysBefore
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setNextEndDateSchedule(newNextEndDate);
        let newNextStartDate = sevenDaysBefore.subtract(7, "day");
        let newNextStartDateDate = newNextStartDate.startOf("day").toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        //  setNextStartDateSchedule(newNextStartDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sevenDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "week") {
        let date = dayjs(startDateSchedule);

        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate.subtract(
          weekBeforeSelectedDate.day() - 1,
          "day"
        );
        let followingSunday = previousMonday.add(6, "day");
        setEndDateRangeSchedule(followingSunday.endOf("day").toDate());
        setStartDateRangeSchedule(previousMonday.startOf("day").toDate());
        let newEndDate = previousMonday
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setNextEndDateSchedule(newEndDate);
        let sevenDaysBefore = previousMonday.subtract(7, "day");
        setNextStartDateSchedule(sevenDaysBefore.startOf("day").toDate());
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            previousMonday.toDate()
          ).format("MMM DD, YYYY")} to ${dayjs(followingSunday.toDate()).format(
            "MMM DD, YYYY"
          )}`
        );
      } else if (value === "month") {
        let date = dayjs(startDateSchedule);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
        setStartDateRangeSchedule(startOfMonthBefore.toDate());
        setNextEndDateSchedule(
          startOfMonthBefore.subtract(1, "day").endOf("day").toDate()
        );
        let oneMoreMonthBefore = startOfMonthBefore.subtract(1, "month");
        setNextStartDateSchedule(oneMoreMonthBefore?.startOf("day").toDate());
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            startOfMonthBefore.toDate()
          ).format("MMM DD, YYYY")} to ${dayjs(
            endOfMonthBefore.toDate()
          ).format("MMM DD, YYYY")}`
        );
      } else if (value === "14days") {
        let date = dayjs(startDateSchedule);
        let fourteenDaysBefore = date.subtract(14, "day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.startOf("day").toDate();
        let newNextEndDate = fourteenDaysBefore
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(fourteenDaysBeforeDate);
        setNextEndDateSchedule(newNextEndDate);
        let newNextStartDate = fourteenDaysBefore.subtract(14, "day");
        let newNextStartDateDate = newNextStartDate.startOf("day").toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            fourteenDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "30days") {
        let date = dayjs(startDateSchedule);
        let oneMonthBefore = date.subtract(30, "day");
        let oneMonthBeforeDate = oneMonthBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(oneMonthBeforeDate);
        let newNextEndDate = oneMonthBefore
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setNextEndDateSchedule(newNextEndDate);
        let newNextStartDate = oneMonthBefore.subtract(30, "day");
        let newNextStartDateDate = newNextStartDate.startOf("day").toDate();
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneMonthBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "60days") {
        let date = dayjs(startDateSchedule);

        let sixtyDaysBefore = date.subtract(60, "day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.startOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(sixtyDaysBeforeDate);

        let newNextStartDate = sixtyDaysBefore.subtract(60, "day");
        let newNextStartDateDate = newNextStartDate.startOf("day").toDate();
        let newNextEndDate = sixtyDaysBefore
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setNextEndDateSchedule(newNextEndDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sixtyDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "90days") {
        let date = dayjs(startDateSchedule);

        let ninetyDaysBefore = date.subtract(90, "day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.startOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(ninetyDaysBeforeDate);

        let newNextStartDate = ninetyDaysBefore.subtract(90, "day");
        let newNextStartDateDate = newNextStartDate.toDate();
        let newNextEndDate = ninetyDaysBefore
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setNextEndDateSchedule(newNextEndDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            ninetyDaysBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "6months") {
        let date = dayjs(startDateSchedule);

        let sixMonthsBefore = date.subtract(6, "month").startOf("month");
        let sixMonthsBeforeDate = sixMonthsBefore.toDate();

        setStartDateRangeSchedule(sixMonthsBeforeDate);

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore?.toDate());

        let newNextStartDate = sixMonthsBefore.subtract(6, "month");
        let newNextStartDateDate = newNextStartDate.startOf("month").toDate();
        let newNextEndDate = sixMonthsBefore
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setNextEndDateSchedule(newNextEndDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            sixMonthsBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "12months") {
        let date = dayjs(startDateSchedule);

        let oneYearBefore = date.subtract(12, "month").startOf("month");
        let oneYearBeforeDate = oneYearBefore.toDate();
        setStartDateRangeSchedule(oneYearBeforeDate);

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore?.toDate());

        let newNextStartDate = oneYearBefore.subtract(12, "month");
        let newNextStartDateDate = newNextStartDate.startOf("month").toDate();
        let newNextEndDate = oneYearBefore
          .subtract(1, "day")
          .endOf("day")
          .toDate();
        setNextEndDateSchedule(newNextEndDate);
        setNextStartDateSchedule(newNextStartDateDate);
        setEmailBody(
          `Dear customer Please find attached emotion AI report for the ${dayjs(
            oneYearBeforeDate
          ).format("MMM DD, YYYY")} to ${dayjs(date).format("MMM DD, YYYY")}`
        );
      } else if (value === "custom") {
        if (fromModel === false) {
          setEndDateRangeSchedule(endDate);
          setNextEndDateSchedule(nextEndDate);
          setNextStartDateSchedule(nextStartDate);

          setStartDateRangeSchedule(startDate);
          setEmailBody(
            `Dear customer Please find attached emotion AI report for the ${dayjs(
              startDate
            ).format("MMM DD, YYYY")} to ${dayjs(endDate).format(
              "MMM DD, YYYY"
            )}`
          );
        } else {
          setEndDateRangeSchedule(endDateValue);

          setStartDateRangeSchedule(startDateValue);
          setEmailBody(
            `Dear customer Please find attached emotion AI report for the ${dayjs(
              startDateValue
            ).format("MMM DD, YYYY")} to ${dayjs(endDateValue).format(
              "MMM DD, YYYY"
            )}`
          );

          let startDateOnee = dayjs(startDateValue);
          const startDateOne = startDateOnee.subtract(1, "days");
          const endDateOne = dayjs(endDateValue);
          const diffInMonths = monthDiff(startDateOnee, endDateOne);
          const diffInDays = endDateOne.diff(startDateOnee, "days");
          if (diffInMonths < 12 || diffInDays > 0) {
            if (diffInDays > 0) {
              const newStartDate = startDateOne.subtract(diffInDays, "days");

              setNextStartDateSchedule(newStartDate.toDate());
              setNextEndDateSchedule(startDateOne.endOf("day").toDate());
            } else {
              const newStartDate = startDateOne.subtract(
                diffInMonths,
                "months"
              );

              setNextStartDateSchedule(newStartDate.toDate());
              setNextEndDateSchedule(startDateOnee.toDate());
            }
          } else {
            const yearsToSubtract = Math.floor(diffInMonths / 12);
            const remainingMonths = diffInMonths % 12;

            const newStartDate = startDateOnee
              .subtract(yearsToSubtract, "year")
              .subtract(remainingMonths, "months");
            setNextStartDateSchedule(newStartDate.toDate());
            setNextEndDateSchedule(startDateOnee.toDate());
          }
        }
      }
    }
  };

  /*  const handleChangeStartDateSchedule = (selectedDate) => {
    setStartDateSchedule(selectedDate);

    setStartDateError(selectedDate === null || selectedDate === "");
  }; */

  const handleEmailSubjectChange = (e) => {
    const value = e.target.value;
    setEmailSubject(value);
    setEmailSubjectRequired(value.trim() === "");
  };
  const handleOpenFilterModel = () => {
    setOpenFilterModel(true);
  };
  const handleCloseFilterModel = (resetValue) => {
    setOpenFilterModel(false);
    if (resetValue !== true) {
      setSelectAllFiltersLocations(selectAllLocations);
      setSelectAllFiltersGroups(selectAllGroups);
    }
  };
  const handleOpenReviewsFilterModel = () => {
    setOpenReviewsFilterModel(true);
  };
  const handleCloseReviewsFilterModel = () => {
    setOpenReviewsFilterModel(false);
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveImageFile = () => {
    setSelectedFileForImage(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "850px",
              height: "550px",
            },
          }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle className={classes.emailReportTitle}>
            {title}

            {/*   {currentPath==="/user/reviews" && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                }}
                style={{ color: "#495059" }}
              >
                {t("reviews_report_subTitle")}
              </Typography>
            )} */}
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid item xs={12} md={12} sm={12} lg={12}>
                {/* <CommonButton label={"Download Pdf"} onSubmit={handleDownloadPdf} /> */}
                {/***********************1st section */}
                {currentPath !== "/user/reviews" &&
                  locations?.length === 0 &&
                  scheduleLocations?.length === 0 &&
                  scheduleGroups?.length === 0 &&
                  selectedGroups?.length === 0 && (
                    <Alert mt={2} mb={3} severity="error">
                      {t("Please select a location or group to proceede")}
                    </Alert>
                  )}
                <Box sx={{ marginTop: "12px" }}>
                  {" "}
                  <Collapsible
                    title={t("configure_report")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureReport")}
                    id={"configureReport"}
                    ComponentToRender={
                      <Grid container>
                        {/*  <Typography className={classes.emailReportLabel} mt={1}>
                        {t("configure_report")}
                      </Typography> */}
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_format")}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Grid container sx={{ marginLeft: "-10px" }}>
                                  <Grid item xs={3}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={handleChangePdfChecked}
                                        checked={pdfChecked}
                                      />
                                      <Typography>{"PDF"}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={handleChangeXlsChecked}
                                        checked={xlsChecked}
                                      />
                                      <Typography>{"XLS"}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}></Grid>

                                  {currentPath !== "/user/reviews" && (
                                    <Grid item xs={8}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          onChange={handleReviewsChecked}
                                          checked={reviewsChecked}
                                        />
                                        <Typography
                                          className={
                                            classes.scheduleReviewTypoEmail
                                          }
                                        >
                                          {t("add_reviews")}
                                        </Typography>
                                      </Box>

                                      {reviewsChecked && (
                                        <Box
                                          className={classes.reviewsubHeadBox}
                                          sx={{
                                            marginLeft: "12px",
                                            marginBottom: "8px",
                                          }}
                                        >
                                          <Grid container>
                                            <Grid
                                              item
                                              xs={1}
                                              sm={1}
                                              md={0.8}
                                              lg={0.8}
                                            >
                                              <InfoOutlinedIcon
                                                className={
                                                  classes.scheduleReviewsIcon
                                                }
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={11}
                                              sm={11}
                                              md={11.2}
                                              lg={11.2}
                                              className={
                                                classes.scheduleReviewInfoGrid
                                              }
                                            >
                                              <Typography
                                                className={
                                                  classes.scheduleReviewInfoTypoEmail
                                                }
                                              >
                                                {t("add_reviews_info")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            {currentPath === "/user/reviews" && pdfChecked && (
                              <Grid item xs={12} lg={8}>
                                <Box
                                  className={classes.reviewsubHeadBox}
                                  sx={{ marginBottom: "8px" }}
                                >
                                  <Grid container>
                                    <Grid item xs={1} sm={1} md={0.8} lg={0.8}>
                                      <InfoOutlinedIcon
                                        className={classes.scheduleReviewsIcon}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={11}
                                      sm={11}
                                      md={11.2}
                                      lg={11.2}
                                      className={classes.scheduleReviewInfoGrid}
                                    >
                                      <Typography
                                        className={
                                          classes.scheduleReviewInfoTypoEmail
                                        }
                                      >
                                        {t("add_reviews_info")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            )}

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography className={classes.emailReportLabel}>
                                {t("upload_logo")}
                              </Typography>

                              <Box mt={3} ml={-3}>
                                <CommonButton
                                  onSubmit={handleOpenLogoUploadModal}
                                  label={t("Select_logo")}
                                />
                              </Box>
                              <Box>
                                {selectedFileForImage !== null ? (
                                  <Box className={classes.fileBoxStyle}>
                                    <Typography
                                      // onClick={handleReopenModal}
                                      className={classes.selectedFileTypo}
                                    >
                                      {selectedFileForImage?.name}
                                    </Typography>
                                    <IconButton
                                      className={classes.removeFileButton}
                                      onClick={() => handleRemoveImageFile()}
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        className={classes.deleteIcon}
                                      />
                                    </IconButton>
                                  </Box>
                                ) : (
                                  <p>{t("")}</p>
                                )}
                              </Box>
                            </Grid>

                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_name")}
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              placeholder={t("enter_title")}
                              value={emailTitle}
                              fullWidth
                              onChange={handleInputChange}
                              error={titleError || displayReportError}
                              helperText={
                                titleError
                                  ? t("Field_req")
                                  : displayReportError
                                  ? t("report_name_exists")
                                  : ""
                              }
                            />
                          </Grid>
                          {currentPath !== "/user/reviews" && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("manage_sections")}
                              </Typography>
                              <Autocomplete
                                id="tags-outlined"
                                options={manageSectionOptions}
                                fullWidth
                                value={sectionOption}
                                getOptionLabel={(option) => option?.title}
                                filterSelectedOptions
                                onChange={handleChangeManageSections}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_section")}
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          {currentPath !== "/user/reviews" &&
                            sectionFormat === "specificReport" && (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("select_features")}
                                </Typography>
                                <Autocomplete
                                  multiple
                                  disableCloseOnSelect
                                  id="tags-outlined"
                                  options={featuresArray}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => {
                                    if (
                                      value.find((option) => option.id === 0)
                                    ) {
                                      // Check if all options are selected
                                      if (
                                        featuresArray?.length ===
                                        selectedFeatures?.length
                                      ) {
                                        setSelectedFeatures([]);
                                        setSelectAllFeatures(false);
                                      } else {
                                        setSelectAllFeatures(true);
                                        setSelectedFeatures(featuresArray);
                                        //  setLocationRequired(false);
                                      }
                                      handleChangeFeature(featuresArray);
                                    } else {
                                      setSelectedFeatures(value);
                                      handleChangeFeature(value);
                                      if (
                                        value?.length === featuresArray?.length
                                      ) {
                                        setSelectAllFeatures(true);
                                      } else {
                                        setSelectAllFeatures(false);
                                      }
                                    }
                                  }}
                                  value={selectedFeatures}
                                  sx={{ minWidth: 300 }}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => {
                                    if (option.id === 0) {
                                      return (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selectAllFeatures}
                                          />
                                          {option?.name}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            // checked={selected}
                                            checked={
                                              selected || selectAllFeatures
                                            }
                                          />
                                          {option?.name}
                                          {/* {option?.internalName + ", " + option?.city} */}
                                        </li>
                                      );
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (featuresArray?.length > 0) {
                                      return [
                                        { id: 0, name: t("Select_All") },
                                        ...filtered,
                                      ];
                                    } else {
                                      return [];
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={t("select_features_tooltip")}
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("date_range")}
                            </Typography>

                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={dateRange}
                              onChange={(e) => {
                                if (currentPath === "/user/reviews") {
                                  handleChangeDateRange(e, false);
                                } else {
                                  handleDateRangChange(e, false);
                                }
                              }}
                              fullWidth
                              error={dateRangeError}
                              helperText={dateRangeError ? t("Field_req") : ""}
                              //sx={{ marginTop: "3px" }}
                            >
                              {currentPath === "/user/reviews" && (
                                <MenuItem value="All">{t("All")}</MenuItem>
                              )}

                              <MenuItem value="Today">{t("today")}</MenuItem>
                              <MenuItem value="Yesterday">
                                {t("yesterday")}
                              </MenuItem>
                              <MenuItem value="2days">{t("2days")}</MenuItem>
                              <MenuItem value="7days">{t("7days")}</MenuItem>
                              <MenuItem value="week">{t("week")}</MenuItem>

                              <MenuItem value="14days">{t("14days")}</MenuItem>
                              <MenuItem value="30days">{t("30days")}</MenuItem>
                              <MenuItem value="month">
                                {t("monthValue")}
                              </MenuItem>
                              <MenuItem value="60days">{t("60days")}</MenuItem>
                              <MenuItem value="90days">{t("90days")}</MenuItem>
                              <MenuItem value="6months">
                                {t("6months")}
                              </MenuItem>
                              <MenuItem value="12months">
                                {t("12months")}
                              </MenuItem>
                              <MenuItem value="custom">
                                {t("custom_text")}
                              </MenuItem>
                            </Select>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_language")}
                            </Typography>
                            <Select
                              required
                              // defaultValue={"en"}
                              style={{ width: "100%" }}
                              key={languageSchedular?.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={languageSchedular?.value}
                              // label="Select"
                              onChange={(e, value) => {
                                const userLang = languagesData?.find(
                                  (item) => item?.value === e.target.value
                                );
                                handleChangeLanguageForScheduler(userLang);
                              }}
                            >
                              {languagesData.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.language}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            md={2}
                            lg={2}
                            sx={{ paddingTop: "0px", textAlign: "end" }}
                          >
                            <Box sx={{ marginTop: "25px" }}>
                              {currentPath === "/user/reviews" ? (
                                <CommonButton
                                  icon={
                                    <FilterAltIcon sx={{ color: "#FFFF" }} />
                                  }
                                  onSubmit={handleOpenReviewsFilterModel}
                                  label={t("Filters")}
                                />
                              ) : (
                                <CommonButton
                                  icon={
                                    <FilterAltIcon sx={{ color: "#FFFF" }} />
                                  }
                                  onSubmit={handleOpenFilterModel}
                                  label={t("Filters")}
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>

                        {/* {dateRange === "custom" &&
                          currentPath !== "/user/reviews" && (
                            <Grid
                              container
                              spacing={2}
                              sx={{ marginTop: "8px" }}
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Fromm")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    value={
                                      startDateRangeSchedule !== null
                                        ? dayjs(startDateRangeSchedule)
                                        : null
                                    }
                                    format="DD/MM/YYYY"
                                    disableFuture
                                    // closeOnSelect={false}
                                    onChange={(e) => {
                                      const newDate = e
                                        ? dayjs(e).startOf("day").toDate()
                                        : null;
                                      handleChangeRangeStartDateSchedule(
                                        newDate
                                      );

                                      //  setStartDateError(e === null || e === "");
                                    }}
                                    className={classes.whiteBackground}
                                    //  format="DD/MM/YYYY hh:mm A" // Display only hours without minutes

                                    minDate={dayjs("2005-01-01")}
                                    maxDate={dayjs().endOf("day")}
                                    renderInput={(params) => (
                                      <TextField
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                        error={startDateError}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Too")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    disableFuture
                                    value={
                                      endDateRangeSchedule !== null
                                        ? dayjs(endDateRangeSchedule)
                                        : null
                                    }
                                    format="DD/MM/YYYY"
                                    onChange={(e) => {
                                      handleChangeRangeEndDateSchedule(e);
                                    }}
                                    className={classes.whiteBackground}
                                    minDate={dayjs("2005-01-01")}
                                    // maxDate={dayjs().endOf("day")}
                                    renderInput={(params) => (
                                      <TextField
                                        error
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                      />
                                    )}
                                  />
                                  <style>
                                    {`
       .MuiDialogActions-root {
        display: none;
      }
     
    `}
                                  </style>
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          )} */}

                        {dateRange === "custom" &&
                          currentPath !== "/user/reviews" && (
                            <DatePickerComponent
                              handleChangeStartDate={
                                handleChangeRangeStartDateSchedule
                              }
                              handleChangeEndDate={
                                handleChangeRangeEndDateSchedule
                              }
                              handleChangeDateRangesfromRangeModel={
                                handleChangeDateRangesfromRangeModel
                              }
                              startDate={
                                startDateRangeSchedule !== null
                                  ? startDateRangeSchedule
                                  : null
                              }
                              endDate={
                                endDateRangeSchedule !== null
                                  ? endDateRangeSchedule
                                  : null
                              }
                              dateRange={dateRange}
                              setDateRange={setDateRange}
                              fromFilters={true}
                              rangeValue={"between"}
                              removePadding={true}
                            />
                          )}
                        {dateRange === "custom" &&
                          currentPath === "/user/reviews" && (
                            <DatePickerComponent
                              handleChangeStartDate={
                                handleChangeRangeStartDateSchedule
                              }
                              handleChangeEndDate={
                                handleChangeRangeEndDateSchedule
                              }
                              startDate={modalFiltersDate.startDate}
                              endDate={modalFiltersDate.endDate}
                              dateRange={dateRange}
                              setDateRange={setDateRange}
                              fromFilters={true}
                              // rangeValue={"between"}
                              fromSchedule={true}
                            />

                            /* <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Fromm")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    disableFuture
                                    value={
                                      startDate !== null
                                        ? dayjs(startDate)
                                        : null
                                    }
                                    format="DD/MM/YYYY"
                                    closeOnSelect={false}
                                    onChange={(e) => {
                                      handleChangeRangeStartDateSchedule(e);
                                    }}
                                    className={classes.whiteBackground}
                                    minDate={dayjs("2005-01-01")}
                                    renderInput={(params) => (
                                      <TextField
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                        error={startDateError}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Too")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    // disablePast
                                    disableFuture
                                    value={
                                      endDate !== null ? dayjs(endDate) : null
                                    }
                                    format="DD/MM/YYYY"
                                    onChange={(e) => {
                                      handleChangeRangeEndDateSchedule(
                                        e.endOf("day")
                                      );
                                    }}
                                    className={classes.whiteBackground}
                                    minDate={dayjs("2005-01-01")}
                                    // maxDate={dayjs().endOf("day")}
                                    renderInput={(params) => (
                                      <TextField
                                        error
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                      />
                                    )}
                                  />
                                  <style>
                                    {`
       .MuiDialogActions-root {
        display: none;
      }
     
    `}
                                  </style>
                                </LocalizationProvider>
                                <Box sx={{ color: "red" }}>
                                  {greaterError ? t("invalid_date") : ""}
                                </Box>
                              </Grid> */
                          )}
                      </Grid>
                    }
                  />
                </Box>

                {/***********************2rd section */}
                <Box sx={{ marginTop: "12px" }}>
                  {" "}
                  <Collapsible
                    title={t("Schedule_email")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("scheduleEmail")}
                    id={"scheduleEmail"}
                    ComponentToRender={
                      <Grid container>
                        {/*   <Typography className={classes.emailReportLabel} mt={1}>
                          {t("Schedule_email")}
                        </Typography> */}

                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("send_email")}
                              </Typography>

                              <BootstrapTooltip
                                title={t("repeate_type_tooltip")}
                              >
                                <Typography
                                  sx={{
                                    marginLeft: "5px",
                                    marginTop: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <InfoOutlinedIcon />
                                </Typography>
                              </BootstrapTooltip>
                            </Box>

                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={repeatValue}
                              onChange={(e) => {
                                setRepeatValue(e.target.value);
                              }}
                              fullWidth
                            >
                              <MenuItem value="no">{t("only_once")}</MenuItem>
                              <MenuItem value="daily">
                                {t("every_day")}
                              </MenuItem>
                              <MenuItem value="weekly">
                                {t("every_week")}
                              </MenuItem>
                              <MenuItem value="monthly">
                                {t("every_month")}
                              </MenuItem>
                              <MenuItem value="yearly">
                                {t("every_year")}
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Start_date")}
                              </Typography>

                              <BootstrapTooltip
                                title={t("start_schedule_date_tooltip")}
                              >
                                <Typography
                                  sx={{
                                    marginLeft: "5px",
                                    marginTop: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <InfoOutlinedIcon />
                                </Typography>
                              </BootstrapTooltip>
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                disablePast
                                value={
                                  startDateSchedule !== null
                                    ? dayjs(startDateSchedule)
                                    : null
                                }
                                className={classes.whiteBackground}
                                closeOnSelect={false}
                                onChange={(e) => {
                                  handleChangeStartDateSchedule(e);
                                  setStartDateError(e === null || e === "");
                                }}
                                onError={(error) => {
                                  setStartDateInvalid(error);
                                }}
                                format="DD/MM/YYYY hh:mm A" // Display only hours without minutes
                                minutesStep={60}
                                minDate={dayjs("2005-01-01")}
                                // maxDate={dayjs().endOf("day")}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    fullWidth
                                    {...params}
                                    sx={{ background: "#FFFF" }}
                                    error={startDateError}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <Box sx={{ color: "red" }}>
                              {startDateError
                                ? t("Field_req")
                                : startDateInvalid !== null
                                ? t("invalid_date")
                                : ""}
                            </Box>
                          </Grid>
                          {repeatValue !== "no" && (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              sx={{ paddingTop: "0px" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("End_Date")}
                                </Typography>

                                <BootstrapTooltip
                                  title={t("end_schedule_date_tooltip")}
                                >
                                  <Typography
                                    sx={{
                                      marginLeft: "5px",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <InfoOutlinedIcon />
                                  </Typography>
                                </BootstrapTooltip>
                              </Box>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disablePast
                                  value={
                                    endDateSchedule !== null
                                      ? dayjs(endDateSchedule)
                                      : null
                                  }
                                  format="DD/MM/YYYY"
                                  className={classes.whiteBackground}
                                  onChange={(e) => {
                                    handleChangeEndDateSchedule(e);
                                    if (
                                      startDateSchedule &&
                                      e &&
                                      dayjs(startDateSchedule).isAfter(e)
                                    ) {
                                      setGreaterError(true);
                                    } else {
                                      setGreaterError(false);
                                    }
                                  }}
                                  minDate={dayjs("2005-01-01")}
                                  // maxDate={dayjs().endOf("day")}
                                  renderInput={(params) => (
                                    <TextField
                                      error
                                      autoComplete="off"
                                      fullWidth
                                      {...params}
                                      sx={{ background: "#FFFF" }}
                                    />
                                  )}
                                />
                                <style>
                                  {`
       .MuiDialogActions-root {
        display: none;
      }
     
    `}
                                </style>
                              </LocalizationProvider>
                              <Box sx={{ color: "red" }}>
                                {greaterError ? t("invalid_date") : ""}
                              </Box>
                            </Grid>
                          )}
                          {/*  {repeatValue !== "no" && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px" }}
                            >
                              <DatePickerComponent
                                handleChangeStartDate={
                                  handleChangeStartDateSchedule
                                }
                                handleChangeEndDate={
                                  handleChangeEndDateSchedule
                                }
                                startDate={
                                  startDateSchedule !== null
                                    ? startDateSchedule
                                    : null
                                }
                                endDate={
                                  endDateSchedule !== null
                                    ? endDateSchedule
                                    : null
                                }
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                fromFilters={true}
                                rangeValue={"between"}
                                fromSchedule={true}
                                repeatValue={repeatValue}
                              />
                            </Grid>
                          )} */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "0px" }}
                          >
                            {" "}
                            <Typography className={classes.emailReportSubLabel}>
                              {t("no_reviewsEmail_text")}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Grid container sx={{ marginLeft: "-10px" }}>
                                  <Grid item xs={3}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={handleChangeYesChecked}
                                        checked={yesChecked}
                                      />
                                      <Typography>{t("Yes")}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={handleChangeNoChecked}
                                        checked={noChecked}
                                      />
                                      <Typography>{t("No_text")}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}></Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                </Box>

                <Box sx={{ marginTop: "12px" }}>
                  {" "}
                  <Collapsible
                    title={t("configure_emaill")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureEmail")}
                    id={"configureEmail"}
                    ComponentToRender={
                      <Grid container>
                        {/*    <Typography className={classes.emailReportLabel} mt={1}>
                          {t("configure_emaill")}
                        </Typography> */}
                        <Grid item xs={12} sx={{ paddingTop: "0px" }}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("select_user_type")}
                          </Typography>
                          {/* <Typography className={classes.emailReportSubLabel}>
                    {t("select_user_type")}
                  </Typography> */}
                          <Grid container sx={{ marginLeft: "-10px" }}>
                            <Grid
                              item
                              xs={3}
                              sx={{ paddingTop: "0px", marginTop: "3px" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeCompanyUser}
                                  checked={companyUser}
                                />
                                <Typography>{t("company_users")}</Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sx={{ paddingTop: "0px", marginTop: "3px" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeOthers}
                                  checked={others}
                                />
                                <Typography>{t("others")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ paddingTop: "0px", marginTop: "3px" }}
                        >
                          {companyUser && users?.length > 0 && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("recipients")}
                              </Typography>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="tags-outlined"
                                options={users?.length > 0 ? users : []}
                                getOptionLabel={(option) =>
                                  option?.User?.firstName !== null &&
                                  option?.User?.lastName !== null
                                    ? option?.User?.firstName
                                        .charAt(0)
                                        .toUpperCase() +
                                      option?.User?.firstName.slice(1) +
                                      " " +
                                      (option?.User?.lastName !== null &&
                                      option?.User?.lastName !== ""
                                        ? option?.User?.lastName
                                            .charAt(0)
                                            .toUpperCase() +
                                          option?.User?.lastName.slice(1) +
                                          " " +
                                          "(" +
                                          option?.User?.email +
                                          ")"
                                        : "")
                                    : option?.User?.email
                                }
                                onChange={(event, value) => {
                                  if (value.find((option) => option.id === 0)) {
                                    // Check if all options are selected
                                    if (
                                      users?.length ===
                                      selectedCompanyUsers?.length
                                    ) {
                                      setSelectedCompanyUsers([]);
                                      setSelectAll(false);
                                    } else {
                                      setSelectAll(true);
                                      setSelectedCompanyUsers(users);
                                      //  setLocationRequired(false);
                                    }
                                  } else {
                                    setSelectedCompanyUsers(value);
                                    if (value?.length === users?.length) {
                                      setSelectAll(true);
                                    } else {
                                      setSelectAll(false);
                                    }
                                  }
                                }}
                                value={selectedCompanyUsers}
                                sx={{ minWidth: 300 }}
                                renderOption={(props, option, { selected }) => {
                                  if (option.id === 0) {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selectAll}
                                        />
                                        {option?.firstName}
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          // checked={selected}
                                          checked={selected || selectAll}
                                        />
                                        {option?.User?.firstName !== null &&
                                        option?.User?.lastName !== null
                                          ? option?.User?.firstName
                                              .charAt(0)
                                              .toUpperCase() +
                                            option?.User?.firstName.slice(1) +
                                            " " +
                                            (option?.User?.lastName !== null &&
                                            option?.User?.lastName !== ""
                                              ? option?.User?.lastName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                option?.User?.lastName.slice(
                                                  1
                                                ) +
                                                " " +
                                                "(" +
                                                option?.User?.email +
                                                ")"
                                              : "")
                                          : option?.User?.email}
                                        {/* {option?.internalName + ", " + option?.city} */}
                                      </li>
                                    );
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (users?.length > 0) {
                                    return [
                                      { id: 0, firstName: t("Select_All") },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_user")}
                                  />
                                )}
                              />
                            </>
                          )}

                          {others && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Email_Address")}
                              </Typography>
                              <TextField
                                id="outlined-basic"
                                multiline
                                rows={5}
                                maxRows={10}
                                ref={textArea}
                                variant="outlined"
                                placeholder={t("Enter_recipients_email_here")}
                                sx={{ marginBottom: "12px" }}
                                value={addNewUserEmials}
                                fullWidth
                                // error={emailsError}
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddNewUserEmailsChange(
                                      event.target.value + ","
                                    );
                                  }
                                }}
                                onChange={(e) =>
                                  handleAddNewUserEmailsChange(e.target.value)
                                }
                              />
                              <Box className={classes.reviewsubHeadBox}>
                                {totalEmailsArr?.length > 5000 ? (
                                  <Typography
                                    className={classes.reviewsubHeadTextError}
                                  >
                                    {t("max_upload_text")}
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.reviewsubHeadText}
                                  >
                                    {t("send_email_text_emotionAi") +
                                      " " +
                                      totalEmailsArr?.length +
                                      " " +
                                      t("email_addresses")}
                                  </Typography>
                                )}
                              </Box>

                              <Box>
                                <input
                                  type="file"
                                  id="file-email"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={handleFileUpload}
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                />

                                <Box mt={3} ml={-3}>
                                  <CommonButton
                                    onSubmit={handleButtonClick}
                                    label={t("Browse_File")}
                                  />
                                </Box>

                                <Box>
                                  {selectedFile ? (
                                    <Box className={classes.fileBoxStyle}>
                                      <Typography
                                        onClick={handleReopenModal}
                                        className={classes.selectedFileTypo}
                                      >
                                        {selectedFile}
                                      </Typography>
                                      <IconButton
                                        className={classes.removeFileButton}
                                        onClick={() => handleRemoveFile()}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          className={classes.deleteIcon}
                                        />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <p>{t("")}</p>
                                  )}
                                </Box>
                              </Box>
                            </>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{ paddingTop: "0px", marginTop: "3px" }}
                        >
                          <Typography className={classes.emailReportSubLabel}>
                            {t("subjectss")}
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_subject")}
                            value={emailSubject}
                            fullWidth
                            error={emailSubjectRequired}
                            helperText={
                              emailSubjectRequired ? t("Field_req") : ""
                            }
                            onChange={handleEmailSubjectChange}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ paddingTop: "0px", marginTop: "3px" }}
                        >
                          <Typography className={classes.emailReportSubLabel}>
                            {t("body")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_body_text")}
                            value={emailBody}
                            fullWidth
                            // error={headingRequired}
                            onChange={(e) => {
                              setEmailBody(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </Box>

                {/*******************3rd section  */}
              </Grid>
            )}
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Box sx={{ marginRight: "10px" }}>
              <CommonButton
                displayWhite="true"
                label={t("Cancel")}
                onSubmit={handleCloseNewRequestModel}
              />
              <CommonButton
                type="submit"
                label={singleReportData !== null ? t("Update") : t("schedule")}
                isLoading={
                  singleReportData !== null
                    ? updateReportLoading
                    : scheduleReportLoading
                }
                disabled={
                  emailTitle === ""
                    ? true
                    : dateRangeError
                    ? true
                    : pdfChecked === false && xlsChecked === false
                    ? true
                    : // : languageSchedular===null || languageSchedular===""
                    // ? true
                    dateRange === "custom" &&
                      currentPath !== "/user/reviews" &&
                      (startDateRangeSchedule === null ||
                        endDateRangeSchedule === null ||
                        startDateRangeSchedule === "" ||
                        endDateRangeSchedule === "")
                    ? true
                    : dateRange === "custom" &&
                      currentPath === "/user/reviews" &&
                      (startDate === null ||
                        endDate === null ||
                        startDate === "" ||
                        endDate === "")
                    ? true
                    : currentPath !== "user/reviews" &&
                      locations?.length === 0 &&
                      scheduleLocations?.length === 0 &&
                      scheduleGroups?.length === 0 &&
                      selectedGroups?.length === 0
                    ? true
                    : currentPath !== "user/reviews" &&
                      sectionFormat === "specificReport" &&
                      selectedFeatures?.length === 0
                    ? true
                    : startDateSchedule === null
                    ? true
                    : startDateInvalid !== null
                    ? true
                    : emailSubject === ""
                    ? true
                    : totalEmailsArr.length === 0 &&
                      selectedCompanyUsers.length === 0
                    ? true
                    : updateReportLoading && scheduleReportLoading
                    ? true
                    : greaterError
                    ? true
                    : false
                }
                onSubmit={
                  singleReportData !== null
                    ? handleUpdateScheduledReport
                    : handleScheduleReport
                }
              />
            </Box>
          </Box>
        </Dialog>
      </div>
      {/*  {openModalFilter && (
        <EmotionAiFilterModal
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          allLocations={allLocations}
          startDateRangeSchedule={startDateRangeSchedule}
          allGroups={allGroups}
          dateRange={dateRange}
          handleDateRangChange={handleDateRangChange}
          endDateRangeSchedule={endDateRangeSchedule}
          scheduleSentiments={scheduleSentiments}
          setScheduleSentiments={setScheduleSentiments}
          scheduleLanguage={scheduleLanguage}
          setScheduleLanguage={setScheduleLanguage}
          setScheduleGroups={setScheduleGroups}
          selectedGroups={scheduleGroups}
          setStartDateRangeSchedule={setStartDateRangeSchedule}
          setEndDateRangeSchedule={setEndDateRangeSchedule}
          open={openModalFilter}
          handleCloseFilterModel={handleCloseFilterModel}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          setLanguageSchedular={setLanguageSchedular}
        />
      )} */}

      {openModalFilter && (
        <EmotionAiFilterModal
          locations={scheduleLocations}
          setLocations={setScheduleLocations}
          allLocations={allLocations}
          setSlectedGroups={setScheduleGroups}
          selectedGroups={scheduleGroups}
          allGroups={allGroups}
          sentimentValue={scheduleSentiments}
          setScheduleSentiments={setScheduleSentiments}
          startDateRangeSchedule={startDateRangeSchedule}
          setStartDateRangeSchedule={setStartDateRangeSchedule}
          setEndDateRangeSchedule={setEndDateRangeSchedule}
          endDateRangeSchedule={endDateRangeSchedule}
          handleChangeStartDate={handleChangeRangeStartDateSchedule}
          handleChangeEndDate={handleChangeRangeEndDateSchedule}
          language={scheduleLanguage}
          setLanguage={setScheduleLanguage}
          dateRangeTimePeriod={dateRange}
          setDateRangeTimePeriod={setDateRange}
          handleDateRangChange={handleDateRangChange}
          openModalFilter={openModalFilter}
          handleCloseModalFilter={handleCloseFilterModel}
          handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          setLanguageSchedular={setLanguageSchedular}
          countries={countries}
          startDate={startDate}
          endDate={endDate}
          defaultLocation={defaultLocation}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          autoSelectHeader={autoSelectHeader}
          isSentimentalAnalysisDone={isSentimentalAnalysisDone}
          selectAll={selectAllGroups}
          setSelectAllLocations={setSelectAllLocations}
          selectAllLocations={selectAllLocations}
          setAutoSelect={setAutoSelect}
          autoSelect={autoSelect}
          selectAllGroups={selectAllFiltersGroups}
          setSelectAllGroups={setSelectAllFiltersGroups}
          selectAllFeatures={selectAllFiltersLocations}
          setSelectAllFeatures={setSelectAllFiltersLocations}
        />
      )}

      {openReviewsFilterModel && (
        <FilterModal
          open={openReviewsFilterModel}
          close={handleCloseReviewsFilterModel}
          groups={allGroups}
          tags={allTags}
          sources={allReviewSource}
          locations={allLocations}
          setFilterObj={() => {}}
          modalFilters={modalFilters}
          modalFiltersDate={modalFiltersDate}
          setModalFiltersDate={setModalFiltersDate}
          setModalFilters={handleSubmit}
          checked={checked}
          setChecked={() => {}}
          setCallApi={() => {}}
        />
        // <FilterModelReviews
        //   open={openReviewsFilterModel}
        //   handleCloseFilterModel={handleCloseReviewsFilterModel}
        //   scheduleLocations={scheduleLocations}
        //   setScheduleLocations={setScheduleLocations}
        //   scheduleGroups={scheduleGroups}
        //   setScheduleGroups={setScheduleGroups}
        //   scheduleReviewSource={scheduleReviewSource}
        //   setScheduleReviewSource={setScheduleReviewSource}
        //   scheduleTags={scheduleTags}
        //   setScheduleTags={setScheduleTags}
        //   scheduleRatingValue={scheduleRatingValue}
        //   setScheduleRatingValue={setScheduleRatingValue}
        //   scheduleReviewerName={scheduleReviewerName}
        //   setScheduleReviewerName={setScheduleReviewerName}
        //   scheduleReply={scheduleReply}
        //   setScheduleReply={setScheduleReply}
        //   allLocations={allLocations}
        //   allGroups={allGroups}
        //   allReviewSource={allReviewSource}
        //   starRatings={starRatings}
        //   allTags={allTags}
        //   dateRange={dateRange}
        //   setDateRange={setDateRange}
        //   handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
        //   haventReplied={haventReplied}
        //   handleChangeDateRange={handleChangeDateRange}
        //   startDate={startDate}
        //   setStartDate={setStartDate}
        //   setEndDate={setEndDate}
        //   endDate={endDate}
        // />
      )}

      {openLogoUploadModal && (
        <LogoImageModal
          title={t("upload_logo")}
          handleClose={handleCloseLogoUploadModal}
          open={openLogoUploadModal}
          selectedImages={images}
          setSelectedImages={setImages}
          setSelectedFile={setSelectedFileForImage}
          setUploadedImages={setUploadedImages}
          uploadedImages={uploadedImages}
        />
      )}
    </>
  );
};

export default FetchModel;
