import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Button,
  Paper,
  Box,
  TextField,
  Alert as MuiAlert,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { TextareaAutosize } from "@material-ui/core";
import { fontWeight, spacing } from "@mui/system";
import styled from "styled-components/macro";
import CommonButton from "../CustomComponents/Buttons/bulkButton";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  textFieldContainer: {
    paddingTop: "15px",
    display: "flex",
  },
  weekDaysContainer: {
    paddingTop: "20px",
    paddingBootom: "20px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    paddingBottom: "8px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  textFieldTypoRight: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    color: "#181818",
    paddingRight: "8px",
    paddingTop: "5px",
  },
  textFieldTypoLeft: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    color: "#181818",
    paddingLeft: "8px",
    paddingTop: "5px",
  },

  calenderContainer: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    padding: "12px",
    marginTop: "12px",
  },
  calenderLabelContainer: {
    height: "30px",
    padding: "5px",
    cursor: "pointer",
    textAlign: "center",
    margin: "3px",
  },
  calenderLabel: {
    textAlign: "center",
    fontWeight: 700,
  },
  checkboxContainer: {
    borderRadius: "8px",
    marginTop: "12px",
  },

  checkboxTypo: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#181818",
    paddingTop: "9px",
  },
}));

const Alert = styled(MuiAlert)(spacing);

const PostCustomModel = ({
  title,
  onCancel,
  open,
  frequencyValue,
  setFrequencyValue,
  occuranceValue,
  setOccuranceValue,
  selectedWeekDays,
  setSelectedWeekDays,
  selectedMonthDays,
  setSelectedMonthDays,
  handleSubmitRepeatDateData,
  selectedPostData,
  index,
  handleChangeFrequency,
  handleChangeOccurance,
  handleChangeCustomDays,
  handleChangeCustomDates,
  setOpenCustomModel,
  handleChangeRepeatPost,
}) => {
  const classes = useStyles();
  const textArea = useRef();
  const { t } = useTranslation();
  const [eachChecked, setEachChecked] = useState(false);
  const [occuranceError, setOccuranceError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [weekError, setWeekError] = useState(false);
  const [frequencyTempValue, setFrequencyTempValue] = useState("daily");
  const [occuranceTempValue, setOccuranceTempValue] = useState(1);
  const [selectedWeekTempDays, setSelectedWeekTempDays] = useState([]);
  const [selectedMonthTempDays, setSelectedMonthTempDays] = useState([]);

  const weekArr = [
    {
      name: "sun",
      label: t("sun"),
      value: 0,
    },
    {
      name: "mon",
      label: t("mon"),
      value: 1,
    },
    {
      name: "tue",
      label: t("tue"),
      value: 2,
    },
    {
      name: "wed",
      label: t("wed"),
      value: 3,
    },
    {
      name: "thu",
      label: t("thu"),
      value: 4,
    },
    {
      name: "fri",
      label: t("fri"),
      value: 5,
    },
    {
      name: "sat",
      label: t("sat"),
      value: 6,
    },
  ];
  const monthArr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  console.log(selectedPostData, "selectedPostData");
  useEffect(() => {
    if (open === true) {
      if (selectedPostData !== undefined) {
        // Using JSON methods to ensure deep copy for state updates
        const frequency =
          selectedPostData?.customSchedule?.frequency === ""
            ? "daily"
            : selectedPostData?.customSchedule?.frequency;
        const occurance = selectedPostData?.customSchedule?.occurance;
        const weekDays = JSON.parse(
          JSON.stringify(selectedPostData?.customSchedule?.days)
        );
        const monthDays = JSON.parse(
          JSON.stringify(selectedPostData?.customSchedule?.dates)
        );

        setFrequencyTempValue(frequency);
        setOccuranceTempValue(occurance);
        setSelectedWeekTempDays(weekDays);
        setSelectedMonthTempDays(monthDays);
      } else {
        const frequency = frequencyValue === "" ? "daily" : frequencyValue;
        const occurance = occuranceValue;
        const weekDays = JSON.parse(JSON.stringify(selectedWeekDays));
        const monthDays = JSON.parse(JSON.stringify(selectedMonthDays));

        setFrequencyTempValue(frequency);
        setOccuranceTempValue(occurance);
        setSelectedWeekTempDays(weekDays);
        setSelectedMonthTempDays(monthDays);
      }
    }
  }, [open]);
  useEffect(() => {
    handleCheckErrorMonth();
  }, [selectedMonthDays, selectedPostData]);
  useEffect(() => {
    handleCheckErrorWeek();
  }, [selectedWeekDays, selectedPostData]);

  const handleClickWeekDaysForTemp = (selectedDay) => {
    if (selectedWeekTempDays?.includes(selectedDay?.value)) {
      const filtered = selectedWeekTempDays?.filter(
        (item) => item !== selectedDay?.value
      );
      const sortedArrayDescending = filtered.slice().sort((a, b) => a - b);
      setSelectedWeekTempDays(sortedArrayDescending);
    } else {
      selectedWeekTempDays?.push(selectedDay?.value);
      const sortedArrayDescending = selectedWeekTempDays
        .slice()
        .sort((a, b) => a - b);
      setSelectedWeekTempDays([...sortedArrayDescending]);
    }
  };

  const handleClickWeekDays = () => {
    if (selectedPostData !== undefined) {
      let arr = [...selectedWeekTempDays];
      const sortedArrayDescending = arr.slice().sort((a, b) => a - b);
      // setSelectedWeekDays([...selectedWeekDays]);
      handleChangeCustomDays([...sortedArrayDescending], index);
    } else {
      const sortedArrayDescending = selectedWeekTempDays
        .slice()
        .sort((a, b) => a - b);
      setSelectedWeekDays([...sortedArrayDescending]);
      handleChangeCustomDays([...sortedArrayDescending], index);
    }
  };

  const handleCheckErrorMonth = () => {
    if (
      (selectedPostData !== undefined &&
        selectedPostData?.customSchedule?.dates?.length === 0 &&
        selectedPostData?.customSchedule?.frequency === "monthly") ||
      (selectedPostData === undefined &&
        frequencyValue === "monthly" &&
        selectedMonthDays?.length === 0)
    ) {
      setMonthError(true);
    } else {
      setMonthError(false);
    }
  };
  const handleCheckErrorWeek = () => {
    if (
      (selectedPostData !== undefined &&
        selectedPostData?.customSchedule?.days?.length === 0 &&
        selectedPostData?.customSchedule?.frequency === "weekly") ||
      (selectedPostData === undefined &&
        frequencyValue === "weekly" &&
        selectedWeekDays?.length === 0)
    ) {
      setWeekError(true);
    } else {
      setWeekError(false);
    }
  };

  const handleClickMonthDaysForTemp = (selectedDay) => {
    if (selectedMonthTempDays?.includes(selectedDay)) {
      const filtered = selectedMonthTempDays?.filter(
        (item) => item !== selectedDay
      );
      const sortedArrayDescending = filtered.slice().sort((a, b) => a - b);
      setSelectedMonthTempDays(sortedArrayDescending);
    } else {
      selectedMonthTempDays?.push(selectedDay);
      const sortedArrayDescending = selectedMonthTempDays
        .slice()
        .sort((a, b) => a - b);
      setSelectedMonthTempDays([...sortedArrayDescending]);
    }
  };
  const handleClickMonthDays = () => {
    if (selectedPostData !== undefined) {
      let arr = [...selectedMonthTempDays];
      const sortedArrayDescending = arr.slice().sort((a, b) => a - b);
      handleChangeCustomDates([...sortedArrayDescending], index);
    } else {
      const sortedArrayDescending = selectedMonthTempDays
        .slice()
        .sort((a, b) => a - b);
      setSelectedMonthDays([...sortedArrayDescending]);
      handleChangeCustomDates([...sortedArrayDescending], index);
    }
  };
  const handleChangeCheckButton = (e) => {
    setEachChecked(e.target.checked);
  };

  const handleSubmit = () => {
    console.log("hereee&&&&&&&&&&&&&&&&&&&&&&");
    if (selectedPostData !== undefined) {
      handleChangeFrequency(frequencyTempValue, index);
      handleChangeOccurance(occuranceTempValue, index);

      if (frequencyTempValue === "monthly") {
        handleClickMonthDays();
      } else if (frequencyTempValue === "weekly") {
        handleClickWeekDays();
      }
    } else {
      handleChangeRepeatPost("custom");
      handleChangeFrequency(frequencyTempValue, index);
      setFrequencyValue(frequencyTempValue);
      handleChangeOccurance(occuranceTempValue, index);
      setOccuranceValue(occuranceTempValue);
      if (frequencyTempValue === "monthly") {
        handleClickMonthDays();
        setSelectedMonthDays(selectedMonthTempDays);
      } else if (frequencyTempValue === "weekly") {
        handleClickWeekDays();
        setSelectedWeekDays(selectedWeekTempDays);
      }
    }
    setOpenCustomModel(false);
    // onCancel();
    setTimeout(() => {
      setFrequencyTempValue("");
      setOccuranceTempValue(1);
      setSelectedWeekTempDays([]);
      setSelectedMonthTempDays([]);
    }, 500);
  };

  return (
    <Dialog
      open={open}
      maxHeight
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          maxWidth: "400px",
          // height: "480px",
        },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <Typography className={classes.titleText}>{title}</Typography>
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            sx={{ justifyContent: "flex-end", textAlign: "end" }}
          >
            <IconButton
              autoFocus
              onClick={onCancel}
              className="delete_button"
              sx={{ paddingTop: "0px" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.labelText}>
              {t("frequency")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={frequencyTempValue}
                onChange={(e) => {
                  //  setFrequencyValue(e.target.value);
                  setFrequencyTempValue(e.target.value);
                  setSelectedMonthTempDays([]);
                  setSelectedWeekTempDays([]);
                  setOccuranceTempValue(1);
                  /*   if (selectedPostData===undefined) {
                    setSelectedMonthDays([]);
                    setSelectedWeekDays([]);
                    setOccuranceValue(1);
                    setOccuranceError(false);
                    setMonthError(false);
                    setWeekError(false);
                  } */

                  //  handleChangeFrequency(e, index);
                }}
                sx={{ width: "250px" }}
              >
                <MenuItem value="daily">{t("DailyText")}</MenuItem>
                <MenuItem value="weekly">{t("WeeklyText")}</MenuItem>
                <MenuItem value="monthly">{t("MonthlyText")}</MenuItem>
                {/* <MenuItem value="yearly">{t("YearlyText")}</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className={classes.textFieldContainer}>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Typography className={classes.textFieldTypoRight}>
              {t("every")}
            </Typography>
          </Grid>
          <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5}>
            <TextField
              type="number"
              size="small"
              value={occuranceTempValue}
              inputProps={{
                type: "number",
                min: 1,
                step: 1,
              }}
              onChange={(event) => {
                // setOccuranceValue(parseInt(event.target.value));
                setOccuranceTempValue(parseInt(event.target.value));
                //  handleChangeOccurance(event, index);
                if (event.target.value <= 0) {
                  setOccuranceError(true);
                } else {
                  setOccuranceError(false);
                }
              }}
              fullWidth
              error={occuranceError}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Typography className={classes.textFieldTypoLeft}>
              {frequencyTempValue === "daily"
                ? occuranceTempValue > 1
                  ? t("days_text")
                  : t("days")
                : frequencyTempValue === "weekly"
                ? occuranceTempValue > 1
                  ? t("weekTexts")
                  : t("weekText")
                : frequencyTempValue === "monthly"
                ? occuranceTempValue > 1
                  ? t("months")
                  : t("month")
                : t("")}
            </Typography>
          </Grid>
        </Grid>
        {/* {frequencyValue !== "weekly" && frequencyValue !== "daily" && (
          <Grid container className={classes.checkboxContainer}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Checkbox
                sx={{ paddingLeft: "0px" }}
                checked={eachChecked}
                onChange={(e) => handleChangeCheckButton(e)}
              />
              <Typography className={classes.checkboxTypo}>
                {t("each")}
              </Typography>
            </Box>
          </Grid>
        )} */}
        {frequencyTempValue !== "daily" && frequencyTempValue !== "monthly" && (
          <Grid className={classes.weekDaysContainer}>
            <Grid container>
              {weekArr?.map((item) => {
                return (
                  <Grid
                    item
                    xs={1.4}
                    sm={1.4}
                    md={1.4}
                    lg={1.4}
                    className={classes.calenderLabelContainer}
                    sx={{
                      background: selectedWeekTempDays?.includes(item?.value)
                        ? "#06BDFF"
                        : "#FFF",
                      borderRadius: "12px",
                      border: selectedWeekTempDays?.includes(item?.value)
                        ? "1px solid #06BDFF"
                        : "1px solid #E0E0E0",
                    }}
                    onClick={() => handleClickWeekDaysForTemp(item)}
                  >
                    <Typography
                      sx={{
                        color: selectedWeekTempDays?.includes(item?.value)
                          ? "#FFF"
                          : "#757D8A",
                        textAlign: "center",
                      }}
                    >
                      {item?.label}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}

        {frequencyTempValue !== "daily" && frequencyTempValue !== "weekly" && (
          <Grid container className={classes.calenderContainer}>
            {monthArr?.map((item) => {
              return (
                <Grid
                  item
                  xs={1.1}
                  sm={1.1}
                  md={1.1}
                  lg={1.1}
                  className={classes.calenderLabelContainer}
                  sx={{
                    background: selectedMonthTempDays?.includes(item)
                      ? "#06BDFF"
                      : "#FFF",
                    borderRadius: "12px",
                    border: selectedMonthTempDays?.includes(item)
                      ? "1px solid #06BDFF"
                      : "1px solid #E0E0E0",
                  }}
                  onClick={() => {
                    handleClickMonthDaysForTemp(item);
                  }}
                >
                  <Typography
                    className={classes.calenderLabel}
                    sx={{
                      color: selectedMonthTempDays?.includes(item)
                        ? "#FFF"
                        : "#757D8A",
                    }}
                  >
                    {item}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
      </DialogContent>
      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Box sx={{ marginRight: "10px" }}>
          <CommonButton
            displayWhite="true"
            onSubmit={onCancel}
            // disabled={isSubmitting}
            label={t("Cancel")}
          />
        </Box>
        <Box>
          <CommonButton
            disabled={
              occuranceTempValue === 0 ||
              occuranceError ||
              (selectedMonthTempDays?.length === 0 &&
                frequencyTempValue === "monthly") ||
              (selectedWeekTempDays?.length === 0 &&
                frequencyTempValue === "weekly")
            }
            type="submit"
            label={t("Save")}
            onSubmit={handleSubmit}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default PostCustomModel;
