import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Autocomplete,
  TextField,
  IconButton,
  Divider,
  Select,
  MenuItem,
  createFilterOptions,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
//import EmotionAiFilterModal from "./../../Scheduler/FilterModel";
import EmotionAiFilterModal from "./../../Scheduler/FilterModelNew";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { languagesData } from "../../../../../../assets/defaultLanguage";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../Style/style";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LogoImageModal from "./UploadModels/CoverImageModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePickerComponent from "../../../../../../components/CustomComponents/DateRangePicker/RangePicker";

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  isLoading,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  handleDownloadEmotionAiReport,
  exportReportLoading,
  handleReviewsChecked,
  reviewsChecked,
  setReviewsChecked,
  setDateRange,
  dateRange,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setLanguageSchedular,
  handleChangeRangeEndDateSchedule,
  endDateRangeSchedule,
  handleChangeRangeStartDateSchedule,
  startDateRangeSchedule,
  setStartDateRangeSchedule,
  setEndDateRangeSchedule,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  setScheduleLanguage,
  scheduleLanguage,
  setScheduleSentiments,
  scheduleSentiments,
  allLocations,
  allGroups,
  startDate,
  endDate,
  handleResetScheduleFiltersModel,
  setEmailTitle,
  featuresArray,
  setSelectedFeatures,
  selectedFeatures,
  handleChangeFeature,
  fromHeader,
  sectionOption,
  handleChangeManageSections,
  sectionFormat,
  manageSectionOptions,
  uploadedImages,
  setUploadedImages,
  images,
  setImages,
  countries,
  compareRange,
  defaultLocation,
  locationAddPermission,
  locationViewPermission,
  autoSelectHeader,
  isSentimentalAnalysisDone,
  selectAll,
  setSelectAllLocations,
  selectAllLocations,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const currentPath = location.pathname.replace(/\/+$/, "");
  const [startDateError, setStartDateError] = useState(false);
  const [dateRangeError, setDateRangeError] = useState("");
  const [openModalFilter, setOpenFilterModel] = useState(false);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [selectedFile, setSelectedFile] = useState(null);
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);
  const [autoSelect, setAutoSelect] = useState(autoSelectHeader);
  const [selectAllFiltersLocations, setSelectAllFiltersLocations] =
    useState(selectAllLocations);
  const [selectAllGroups, setSelectAllGroups] = useState(selectAll);

  useEffect(() => {
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
  }, [open]);

  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
    if (xlsChecked === false) {
      setReviewsChecked(false);
    }
  };
  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);
    if (pdfChecked === false) {
      setReviewsChecked(false);
    }
    //  setReviewsChecked(false);
  };

  const handleOpenFilterModel = () => {
    setOpenFilterModel(true);
  };
  const handleCloseFilterModel = (resetValue) => {
    setOpenFilterModel(false);
    if (resetValue !== true) {
      setSelectAllFiltersLocations(selectAllLocations);
      setSelectAllGroups(selectAll);
    }
  };

  const handleDateRangChange = (e, fromModel, startDateValue, endDateValue) => {
    console.log(e, "e.target.value");
    const value = fromModel ? e : e.target.value;
    setDateRange(value);
    setDateRangeError(value === "");
    const today = new Date();
    let date = dayjs(today);
    let oneDaysBefore = date.subtract(1, "day").endOf("day");
    let oneDaysBeforeDate = oneDaysBefore.toDate();

    if (today !== null && today !== "") {
      if (value === "Yesterday") {
        let oneDaysBefore = date.subtract(1, "day");
        let oneDaysBeforeDateStart = oneDaysBefore.startOf("day").toDate();
        let oneDaysBeforeDateEnd = oneDaysBefore.endOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDateEnd);
        setStartDateRangeSchedule(oneDaysBeforeDateStart);
      } else if (value === "Today") {
        let todayDayEnd = date.endOf("day");
        let oneDaysBefore = date.startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(todayDayEnd);
        setStartDateRangeSchedule(oneDaysBeforeDate);
      } else if (value === "2days") {
        let twoDaysBefore = date.subtract(2, "day");
        let twoDaysBeforeDate = twoDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(twoDaysBeforeDate);
      } else if (value === "7days") {
        let sevenDaysBefore = date.subtract(7, "day");
        let sevenDaysBeforeDate = sevenDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(sevenDaysBeforeDate);
      } else if (value === "week") {
        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate.subtract(
          weekBeforeSelectedDate.day() - 1,
          "day"
        );
        let followingSunday = previousMonday.add(6, "day");
        setEndDateRangeSchedule(followingSunday.endOf("day").toDate());
        setStartDateRangeSchedule(previousMonday.startOf("day").toDate());
      } else if (value === "month") {
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
        setStartDateRangeSchedule(startOfMonthBefore.startOf("day").toDate());
      } else if (value === "14days") {
        let fourteenDaysBefore = date.subtract(14, "day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(fourteenDaysBeforeDate);
      } else if (value === "30days") {
        let oneMonthBefore = date.subtract(30, "day");
        let oneMonthBeforeDate = oneMonthBefore.startOf("day").toDate();
        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(oneMonthBeforeDate);
      } else if (value === "60days") {
        let sixtyDaysBefore = date.subtract(60, "day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.startOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(sixtyDaysBeforeDate);
      } else if (value === "90days") {
        let ninetyDaysBefore = date.subtract(90, "day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.startOf("day").toDate();

        setEndDateRangeSchedule(oneDaysBeforeDate);
        setStartDateRangeSchedule(ninetyDaysBeforeDate);
      } else if (value === "6months") {
        let sixMonthsBefore = date.subtract(6, "month");
        let sixMonthsBeforeDate = sixMonthsBefore.startOf("month").toDate();
        setStartDateRangeSchedule(sixMonthsBeforeDate);

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
      } else if (value === "12months") {
        let oneYearBefore = date.subtract(12, "month");
        let oneYearBeforeDate = oneYearBefore.startOf("month").toDate();

        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDateRangeSchedule(endOfMonthBefore.toDate());
        setStartDateRangeSchedule(oneYearBeforeDate);
      } else if (value === "custom") {
        if (fromModel === false) {
          setEndDateRangeSchedule(endDate);
          setStartDateRangeSchedule(startDate);
        } else {
          setEndDateRangeSchedule(endDateValue);
          setStartDateRangeSchedule(startDateValue);
        }
      }
    }
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "50%",
              height: "550px",
            },
          }}
          open={open}
        >
          <Grid container sx={{ padding: "12px" }}>
            <Grid item xs={10} sm={10} md={11} lg={11}>
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                  marginTop: "5px",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <IconButton
                autoFocus
                onClick={handleCloseNewRequestModel}
                className="delete_button"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
          <DialogContent
            dividers
            sx={{
              "& .MuiDialogContent-root": {
                padding: "12px",
              },
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.emailReportLabel}>
                    {t("choose_a_formate_heading")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container sx={{ marginLeft: "-10px" }}>
                    <Grid item xs={3}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onChange={handleChangePdfChecked}
                          checked={pdfChecked}
                        />
                        <Typography>{"PDF"}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onChange={handleChangeXlsChecked}
                          checked={xlsChecked}
                        />
                        <Typography>{"XLS"}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}></Grid>

                    <Grid item xs={8}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          onChange={handleReviewsChecked}
                          checked={reviewsChecked}
                        />
                        <Typography className={classes.scheduleReviewTypoEmail}>
                          {t("add_reviews")}
                        </Typography>
                      </Box>

                      {reviewsChecked && (
                        <Box
                          className={classes.reviewsubHeadBox}
                          sx={{ marginLeft: "12px" }}
                        >
                          <Grid container>
                            <Grid item xs={1} sm={1} md={0.8} lg={0.8}>
                              <InfoOutlinedIcon
                                className={classes.scheduleReviewsIcon}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={11}
                              sm={11}
                              md={11.2}
                              lg={11.2}
                              className={classes.scheduleReviewInfoGrid}
                            >
                              <Typography
                                className={classes.scheduleReviewInfoTypoEmail}
                              >
                                {t("add_reviews_info")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.emailReportLabel}>
                    {t("upload_logo")}
                  </Typography>

                  <Box mt={3} ml={-3}>
                    <CommonButton
                      onSubmit={handleOpenLogoUploadModal}
                      label={t("Select_logo")}
                    />
                  </Box>
                  <Box>
                    {selectedFile ? (
                      <Box className={classes.fileBoxStyle}>
                        <Typography className={classes.selectedFileTypo}>
                          {selectedFile?.name}
                        </Typography>
                        <IconButton
                          className={classes.removeFileButton}
                          onClick={() => handleRemoveFile()}
                        >
                          <DeleteIcon
                            fontSize="small"
                            className={classes.deleteIcon}
                          />
                        </IconButton>
                      </Box>
                    ) : (
                      <p>{t("")}</p>
                    )}
                  </Box>
                </Grid>

                {fromHeader && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.emailReportSubLabel}>
                      {t("manage_sections")}
                    </Typography>
                    <Autocomplete
                      id="tags-outlined"
                      options={manageSectionOptions}
                      fullWidth
                      value={sectionOption}
                      getOptionLabel={(option) => option?.title}
                      filterSelectedOptions
                      onChange={handleChangeManageSections}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("select_section")}
                        />
                      )}
                    />
                  </Grid>
                )}

                {fromHeader && sectionFormat === "specificReport" && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.emailReportSubLabel}>
                      {t("select_features")}
                    </Typography>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      id="tags-outlined"
                      options={featuresArray}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, value) => {
                        if (value.find((option) => option.id === 0)) {
                          if (
                            featuresArray?.length === selectedFeatures?.length
                          ) {
                            setSelectedFeatures([]);
                            setSelectAllFeatures(false);
                          } else {
                            setSelectAllFeatures(true);
                            setSelectedFeatures(featuresArray);
                          }
                          handleChangeFeature(featuresArray);
                        } else {
                          setSelectedFeatures(value);
                          handleChangeFeature(value);
                          if (value?.length === featuresArray?.length) {
                            setSelectAllFeatures(true);
                          } else {
                            setSelectAllFeatures(false);
                          }
                        }
                      }}
                      value={selectedFeatures}
                      sx={{ minWidth: 300 }}
                      renderOption={(props, option, { selected }) => {
                        if (option.id === 0) {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selectAllFeatures}
                              />
                              {option?.name}
                            </li>
                          );
                        } else {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected || selectAllFeatures}
                              />
                              {option?.name}
                            </li>
                          );
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (featuresArray?.length > 0) {
                          return [
                            { id: 0, name: t("Select_All") },
                            ...filtered,
                          ];
                        } else {
                          return [];
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("select_features_tooltip")}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sm={4.5}
                  md={4.5}
                  lg={4.5}
                  sx={{ paddingTop: "0px" }}
                >
                  <Typography className={classes.emailReportSubLabel}>
                    {t("date_range")}
                  </Typography>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={dateRange}
                    onChange={(e) => {
                      handleDateRangChange(e, false);
                    }}
                    fullWidth
                    error={dateRangeError}
                    helperText={dateRangeError ? t("Field_req") : ""}
                  >
                    {currentPath === "/user/reviews" && (
                      <MenuItem value="All">{t("All")}</MenuItem>
                    )}

                    <MenuItem value="Today">{t("today")}</MenuItem>
                    <MenuItem value="Yesterday">{t("yesterday")}</MenuItem>
                    <MenuItem value="2days">{t("2days")}</MenuItem>
                    <MenuItem value="7days">{t("7days")}</MenuItem>
                    <MenuItem value="week">{t("week")}</MenuItem>

                    <MenuItem value="14days">{t("14days")}</MenuItem>
                    <MenuItem value="30days">{t("30days")}</MenuItem>
                    <MenuItem value="month">{t("monthValue")}</MenuItem>
                    <MenuItem value="60days">{t("60days")}</MenuItem>
                    <MenuItem value="90days">{t("90days")}</MenuItem>
                    <MenuItem value="6months">{t("6months")}</MenuItem>
                    <MenuItem value="12months">{t("12months")}</MenuItem>
                    <MenuItem value="custom">{t("custom_text")}</MenuItem>
                  </Select>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4.5}
                  md={4.5}
                  lg={4.5}
                  sx={{ paddingTop: "0px" }}
                >
                  <Typography className={classes.emailReportSubLabel}>
                    {t("report_language")}
                  </Typography>
                  <Select
                    required
                    style={{ width: "100%" }}
                    key={languageSchedular?.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={languageSchedular?.value}
                    onChange={(e, value) => {
                      const userLang = languagesData?.find(
                        (item) => item?.value === e.target.value
                      );
                      handleChangeLanguageForScheduler(userLang);
                    }}
                  >
                    {languagesData.map((item) => (
                      <MenuItem value={item.value}>{item.language}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  sx={{ paddingTop: "0px" }}
                >
                  <Box sx={{ marginTop: "25px" }}>
                    <CommonButton
                      icon={<FilterAltIcon sx={{ color: "#FFFF" }} />}
                      onSubmit={handleOpenFilterModel}
                      label={t("Filters")}
                    />
                  </Box>
                </Grid>
                {/* {dateRange === "custom" && currentPath !== "/user/reviews" && (
                  <>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography className={classes.emailReportSubLabel}>
                        {t("Fromm")}
                      </Typography>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={
                            startDateRangeSchedule !== null
                              ? dayjs(startDateRangeSchedule)
                              : null
                          }
                          format="DD/MM/YYYY"
                          disableFuture
                          onChange={(e) => {
                            const newDate = e
                              ? dayjs(e).startOf("day").toDate()
                              : null;
                            handleChangeRangeStartDateSchedule(newDate);
                          }}
                          className={classes.whiteBackground}
                          minDate={dayjs("2005-01-01")}
                          maxDate={dayjs().endOf("day")}
                          renderInput={(params) => (
                            <TextField
                              autoComplete="off"
                              fullWidth
                              {...params}
                              sx={{ background: "#FFFF" }}
                              error={startDateError}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography className={classes.emailReportSubLabel}>
                        {t("Too")}
                      </Typography>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disableFuture
                          value={
                            endDateRangeSchedule !== null
                              ? dayjs(endDateRangeSchedule)
                              : null
                          }
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            handleChangeRangeEndDateSchedule(e);
                          }}
                          className={classes.whiteBackground}
                          minDate={dayjs("2005-01-01")}
                          renderInput={(params) => (
                            <TextField
                              error
                              autoComplete="off"
                              fullWidth
                              {...params}
                              sx={{ background: "#FFFF" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                )} */}

                {dateRange === "custom" && currentPath !== "/user/reviews" && (
                  <DatePickerComponent
                    handleChangeStartDate={handleChangeRangeStartDateSchedule}
                    handleChangeEndDate={handleChangeRangeEndDateSchedule}
                    startDate={
                      startDateRangeSchedule !== null
                        ? startDateRangeSchedule
                        : null
                    }
                    endDate={
                      endDateRangeSchedule !== null
                        ? endDateRangeSchedule
                        : null
                    }
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    fromFilters={true}
                    rangeValue={"between"}
                    removePadding={false}
                  />
                )}
              </Grid>
            )}
          </DialogContent>

          <DialogActions sx={{ marginRight: "12px" }}>
            <CommonButton
              label={t("Cancel")}
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
            />
            <CommonButton
              label={t("download")}
              icon={<FileDownloadOutlinedIcon />}
              onSubmit={handleDownloadEmotionAiReport}
              disabled={
                pdfChecked === false && xlsChecked === false
                  ? true
                  : exportReportLoading === true
                  ? true
                  : fromHeader &&
                    sectionFormat === "specificReport" &&
                    selectedFeatures?.length === 0
                  ? true
                  : false
              }
              isLoading={exportReportLoading}
            />
          </DialogActions>
        </Dialog>

        {openModalFilter && (
          <EmotionAiFilterModal
            locations={scheduleLocations}
            setLocations={setScheduleLocations}
            allLocations={allLocations}
            setSlectedGroups={setScheduleGroups}
            selectedGroups={scheduleGroups}
            allGroups={allGroups}
            sentimentValue={scheduleSentiments}
            setScheduleSentiments={setScheduleSentiments}
            startDateRangeSchedule={startDateRangeSchedule}
            setStartDateRangeSchedule={setStartDateRangeSchedule}
            setEndDateRangeSchedule={setEndDateRangeSchedule}
            endDateRangeSchedule={endDateRangeSchedule}
            handleChangeStartDate={handleChangeRangeStartDateSchedule}
            handleChangeEndDate={handleChangeRangeEndDateSchedule}
            language={scheduleLanguage}
            setLanguage={setScheduleLanguage}
            dateRangeTimePeriod={dateRange}
            setDateRangeTimePeriod={setDateRange}
            handleDateRangChange={handleDateRangChange}
            openModalFilter={openModalFilter}
            handleCloseModalFilter={handleCloseFilterModel}
            handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
            setLanguageSchedular={setLanguageSchedular}
            countries={countries}
            compareRange={compareRange}
            startDate={startDate}
            endDate={endDate}
            defaultLocation={defaultLocation}
            locationAddPermission={locationAddPermission}
            locationViewPermission={locationViewPermission}
            autoSelectHeader={autoSelectHeader}
            isSentimentalAnalysisDone={isSentimentalAnalysisDone}
            selectAll={selectAll}
            setSelectAllLocations={setSelectAllLocations}
            selectAllLocations={selectAllLocations}
            setAutoSelect={setAutoSelect}
            autoSelect={autoSelect}
            selectAllGroups={selectAllGroups}
            setSelectAllGroups={setSelectAllGroups}
            selectAllFeatures={selectAllFiltersLocations}
            setSelectAllFeatures={setSelectAllFiltersLocations}
          />
        )}

        {openLogoUploadModal && (
          <LogoImageModal
            title={t("upload_logo")}
            handleClose={handleCloseLogoUploadModal}
            open={openLogoUploadModal}
            selectedImages={images}
            setSelectedImages={setImages}
            setSelectedFile={setSelectedFile}
            setUploadedImages={setUploadedImages}
            uploadedImages={uploadedImages}
          />
        )}
      </div>
    </>
  );
};

export default FetchModel;
