import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../../../../../contexts/JWTContext";
import moment from "moment";
import Hours from "./hour";
import Loader from "../../../../../../../../components/Loaders/Loader";
import { useStyles } from "../../../../../Styles/style";

const CompanyOpeninghours = ({
  item,
  onCancel,
  locationDetailsData,
  location,
  getLocationDetails,
  index,
  allLocationSelectedHours,
  setTouchedValue,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState([]);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [filteredPeriodsArr, setFilteredPeriodsArr] = useState([]);
  const [timesArr, setTimesArr] = useState([]);
  const [openTimesError, setOpenTimesError] = useState(false);
  const [closeTimesError, setCloseTimesError] = useState(false);

  useEffect(() => {
    handlePeriodsData();
  }, []);

  const handlePeriodsData = () => {
    const finalFilteredData = [];
    const arr2 = [];
    let periodsData = item?.periods;
    periodsData?.forEach((itemOne) => {
      let openTimes = [];
      let closeTimes = [];
      let mergedTimes = [];
      periodsData?.forEach((itemtwo) => {
        if (
          itemOne?.openDay === itemtwo?.openDay &&
          itemOne?.closeDay === itemtwo?.closeDay
        ) {
          openTimes?.push(itemtwo.openTime);
          closeTimes.push(itemtwo.closeTime);
          const obj = {
            openHours: itemtwo.openTime,
            closedHours: itemtwo.closeTime,
            openTimesValueMerged: "",
            closeTimesValueMerged: "",
          };
          mergedTimes.push(obj);
          /* itemOne.mergedOpen = [itemOne.openTime, itemtwo.openTime];
          itemOne.mergedClose = [itemOne.closeTime, itemtwo.closeTime]; */
        }
      });
      itemOne.mergedTimes = [...mergedTimes];
      /*   itemOne.mergedClose = [...closeTimes]; */
      finalFilteredData.push(itemOne);
      /*   timesArr.push(itemOne);
      setTimesArr([...timesArr]); */

      finalFilteredData.forEach((item) => {
        let isFound = false;
        timesArr.forEach((item2) => {
          if (item.openDay === item2.openDay) {
            isFound = true;
          }
        });
        if (!isFound) {
          timesArr.push(item);
          arr2.push(item);
        }
      });
      setTimesArr([...timesArr]);
    });

    const weekDays = [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ];
    let tempObj = {
      mergedTimes: [],
      closeDay: "",
      closeTime: {
        hours: 0,
        minutes: 0,
      },
      openDay: "",
      openTime: {
        hours: 0,
        minutes: 0,
      },
    };

    const filteredData = weekDays.map((itemOne, index) => {
      let found = false;
      let data = "";
      arr2?.forEach((itemTwo) => {
        if (itemTwo?.closeDay === itemOne) {
          found = true;
          data = itemTwo;
        }
      });
      if (found) {
        tempObj = {
          mergedTimes: data?.mergedTimes,
          closed: null,
          closeDay: data?.closeDay,
          closeTime: {
            hours: data?.closeTime?.hours,
            minutes: 0,
          },
          openDay: data?.openDay,
          openTime: {
            hours: data?.openTime?.hours,
            minutes: data.openTime?.minutes,
          },
        };
        setOpen(true);
      } else {
        tempObj = {
          mergedTimes: [],
          closed: null,
          closeDay: itemOne,
          closeTime: {
            hours: 0,
            minutes: 0,
          },
          openDay: itemOne,
          openTime: {
            hours: 0,
            minutes: 0,
          },
        };

        setOpen(false);
      }
      filteredPeriodsArr.push(tempObj);
    });

    setFilteredPeriodsArr([...filteredPeriodsArr]);
    allLocationSelectedHours[index].periods = filteredPeriodsArr;
  };
  console.log(filteredPeriodsArr, "filteredPeriodsArr");

  const handleChangeOpen = (e, periods, ind) => {
    setTouchedValue(true);
    const date = new Date();
    if (e === null) {
      let tempObj = {
        closed: null,
        mergedTimes: [
          {
            openHours: {
              hours: 0,
              minutes: 0,
            },
            closedHours: {
              hours: 0,
              minutes: 0,
            },
            openTimesValueMerged: date,
            closeTimesValueMerged: date,
          },
        ],
        closeDay: periods?.closeDay,
        closeTime: {
          hours: 0,
          minutes: 0,
        },
        openDay: periods?.openDay,
        openTime: {
          hours: 0,
          minutes: 0,
        },
      };
      filteredPeriodsArr[ind] = tempObj;
    } else if (e) {
      if (ind > 0) {
        const aboveData = filteredPeriodsArr[ind - 1];
        if (aboveData !== undefined && aboveData?.closed === false) {
          const mergedTimesData =
            aboveData?.mergedTimes[aboveData?.mergedTimes?.length - 1];
          let tempObj = {
            closed: false,
            mergedTimes: [
              {
                openHours: {
                  hours: mergedTimesData?.openHours?.hours
                    ? mergedTimesData?.openHours?.hours
                    : 0,
                  minutes: mergedTimesData?.openHours?.minutes
                    ? mergedTimesData?.openHours?.minutes
                    : 0,
                },
                closedHours: {
                  hours: mergedTimesData?.closedHours?.hours
                    ? mergedTimesData?.closedHours?.hours
                    : 0,
                  minutes: mergedTimesData?.closedHours?.minutes
                    ? mergedTimesData?.closedHours?.minutes
                    : 0,
                },
                openTimesValueMerged: mergedTimesData?.openTimesValueMerged
                  ? mergedTimesData?.openTimesValueMerged
                  : date,
                closeTimesValueMerged: mergedTimesData?.closeTimesValueMerged
                  ? mergedTimesData?.closeTimesValueMerged
                  : date,
              },
            ],
            closeDay: periods?.closeDay,
            closeTime: {
              hours: aboveData?.closeTime?.hours
                ? aboveData?.closeTime?.hours
                : 0,
              minutes: aboveData?.closeTime?.minutes
                ? aboveData?.closeTime?.minutes
                : 0,
            },
            openDay: periods?.openDay,
            openTime: {
              hours: aboveData?.openTime?.hours
                ? aboveData?.openTime?.hours
                : 0,
              minutes: aboveData?.openTime?.minutes
                ? aboveData?.openTime?.minutes
                : 0,
            },
          };
          filteredPeriodsArr[ind] = tempObj;
        } else {
          let tempObj = {
            closed: false,
            mergedTimes: [
              {
                openHours: {
                  hours: 0,
                  minutes: 0,
                },
                closedHours: {
                  hours: 0,
                  minutes: 0,
                },
                openTimesValueMerged: date,
                closeTimesValueMerged: date,
              },
            ],
            closeDay: periods?.closeDay,
            closeTime: {
              hours: 0,
              minutes: 0,
            },
            openDay: periods?.openDay,
            openTime: {
              hours: 0,
              minutes: 0,
            },
          };
          filteredPeriodsArr[ind] = tempObj;
        }
      } else {
        let tempObj = {
          closed: false,
          mergedTimes: [
            {
              openHours: {
                hours: 0,
                minutes: 0,
              },
              closedHours: {
                hours: 0,
                minutes: 0,
              },
              openTimesValueMerged: date,
              closeTimesValueMerged: date,
            },
          ],
          closeDay: periods?.closeDay,
          closeTime: {
            hours: 0,
            minutes: 0,
          },
          openDay: periods?.openDay,
          openTime: {
            hours: 0,
            minutes: 0,
          },
        };
        filteredPeriodsArr[ind] = tempObj;
      }

      setOpen(true);
    } else {
      filteredPeriodsArr[ind].closed = true;
      setOpen(false);
    }
    setFilteredPeriodsArr([...filteredPeriodsArr]);
    allLocationSelectedHours[index].periods = filteredPeriodsArr;
  };
  const handleAddHours = (periodsIndex, itemIndex) => {
    setTouchedValue(true);
    const date = new Date();
    if (filteredPeriodsArr[periodsIndex].mergedTimes?.length > 0) {
      const aboveData =
        filteredPeriodsArr[periodsIndex].mergedTimes[
          filteredPeriodsArr[periodsIndex].mergedTimes?.length - 1
        ];
      let obj = {
        openHours: {
          hours: aboveData?.openHours?.hours ? aboveData?.openHours?.hours : 0,
          minutes: aboveData?.openHours?.minutes
            ? aboveData?.openHours?.minutes
            : 0,
        },
        closedHours: {
          hours: aboveData?.closedHours?.hours
            ? aboveData?.closedHours?.hours
            : 0,
          minutes: aboveData?.closedHours?.minutes
            ? aboveData?.closedHours?.minutes
            : 0,
        },
        openTimesValueMerged: aboveData?.openTimesValueMerged
          ? aboveData?.openTimesValueMerged
          : date,
        closeTimesValueMerged: aboveData?.closeTimesValueMerged
          ? aboveData?.closeTimesValueMerged
          : date,
      };
      filteredPeriodsArr[periodsIndex].mergedTimes?.push(obj);
    } else {
      let obj = {
        openHours: {
          hours: 0,
          minutes: 0,
        },
        closedHours: {
          hours: 0,
          minutes: 0,
        },
        openTimesValueMerged: date,
        closeTimesValueMerged: date,
      };

      filteredPeriodsArr[periodsIndex].mergedTimes?.push(obj);
    }

    setFilteredPeriodsArr([...filteredPeriodsArr]);
    allLocationSelectedHours[index].periods = [...filteredPeriodsArr];
  };
  const handleRemoveHours = (periodsIndex, itemIndex) => {
    setTouchedValue(true);
    const filteredMergedArr = filteredPeriodsArr[
      periodsIndex
    ].mergedTimes.filter((el, i) => i !== itemIndex);
    filteredPeriodsArr[periodsIndex].mergedTimes = filteredMergedArr;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
    allLocationSelectedHours[index].periods = [...filteredPeriodsArr];
  };

  const handleChangeOpenTime = (e, itemIndex, item, periodsIndex) => {
    setTouchedValue(true);

    const formattedDate = dayjs(e).format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );
    const obj = {
      openHours: {
        hours: dayjs(e).hour(),
        minutes: dayjs(e).minute(),
      },
      closedHours: {
        hours: item?.closedHours?.hours,
        minutes: item?.closedHours?.minutes,
      },
      openTimesValueMerged: formattedDate,
      closeTimesValueMerged: item?.closeTimesValueMerged,
    };
    filteredPeriodsArr[periodsIndex].mergedTimes[itemIndex] = obj;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
    allLocationSelectedHours[index].periods = [...filteredPeriodsArr];
  };
  const handleChangeCloseTime = (e, itemIndex, item, periodsIndex) => {
    setTouchedValue(true);
    const formattedDate = dayjs(e).format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );

    const obj = {
      openHours: {
        hours: item?.openHours?.hours,
        minutes: item?.openHours?.minutes,
      },
      closedHours: {
        hours: dayjs(e).hour(),
        minutes: dayjs(e).minute(),
      },
      openTimesValueMerged: item?.openTimesValueMerged,
      closeTimesValueMerged: formattedDate,
    };
    filteredPeriodsArr[periodsIndex].mergedTimes[itemIndex] = obj;
    setFilteredPeriodsArr([...filteredPeriodsArr]);
    allLocationSelectedHours[index].periods = [...filteredPeriodsArr];
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "5px" }}>
        {isLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.loaderBox}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              // className={classes.actionContent}
            >
              <Grid container sx={{ paddingY: "6px" }}>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  {filteredPeriodsArr &&
                    filteredPeriodsArr.map((periods, ind) => {
                      return (
                        <Grid
                          container
                          // mb={4}
                          key={ind}
                          // sx={{ marginBottom: "10px" }}
                        >
                          <Hours
                            index={ind}
                            periods={periods}
                            openTime={openTime}
                            closeTime={closeTime}
                            handleChangeCloseTime={handleChangeCloseTime}
                            handleChangeOpenTime={handleChangeOpenTime}
                            handleChangeOpen={handleChangeOpen}
                            open={open}
                            filteredPeriodsArr={filteredPeriodsArr}
                            setSelectedData={setSelectedData}
                            selectedData={selectedData}
                            getLocationDetails={getLocationDetails}
                            handleAddHours={handleAddHours}
                            handleRemoveHours={handleRemoveHours}
                            openTimesError={openTimesError}
                            setOpenTimesError={setOpenTimesError}
                            setCloseTimesError={setCloseTimesError}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default CompanyOpeninghours;
