import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Button,
  Autocomplete,
  TextField,
  IconButton,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterModelReviews from "./ScheduleFiltersModel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { languagesData } from "../../../../../assets/defaultLanguage";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../EmotionAi/Style/style";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import LogoImageModal from "./UploadModels/CoverImageModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePickerComponent from "../../../../../components/CustomComponents/DateRangePicker/RangePicker";
import FilterModal from "../../../../../components/Models/FilterModalReviews";
import moment from "moment";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseNewRequestModel,
  setDateRange,
  startDate,
  endDate,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setLanguageSchedular,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  scheduleReviewSource,
  setScheduleReviewSource,
  scheduleTags,
  setScheduleTags,
  scheduleRatingValue,
  setScheduleRatingValue,
  scheduleReviewerName,
  setScheduleReviewerName,
  scheduleReply,
  setScheduleReply,
  allLocations,
  allGroups,
  allReviewSource,
  starRatings,
  allTags,
  handleResetScheduleFiltersModel,
  haventReplied,
  handleChangeDateRange,
  handleChangeRangeEndDateSchedule,
  handleChangeRangeStartDateSchedule,

  setStartDate,
  setEndDate,
  setEmailTitle,
  emailTitle,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  dateRange,
  loadingXlsReport,
  handleDownloadXLSReport,
  setUploadedImages,
  setImages,
  images,
  uploadedImages,
  modalFilters,
  modalFiltersDate,
  setModalFiltersDate,
  setModalFilters,
  checked,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const currentPath = location.pathname.replace(/\/+$/, "");
  const [startDateError, setStartDateError] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [dateRangeError, setDateRangeError] = useState("");
  const [openReviewsFilterModel, setOpenReviewsFilterModel] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);

  const timePeriod = [
    { id: 0, name: "All", value: "All" },
    { id: 1, name: "today", value: "Today" },
    { id: 2, name: "yesterday", value: "Yesterday" },
    { id: 3, name: "2days", value: "2days" },
    { id: 4, name: "7days", value: "7days" },
    { id: 5, name: "week", value: "week" },
    { id: 6, name: "14days", value: "14days" },
    { id: 7, name: "30days", value: "30days" },
    { id: 8, name: "monthValue", value: "month" },
    { id: 9, name: "60days", value: "60days" },
    { id: 10, name: "90days", value: "90days" },
    { id: 11, name: "6months", value: "6months" },
    { id: 12, name: "12months", value: "12months" },
    { id: 12, name: "custom_text", value: "custom" },
  ];

  useEffect(() => {
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
  }, [open]);

  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
    // setXlsChecked(false);
  };
  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);
    //  setPdfChecked(false);
  };

  const handleOpenReviewsFilterModel = () => {
    setOpenReviewsFilterModel(true);
  };
  const handleCloseReviewsFilterModel = () => {
    setOpenReviewsFilterModel(false);
  };
  const handleDateRangChange = (e) => {
    const value = e.target.value;
    setDateRange(value);
    setDateRangeError(value === "");
    setModalFilters((prevFilters) => ({
      ...prevFilters,
      timePeriod: value,
      time: timePeriod.find((item) => item.value === value),
    }));
    const today = new Date();
    let date = dayjs(today);
    let oneDaysBefore = date.subtract(1, "day").endOf("day");
    let oneDaysBeforeDate = oneDaysBefore.toDate();
    if (today !== null && today !== "") {
      if (value === "Yesterday") {
        let date = dayjs(today);
        let oneDaysBefore = date.subtract(1, "day").startOf("day");
        let oneDaysBeforeDateStart = oneDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(oneDaysBeforeDateStart);
      } else if (value === "Today") {
        let date = dayjs(today);
        let oneDaysBefore = date.startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();
        setEndDate(today);
        setStartDate(oneDaysBeforeDate);
      } else if (value === "2days") {
        let date = dayjs(today);
        let twoDaysBefore = date.subtract(2, "day").startOf("day");
        let twoDaysBeforeDate = twoDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(twoDaysBeforeDate);
      } else if (value === "7days") {
        let date = dayjs(today);
        let sevenDaysBefore = date.subtract(7, "day").startOf("day");
        let sevenDaysBeforeDate = sevenDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(sevenDaysBeforeDate);
      } else if (value === "week") {
        let date = dayjs(today);

        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate
          .subtract(weekBeforeSelectedDate.day() - 1, "day")
          .startOf("day");
        let followingSunday = previousMonday.add(6, "day").endOf("day");
        setEndDate(followingSunday.toDate());
        setStartDate(previousMonday.toDate());
      } else if (value === "month") {
        let date = dayjs(today);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate
          .endOf("month")
          .endOf("day");
        setEndDate(endOfMonthBefore.toDate());
        setStartDate(startOfMonthBefore.toDate());
      } else if (value === "14days") {
        let date = dayjs(today);
        let fourteenDaysBefore = date.subtract(14, "day").startOf("day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(fourteenDaysBeforeDate);
      } else if (value === "30days") {
        let date = dayjs(today);
        let oneMonthBefore = date.subtract(30, "day").startOf("day");
        let oneMonthBeforeDate = oneMonthBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(oneMonthBeforeDate);
      } else if (value === "60days") {
        let date = dayjs(today);

        let sixtyDaysBefore = date.subtract(60, "day").startOf("day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.toDate();

        setEndDate(oneDaysBeforeDate);
        setStartDate(sixtyDaysBeforeDate);
      } else if (value === "90days") {
        let date = dayjs(today);
        let ninetyDaysBefore = date.subtract(90, "day").startOf("day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(ninetyDaysBeforeDate);
      } else if (value === "6months") {
        let date = dayjs(today);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore.toDate());
        let sixMonthsBefore = date.subtract(6, "month").startOf("month");
        let sixMonthsBeforeDate = sixMonthsBefore.toDate();
        setStartDate(sixMonthsBeforeDate);
      } else if (value === "12months") {
        let date = dayjs(today);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore.toDate());
        let oneYearBefore = date.subtract(12, "month").startOf("month");
        let oneYearBeforeDate = oneYearBefore.toDate();
        setStartDate(oneYearBeforeDate);
      } else if (value === "custom") {
        const today = new Date();
        const previousYear = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        setEndDate(today);
        setStartDate(previousYear);
      }
    }
    if (modalFiltersDate.startDate === "" && modalFiltersDate.endDate === "") {
      const today = new Date();
      const previousYear = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      setModalFiltersDate({
        startDate: previousYear,
        endDate: today,
      });
    }
  };

  useEffect(() => {
    let value = {
      target: {
        value: dateRange,
      },
    };
    handleDateRangChange(value);
  }, []);

  const handleSubmit = (data) => {
    let value = {
      target: {
        value: data.timePeriod,
      },
    };
    handleDateRangChange(value);
    setModalFilters(data);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailTitle(value);
    setTitleError(value.trim() === "");
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "50%",
              height: "550px",
            },
          }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <Grid container sx={{ padding: "12px" }}>
            <Grid item xs={10} sm={10} md={11} lg={11}>
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                  marginTop: "5px",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <IconButton
                autoFocus
                onClick={handleCloseNewRequestModel}
                className="delete_button"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
          <DialogContent
            dividers
            sx={{
              "& .MuiDialogContent-root": {
                padding: "12px",
              },
            }}
          >
            {loadingXlsReport ? (
              <Loader />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.emailReportLabel}>
                    {t("choose_a_formate_heading")}
                  </Typography>
                </Grid>
                {/* <CommonButton
                  label={"Download Pdf"}
                  onSubmit={() => handleDownloadPdf(ratingVarianceRef)}
                /> */}
                <Grid item xs={12}>
                  <Grid container sx={{ marginLeft: "-10px" }}>
                    <Grid item xs={3}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onChange={handleChangePdfChecked}
                          checked={pdfChecked}
                        />
                        <Typography>{"PDF"}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onChange={handleChangeXlsChecked}
                          checked={xlsChecked}
                        />
                        <Typography>{"XLS"}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </Grid>
                {pdfChecked && (
                  <Grid item xs={12} lg={8}>
                    <Box
                      className={classes.reviewsubHeadBox}
                      sx={
                        {
                          //  marginLeft: "12px",
                          //  marginBottom: "8px",
                        }
                      }
                    >
                      <Grid container>
                        <Grid item xs={1} sm={1} md={0.8} lg={0.8}>
                          <InfoOutlinedIcon
                            className={classes.scheduleReviewsIcon}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={11}
                          sm={11}
                          md={11.2}
                          lg={11.2}
                          className={classes.scheduleReviewInfoGrid}
                        >
                          <Typography
                            className={classes.scheduleReviewInfoTypoEmail}
                          >
                            {t("add_reviews_info")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.emailReportLabel}>
                    {t("upload_logo")}
                  </Typography>

                  <Box mt={3} ml={-3}>
                    <CommonButton
                      onSubmit={handleOpenLogoUploadModal}
                      label={t("Select_logo")}
                    />
                  </Box>
                  <Box>
                    {selectedFile ? (
                      <Box className={classes.fileBoxStyle}>
                        <Typography
                          // onClick={handleReopenModal}
                          className={classes.selectedFileTypo}
                        >
                          {selectedFile?.name}
                        </Typography>
                        <IconButton
                          className={classes.removeFileButton}
                          onClick={() => handleRemoveFile()}
                        >
                          <DeleteIcon
                            fontSize="small"
                            className={classes.deleteIcon}
                          />
                        </IconButton>
                      </Box>
                    ) : (
                      <p>{t("")}</p>
                    )}
                  </Box>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ paddingTop: "0px" }}
                >
                  <Typography className={classes.emailReportSubLabel}>
                    {t("report_name")}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={t("enter_title")}
                    value={emailTitle}
                    fullWidth
                    onChange={handleInputChange}
                    error={titleError}
                    helperText={titleError ? t("Field_req") : ""}
                  />
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ paddingTop: "0px" }}
                ></Grid>

                <Grid
                  item
                  xs={12}
                  sm={4.5}
                  md={4.5}
                  lg={4.5}
                  sx={{ paddingTop: "0px" }}
                >
                  <Typography className={classes.emailReportSubLabel}>
                    {t("date_range")}
                  </Typography>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={dateRange}
                    onChange={(e) => {
                      handleDateRangChange(e);
                    }}
                    fullWidth
                    error={dateRangeError}
                    helperText={dateRangeError ? t("Field_req") : ""}
                    //sx={{ marginTop: "3px" }}
                  >
                    {currentPath === "/user/reviews" && (
                      <MenuItem value="All">{t("All")}</MenuItem>
                    )}

                    <MenuItem value="Today">{t("today")}</MenuItem>
                    <MenuItem value="Yesterday">{t("yesterday")}</MenuItem>
                    <MenuItem value="2days">{t("2days")}</MenuItem>
                    <MenuItem value="7days">{t("7days")}</MenuItem>
                    <MenuItem value="week">{t("week")}</MenuItem>

                    <MenuItem value="14days">{t("14days")}</MenuItem>
                    <MenuItem value="30days">{t("30days")}</MenuItem>
                    <MenuItem value="month">{t("monthValue")}</MenuItem>
                    <MenuItem value="60days">{t("60days")}</MenuItem>
                    <MenuItem value="90days">{t("90days")}</MenuItem>
                    <MenuItem value="6months">{t("6months")}</MenuItem>
                    <MenuItem value="12months">{t("12months")}</MenuItem>
                    <MenuItem value="custom">{t("custom_text")}</MenuItem>
                  </Select>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4.5}
                  md={4.5}
                  lg={4.5}
                  sx={{ paddingTop: "0px" }}
                >
                  <Typography className={classes.emailReportSubLabel}>
                    {t("report_language")}
                  </Typography>
                  <Select
                    required
                    // defaultValue={"en"}
                    style={{ width: "100%" }}
                    key={languageSchedular?.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={languageSchedular?.value}
                    // label="Select"
                    onChange={(e, value) => {
                      const userLang = languagesData?.find(
                        (item) => item?.value === e.target.value
                      );
                      handleChangeLanguageForScheduler(userLang);
                    }}
                  >
                    {languagesData.map((item) => (
                      <MenuItem value={item.value}>{item.language}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  sx={{ paddingTop: "0px" }}
                >
                  <Box sx={{ marginTop: "25px" }}>
                    <CommonButton
                      icon={<FilterAltIcon sx={{ color: "#FFFF" }} />}
                      onSubmit={handleOpenReviewsFilterModel}
                      label={t("Filters")}
                    />
                  </Box>
                </Grid>
                {dateRange === "custom" && (
                  <>
                    <DatePickerComponent
                      handleChangeStartDate={handleChangeRangeStartDateSchedule}
                      handleChangeEndDate={handleChangeRangeEndDateSchedule}
                      startDate={modalFiltersDate.startDate}
                      endDate={modalFiltersDate.endDate}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      fromFilters={true}
                      rangeValue={"between"}
                    />
                  </>
                )}
              </Grid>
            )}
          </DialogContent>

          <DialogActions sx={{ marginRight: "12px" }}>
            <CommonButton
              label={t("Cancel")}
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
            />
            <CommonButton
              label={t("download")}
              icon={<FileDownloadOutlinedIcon />}
              onSubmit={handleDownloadXLSReport}
              disabled={
                pdfChecked === false && xlsChecked === false
                  ? true
                  : loadingXlsReport === true
                  ? true
                  : // : emailTitle === ""
                    // ? true
                    false
              }
              isLoading={loadingXlsReport}
            />
          </DialogActions>
        </Dialog>
        {openReviewsFilterModel && (
          <FilterModal
            open={openReviewsFilterModel}
            close={handleCloseReviewsFilterModel}
            groups={allGroups}
            tags={allTags}
            sources={allReviewSource}
            locations={allLocations}
            setFilterObj={() => {}}
            modalFilters={modalFilters}
            modalFiltersDate={modalFiltersDate}
            setModalFiltersDate={setModalFiltersDate}
            setModalFilters={handleSubmit}
            checked={checked}
            setChecked={() => {}}
            setCallApi={() => {}}
          />
          // <FilterModelReviews
          //   open={openReviewsFilterModel}
          //   handleCloseFilterModel={handleCloseReviewsFilterModel}
          //   scheduleLocations={scheduleLocations}
          //   setScheduleLocations={setScheduleLocations}
          //   scheduleGroups={scheduleGroups}
          //   setScheduleGroups={setScheduleGroups}
          //   scheduleReviewSource={scheduleReviewSource}
          //   setScheduleReviewSource={setScheduleReviewSource}
          //   scheduleTags={scheduleTags}
          //   setScheduleTags={setScheduleTags}
          //   scheduleRatingValue={scheduleRatingValue}
          //   setScheduleRatingValue={setScheduleRatingValue}
          //   scheduleReviewerName={scheduleReviewerName}
          //   setScheduleReviewerName={setScheduleReviewerName}
          //   scheduleReply={scheduleReply}
          //   setScheduleReply={setScheduleReply}
          //   allLocations={allLocations}
          //   allGroups={allGroups}
          //   allReviewSource={allReviewSource}
          //   starRatings={starRatings}
          //   allTags={allTags}
          //   dateRange={dateRange}
          //   setDateRange={setDateRange}
          //   handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          //   haventReplied={haventReplied}
          //   handleChangeDateRange={handleDateRangChange}
          //   startDate={startDate}
          //   setStartDate={setStartDate}
          //   setEndDate={setEndDate}
          //   endDate={endDate}
          // />
        )}
        {openLogoUploadModal && (
          <LogoImageModal
            title={t("upload_logo")}
            handleClose={handleCloseLogoUploadModal}
            open={openLogoUploadModal}
            selectedImages={images}
            setSelectedImages={setImages}
            setSelectedFile={setSelectedFile}
            setUploadedImages={setUploadedImages}
            uploadedImages={uploadedImages}
          />
        )}
      </div>
    </>
  );
};

export default FetchModel;
