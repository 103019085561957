// ** React Imports ================================================================
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import Link from "@mui/material/Link";
import Person from "../../../../assets/images/person.svg";
import Skull from "../../../../assets/images/Skull.svg";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// ** File Imports ================================================================

import TitleHeader from "../../../../components/CustomComponents/title";
import { api } from "../../../../contexts/JWTContext";
import Loader from "../../../../components/Loaders/Loader";

// ** Material ui Imports ================================================================
import {
  Autocomplete,
  createFilterOptions,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  tooltipClasses,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { Edit, WarningAmberOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import { useStyles } from "../styles/style";
import PostAIModal from "./PromptModel";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import AddBusinessModel from "./Models/AddBusinessEmailModel";
import AddBulkEmailModel from "./Models/bulkUpdateModel";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// ** Other Imports ================================================================

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
//const Alert = styled(MuiAlert)(spacing);

const Ratings = ["one", "two", "three", "four", "five"];

const state = [{ label: "Present" }, { label: "Not Present" }];
const AddRule = () => {
  // ** Others ================================================================
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const classes = useStyles();
  const tagsGridRef = useRef(null);
  const tagsGridRefTwo = useRef(null);
  const langGridRef = useRef(null);
  // ** States ================================================================
  const [dataToEdit, setDataToEdit] = useState(null);
  const [allTemplate, setAllTemplate] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [aiReview, setAIReview] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [templateRequired, setTemplateRequired] = useState(false);
  const [reviewRequired, setReviewRequired] = useState(false);
  const [ratingRequired, setRatingRequired] = useState(false);
  const [selectedReviewOption, setSelectedReviewOption] = useState([]);
  const [reviewPresent, setReviewPresent] = useState(false);

  const [reviewNotPresent, setReviewNotPresent] = useState(false);
  /*   const [message, setMessage] = useState("");
   */ const [errors, setErrors] = useState("");

  const [errorRules, setErrorRules] = useState([]);
  const [editData, setEditData] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectedPrmopt, setSelectedPrompt] = useState("default");
  const [openPrmoptModel, setOpenPrmoptModel] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsNotPresent, setTagsNotPresent] = useState([]);
  const [formalChecked, setFormalChecked] = useState(true);
  const [formalCheckedTwo, setFormalCheckedTwo] = useState(true);
  const [reviewToneChecked, setReviewToneChecked] = useState(false);
  const [reviewToneCheckedTwo, setReviewToneCheckedTwo] = useState(false);
  const [friendlyChecked, setFriendlyChecked] = useState(false);
  const [friendlyCheckedTwo, setFriendlyCheckedTwo] = useState(false);
  const [includeChecked, setIncludeChecked] = useState(false);
  const [includeCheckedTwo, setIncludeCheckedTwo] = useState(false);
  const [excludeChecked, setExcludeChecked] = useState(false);
  const [excludeCheckedTwo, setExcludeCheckedTwo] = useState(false);
  const [yesChecked, setYesChecked] = useState(false);
  const [yesCheckedTwo, setYesCheckedTwo] = useState(false);
  const [noChecked, setNoChecked] = useState(true);
  const [noCheckedTwo, setNoCheckedTwo] = useState(true);
  const [moreLanguageChecked, setMoreLanguageChecked] = useState(false);
  const [moreLanguageCheckedTwo, setMoreLanguageCheckedTwo] = useState(false);
  const [userLanguageChecked, setUserLanguageChecked] = useState(false);
  const [userLanguageCheckedTwo, setUserLanguageCheckedTwo] = useState(true);
  const [userLanguage, setUserLanguage] = useState([]);
  const [userLanguageTwo, setUserLanguageTwo] = useState([]);
  const [reviewLanguageChecked, setReviewLanguageChecked] = useState(true);
  const [shortChecked, setShortChecked] = useState(true);
  const [detailedCheked, setDetailedChecked] = useState(false);
  const [shortCheckedTwo, setShortCheckedTwo] = useState(true);
  const [detailedChekedTwo, setDetailedCheckedTwo] = useState(false);
  const [extraData, setExtraData] = useState("");
  const [displayToast, setDisplayToast] = useState(false);
  const [extraDataTwo, setExtraDataTwo] = useState("");
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [userTranslationLanguage, setUserTranslationLanguage] =
    React.useState(i18nextLng);
  const [userTranslationLanguageTwo, setUserTranslationLanguageTwo] =
    React.useState(i18nextLng);
  const [selectAllPresent, setSelectAllPresent] = useState(false);
  const [selectAllNotPresent, setSelectAllNotPresent] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [singleChecked, setSingleChecked] = useState(false);
  const [singleCheckedTwo, setSingleCheckedTwo] = useState(false);
  const [multiChecked, setMultiChecked] = useState(false);
  const [multiCheckedTwo, setMultiCheckedTwo] = useState(false);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [tagsRequiredTwo, setTagsRequiredTwo] = useState(false);
  const [languageRequired, setLanguageRequired] = useState(false);
  const [languageRequiredTwo, setLanguageRequiredTwo] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [scrollStateTags, setScrollStateTags] = useState(false);
  const [scrollStateTagsTwo, setScrollStateTagsTwo] = useState(false);
  const [scrollStateLang, setScrollStateLang] = useState(false);
  const [scrollStateLangTwo, setScrollStateLangTwo] = useState(false);
  const [locationsToEdit, setLocationsToEdit] = useState([]);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [displayEmailWarning, setDisplayEmailWarning] = useState(false);
  const [openUpdateModel, setOpenUpdateModel] = useState(false);
  const [businessEmailLocations, setBusinessEmailLocations] = useState([]);
  const [openAddEmailModel, setOpenAddEmailModel] = useState(false);
  const [openAddBulkEmailModel, setOpenAddBulkEmailModel] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [alreadySelectedLocation, setAlreadySelectedLocations] = useState([]);

  const [textPresentPrompt, setTextPresentPrompt] = useState(
    `${t("promptText_default_present1")} {{-client-}} ${t(
      "promptText_default_present2"
    )} {{-locationName-}} ${t(
      "promptText_default_present3"
    )} {{-cityName-}} ${t(
      "promptText_default_present4"
    )} {{businessEmail}}) ${t(
      "promptText_default_present6"
    )} {{-ratingStars-}} ${t(
      "promptText_default_present7"
    )} {{-reviewText-}}. ${t(
      "promptText_custom_present1"
    )} {{-reviewer'sLanguage-}}, ${t(
      "promptText_custom_present2"
    )} {{-formalTone-}} ${t("promptText_custom_present3")} ${t(
      "prompt_response_text"
    )} {{-shortResponse-}},  ${t("promptText_custom_and")} {{-withOut-}} ${t(
      "promptText_custom_present7"
    )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
  );
  const [textNotPresentPrompt, setTextNotPresentPrompt] = useState(
    `${t("promptText_default_present1")} {{-client-}} ${t(
      "promptText_default_Notpresent2"
    )} {{-locationName-}} ${t(
      "promptText_default_present3"
    )} {{-cityName-}} ${t(
      "promptText_default_present4"
    )} {{businessEmail}}) ${t(
      "promptText_default_present6"
    )} {{-ratingStars-}}. ${t(
      "promptText_custom_present1"
    )} {{-localLanguage-}}, ${t(
      "promptText_custom_present2"
    )} {{-formalTone-}} ${t("promptText_custom_present3")} ${t(
      "prompt_response_text"
    )} {{-shortResponse-}},  ${t("promptText_custom_and")} {{-withOut-}} ${t(
      "promptText_custom_present7"
    )} {{-selectedExtras-}}.${t("Prompt_for_text")} {{-ratingStars-}} ${t(
      "promptText_custom_present9"
    )} `
  );

  const [modelPresentPrompt, setModelPresentPrompt] =
    useState(textPresentPrompt);
  const [modelNotPresentPrompt, setModelNotPresentPrompt] =
    useState(textNotPresentPrompt);

  /*   useEffect(() => {
    if (id !== undefined) {
      let arr = [];
      if (allLocations?.results?.length > 0 && selectedLocation?.length > 0) {
        allLocations?.results?.forEach((itemOne) => {
          selectedLocation?.forEach((itemTwo) => {
            if (itemOne?.id===itemTwo?.id) {
              arr?.push(itemTwo);
            }
          });
        });
        setLocationsToEdit(selectedLocation);
        if (arr?.length > 0 && arr.length===selectedLocation?.length) {
          setDisableLocations(false);
        } else {
          setDisableLocations(true);
        }
      }
     
    }
  }, [allLocations, selectedLocation]); */

  useEffect(() => {
    if (id !== undefined) {
      getRuleToEdit();
    }
  }, []);

  /*  useEffect(() => {
    if (selectedLocation?.length===0) {
      setLocationRequired(true);
    } else {
      setLocationRequired(false);
    }
  }, [selectedLocation]); */

  useEffect(() => {
    if (id !== undefined) {
      if (selectedLocation?.length > 0 && locationsToEdit?.length > 0) {
        if (selectedLocation?.length === locationsToEdit?.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }
    }
  }, [selectedLocation, locationsToEdit]);

  useEffect(() => {
    if (dataToEdit !== null) {
      let tempLocationArray = [];
      dataToEdit?.ruleLocation?.map((locationItem) => {
        tempLocationArray.push(locationItem?.location);
      });
      const defaultlocation = tempLocationArray?.find(
        (item, index) => item.defaultLocationId !== null
      );
      if (defaultlocation !== null && defaultlocation !== undefined) {
        setDefaultLocation(defaultlocation);
      } else {
        setDefaultLocation(tempLocationArray[0]);
      }

      let arr = [...tempLocationArray];
      allLocations?.results?.map((item) => {
        arr.map((itemTwo) => {
          if (item?.id === itemTwo?.id) {
            itemTwo["businessEmail"] = item?.businessEmail;
          }
        });
      });
      setSelectedLocation(arr);
      //  setSelectedLocation(tempLocationArray);
      setLocationRequired(false);
      if (allLocations?.results !== undefined) {
        const results = tempLocationArray.filter(
          ({ id: id1 }) =>
            !allLocations?.results.some(({ id: id2 }) => id2 === id1)
        );

        const missingEmailLocations = tempLocationArray?.filter(
          (item) => item?.businessEmail === "" || item?.businessEmail === null
        );

        const mergedArraySelected = results.concat(missingEmailLocations);

        setAlreadySelectedLocations(mergedArraySelected);

        if (results?.length > 0) {
          setDisplayWarning(true);
        } else {
          setDisplayWarning(false);
        }
        const mergedArray = [...tempLocationArray, ...allLocations?.results];
        let uniqueArray = mergedArray.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setLocationsToEdit(uniqueArray);
      }

      let newTemplate = [];
      dataToEdit?.ruleTemplate?.map((template) => {
        newTemplate.push(template.template);
      });
      setSelectedTemplate(newTemplate);

      let newTempArray = [];
      dataToEdit?.reviewTextPresent === true &&
        newTempArray.push({ label: "Present" });
      dataToEdit?.reviewTextNotPresent === true &&
        newTempArray.push({ label: "Not Present" });

      setSelectedReviewOption(newTempArray);

      //  setSelectedReviewOption(dataToEdit?.reviewTextPresent === true);
      setSelectedRating(dataToEdit?.ratingStar);
      setAIReview(dataToEdit?.isSupervised);
      setTitle(dataToEdit?.title);
      if (dataToEdit?.defaultPrompt === false) {
        setSelectedPrompt("custom");
        setModelNotPresentPrompt(
          dataToEdit?.promptWithOutText !== null
            ? dataToEdit?.promptWithOutText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_Notpresent2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-localLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} ${
                tagsNotPresent?.length > 0 ? tagsNotPresent?.toString() : ""
              } ${t("promptText_custom_present6")} {{-shortResponse-}}, ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
        setTextNotPresentPrompt(
          dataToEdit?.promptWithOutText !== null
            ? dataToEdit?.promptWithOutText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_Notpresent2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-localLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} ${
                tagsNotPresent?.length > 0 ? tagsNotPresent?.toString() : ""
              } ${t("promptText_custom_present6")} {{-shortResponse-}}, ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
        setModelPresentPrompt(
          dataToEdit?.promptWithText !== null
            ? dataToEdit?.promptWithText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_present2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}} ${t(
                "promptText_default_present7"
              )} {{-reviewText-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-localLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} {{${
                tags?.length > 0 ? tags?.toString() : ""
              }}} ${t("promptText_custom_present6")} {{-shortResponse-}},  ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
        setTextPresentPrompt(
          dataToEdit?.promptWithText !== null
            ? dataToEdit?.promptWithText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_present2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}} ${t(
                "promptText_default_present7"
              )} {{-reviewText-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-localLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} {{${
                tags?.length > 0 ? tags?.toString() : ""
              }}} ${t("promptText_custom_present6")} {{-shortResponse-}},  ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
      } else {
        setSelectedPrompt("default");
      }

      if (dataToEdit?.promptWithTextProperties !== null) {
        setExtraData(dataToEdit?.promptWithTextProperties?.extraData);
        setTags(dataToEdit?.promptWithTextProperties?.keywords);
        if (dataToEdit?.promptWithTextProperties?.responseTone === "formal") {
          setFormalChecked(true);
          setFriendlyChecked(false);
          setReviewToneChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.responseTone === "friendly"
        ) {
          setFormalChecked(false);
          setFriendlyChecked(true);
          setReviewToneChecked(false);
        } else {
          setFormalChecked(false);
          setFriendlyChecked(false);
          setReviewToneChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.pronoun === null) {
          setMultiChecked(false);
          setSingleChecked(false);
        } else if (dataToEdit?.promptWithTextProperties?.pronoun === "single") {
          setMultiChecked(false);
          setSingleChecked(true);
        } else {
          setMultiChecked(true);
          setSingleChecked(false);
        }
        if (dataToEdit?.promptWithTextProperties?.style === null) {
          setExcludeChecked(false);
          setIncludeChecked(false);
        } else if (dataToEdit?.promptWithTextProperties?.style === "exclude") {
          setExcludeChecked(true);
          setIncludeChecked(false);
        } else {
          setExcludeChecked(false);
          setIncludeChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.length === "short") {
          setShortChecked(true);
          setDetailedChecked(false);
        } else {
          setShortChecked(false);
          setDetailedChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.signatures === "yes") {
          setYesChecked(true);
          setNoChecked(false);
        } else {
          setYesChecked(false);
          setNoChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.language === "userLanguage") {
          setUserLanguageChecked(true);
          setReviewLanguageChecked(false);
          setMoreLanguageChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.language === "reviewLanguage"
        ) {
          setUserLanguageChecked(false);
          setReviewLanguageChecked(true);
          setMoreLanguageChecked(false);
        } else {
          setUserLanguageChecked(false);
          setReviewLanguageChecked(false);
          setMoreLanguageChecked(true);
          setUserLanguage(dataToEdit?.promptWithTextProperties?.languageName);
        }
      }
      if (dataToEdit?.promptWithOutTextProperties !== null) {
        setExtraDataTwo(dataToEdit?.promptWithOutTextProperties?.extraData);
        setTagsNotPresent(dataToEdit?.promptWithOutTextProperties?.keywords);
        if (
          dataToEdit?.promptWithOutTextProperties?.responseTone === "formal"
        ) {
          setFormalCheckedTwo(true);
          setFriendlyCheckedTwo(false);
          setReviewToneCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.responseTone === "friendly"
        ) {
          setFormalCheckedTwo(false);
          setFriendlyCheckedTwo(true);
          setReviewToneCheckedTwo(false);
        } else {
          setFormalCheckedTwo(false);
          setFriendlyCheckedTwo(false);
          setReviewToneCheckedTwo(true);
        }
        if (dataToEdit?.promptWithOutTextProperties?.style !== null) {
          if (dataToEdit?.promptWithOutTextProperties?.style === "exclude") {
            setExcludeCheckedTwo(true);
            setIncludeCheckedTwo(false);
          } else {
            setExcludeCheckedTwo(false);
            setIncludeCheckedTwo(true);
          }
        } else {
          setExcludeCheckedTwo(false);
          setIncludeCheckedTwo(false);
        }
        if (dataToEdit?.promptWithOutTextProperties?.length === "short") {
          setShortCheckedTwo(true);
          setDetailedCheckedTwo(false);
        } else {
          setShortCheckedTwo(false);
          setDetailedCheckedTwo(true);
        }
        if (dataToEdit?.promptWithOutTextProperties?.signatures === "yes") {
          setYesCheckedTwo(true);
          setNoCheckedTwo(false);
        } else {
          setYesCheckedTwo(false);
          setNoCheckedTwo(true);
        }
        if (
          dataToEdit?.promptWithOutTextProperties?.language === "userLanguage"
        ) {
          setUserLanguageCheckedTwo(true);
          //  setReviewLanguageChecked(false);
          setMoreLanguageCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.language === "reviewLanguage"
        ) {
          setUserLanguageCheckedTwo(false);
          //  setReviewLanguageChecked(true);
          setMoreLanguageCheckedTwo(false);
        } else {
          setUserLanguageCheckedTwo(false);
          //  setReviewLanguageChecked(false);
          setMoreLanguageCheckedTwo(true);
          setUserLanguage(
            dataToEdit?.promptWithOutTextProperties?.languageName
          );
        }
      }
    }
  }, [dataToEdit, allLocations]);

  useEffect(() => {
    const getAllLocations = async () => {
      setIsLoading(true);
      try {
        const res = await api.get(`/location/search`, {
          params: {
            isLocationPage: false,
            // searchTerm: filterWithName,
            // subscriptionIds: temp?.length > 0 ? temp.toString() : "",
          },
        });
        if (res.status === 200) {
          setIsLoading(true);
          setAllLocations(res.data.data);
          if (res.data.data?.results?.length > 0) {
            const filteredData = res.data.data?.results?.filter(
              (item) =>
                item?.businessEmail === null || item?.businessEmail === ""
            );
            setBusinessEmailLocations(filteredData);
            if (filteredData?.length > 0) {
              setDisplayEmailWarning(true);
            } else {
              setDisplayEmailWarning(false);
            }
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    getAllLocations();
  }, []);

  useEffect(() => {
    getAllTemplate();
  }, []);

  useEffect(() => {
    let reviewIncludes = "Present";
    let reviewNotIncludes = "Not Present";

    const reviews = selectedReviewOption?.map((item) => item?.label);
    const reviewPresent = reviews?.includes(reviewIncludes);
    const reviewNotPresent = reviews?.includes(reviewNotIncludes);

    setReviewPresent(reviewPresent);
    setReviewNotPresent(reviewNotPresent);
  }, [selectedReviewOption]);

  useEffect(() => {
    selectedRating?.OneStar === true && setSelectedRating("one");
    selectedRating?.TwoStar === true && setSelectedRating("two");
    selectedRating?.ThreeStar === true && setSelectedRating("three");
    selectedRating?.FourStar === true && setSelectedRating("four");
    selectedRating?.FiveStar === true && setSelectedRating("five");
  }, [selectedRating]);

  const getRuleToEdit = async () => {
    try {
      const res = await api.get(`/rule/${id}`);
      if (res.status === 200) {
        setIsLoading(true);
        setDataToEdit(res?.data?.data);
        setIsLoading(false);
      }
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  function getMatchingItemsWithEmptyEmail(arr1, arr2) {
    const idSetFromArr1 = [...new Set(arr1.map((item) => item.id))];
    const matchingItems = arr2.filter(
      (item) =>
        idSetFromArr1.includes(item.id) &&
        (item.businessEmail === null || item.businessEmail === "")
    );
    return matchingItems.length > 0;
  }

  const updateRule = async () => {
    setUpdateLoading(true);
    // Extracting ids from location and template to send to db =====================
    let templateIds = [];
    selectedTemplate?.map((template) => {
      templateIds.push(template?.id);
    });
    let locationFound = false;

    if (selectedLocation?.length > 0) {
      const matchingItems = getMatchingItemsWithEmptyEmail(
        allLocations?.results,
        selectedLocation
      );
      locationFound = matchingItems;
    }
    if (locationFound) {
      setUpdateLoading(false);
      toast?.error(t("businessEmailError"));
      handleCloseUpdateModel();
    } else {
      let locationIds = [];
      selectedLocation?.map((location) => {
        locationIds.push(location?.id);
      });

      if (title === null) {
        setTitleError(true);
      }
      if (templateIds.length < 1) {
        setTemplateRequired(true);
      }
      if (selectedLocation.length < 1) {
        setLocationRequired(true);
      }
      if (!selectedRating) {
        setRatingRequired(true);
      }
      if (selectedReviewOption.length < 1) {
        setReviewRequired(true);
      }

      let defaultPromptWithText = `${t(
        "promptText_default_present1"
      )} {{-client-}} ${t(
        "promptText_default_present2"
      )} {{-locationName-}} ${t(
        "promptText_default_present3"
      )} {{-cityName-}} ${t(
        "promptText_default_present4"
      )} {{businessEmail}}) ${t(
        "promptText_default_present6"
      )} {{-ratingStars-}} ${t(
        "promptText_default_present7"
      )} {{-reviewText-}}, ${t("promptText_default_present8")}`;
      let defaultPromptWithoutText = `${t(
        "promptText_default_Notpresent1"
      )} {{-client-}} ${t(
        "promptText_default_Notpresent2"
      )} {{-locationName-}} ${t(
        "promptText_default_present3"
      )} {{-cityName-}} ${t(
        "promptText_default_present4"
      )} {{businessEmail}}) ${t(
        "promptText_default_present6"
      )} {{-ratingStars-}}, ${t(
        "promptText_default_Notpresent8"
      )} {{-languageName-}}, ${t("promptText_default_Notpresent9")}`;

      const promptWithTextProperties = {
        responseTone: formalChecked
          ? "formal"
          : friendlyChecked
          ? "friendly"
          : "reviewTone",
        style: excludeChecked ? "exclude" : includeChecked ? "include" : null,
        keywords: tags,
        length: shortChecked ? "short" : "detailed",
        signatures: yesChecked ? "yes" : "no",
        language: userLanguageChecked
          ? "userLanguage"
          : reviewLanguageChecked
          ? "reviewLanguage"
          : "more",
        languageName: moreLanguageChecked ? userLanguage : null,
        extraData: extraData,
        pronoun: singleChecked ? "single" : multiChecked ? "multi" : null,
      };
      const promptWithOutTextProperties = {
        responseTone: formalCheckedTwo
          ? "formal"
          : friendlyCheckedTwo
          ? "friendly"
          : "reviewTone",
        style: excludeCheckedTwo
          ? "exclude"
          : includeCheckedTwo
          ? "include"
          : null,
        keywords: tagsNotPresent,
        length: shortCheckedTwo ? "short" : "detailed",
        signatures: yesCheckedTwo ? "yes" : "no",
        language: userLanguageCheckedTwo ? "userLanguage" : "more",
        languageName: moreLanguageCheckedTwo ? userLanguageTwo : null,
        extraData: extraDataTwo,
        pronoun: singleCheckedTwo ? "single" : multiCheckedTwo ? "multi" : null,
      };

      const data = {
        title,
        templateIds: aiReview === true ? templateIds : null,
        locationIds: locationIds,
        ratingStar: selectedRating,
        reviewTextPresent: reviewPresent,
        reviewTextNotPresent: reviewNotPresent,
        isSupervised: aiReview,
        defaultPrompt: selectedPrmopt === "default" ? true : false,
        promptWithText:
          selectedPrmopt === "default"
            ? defaultPromptWithText
            : textPresentPrompt?.includes("undefined")
            ? textPresentPrompt?.replace("undefined", "")
            : textPresentPrompt,
        promptWithOutText:
          selectedPrmopt === "default"
            ? defaultPromptWithoutText
            : textNotPresentPrompt?.includes("undefined")
            ? textNotPresentPrompt?.replace("undefined", "")
            : textNotPresentPrompt,
        promptWithTextProperties:
          selectedPrmopt === "default" ? null : promptWithTextProperties,
        promptWithOutTextProperties:
          selectedPrmopt === "default" ? null : promptWithOutTextProperties,
      };
      if (aiReview === true) {
        if (
          data?.title !== "" &&
          data?.templateIds?.length > 0 &&
          data?.locationIds?.length > 0 &&
          data?.ratingStar?.length > 0 &&
          selectedRating &&
          selectedReviewOption?.length > 0
        ) {
          try {
            const res = await api.patch(`/rule/${id}`, data);

            /*   if (res.status === 200) {
          setLoading(true);

          navigate("/user/rules");
          setIsLoading(false);
        } */
            if (res.status === 200) {
              toast.success(t("Rule_updated_successfully"));
              navigate("/user/rules");
              setErrors("");
              //  setMessage(res.data.message);
              setUpdateLoading(false);
            }
          } catch (error) {
            setUpdateLoading(false);
            handleCloseUpdateModel();
            //  toast.error(error?.response?.data?.message);
            setErrors(error?.response?.data?.message);
            setErrorRules(error?.response?.data?.data);
            // toast.error("ratingStar with this locationIds already exist");
          }
        }
      } else {
        if (
          data.title !== "" &&
          //  data.templateIds.length > 0 &&
          data?.locationIds?.length > 0 &&
          data?.ratingStar?.length > 0 &&
          selectedRating &&
          selectedReviewOption?.length > 0
        ) {
          try {
            setUpdateLoading(true);
            const res = await api.patch(`/rule/${id}`, data);

            /*   if (res.status === 200) {
          setLoading(true);

          navigate("/user/rules");
          setIsLoading(false);
        } */
            if (res.status === 200) {
              toast.success("Rule updated successfully");
              navigate("/user/rules");
              setErrors("");
              //  setMessage(res.data.message);
              setUpdateLoading(false);
            }

            setIsLoading(false);
          } catch (error) {
            setUpdateLoading(false);
            handleCloseUpdateModel();
            //  toast.error(error?.response?.data?.message);
            setErrors(error?.response?.data?.message);
            setErrorRules(error?.response?.data?.data);
            // toast.error("ratingStar with this locationIds already exist");
          }
        }
      }
    }
  };
  const handleReviewText = (value) => {
    setSelectedReviewOption(value);
  };

  const getAllTemplate = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/template`);
      if (res.status === 200) {
        // setLoading(true);
        setAllTemplate(res?.data?.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const createRule = async () => {
    if (title === null || title === "") {
      setTitleError(true);
      //  return;
    } else {
      setTitleError(false);
    }
    if (selectedTemplate.length < 1) {
      setTemplateRequired(true);
    }
    if (selectedLocation.length < 1) {
      setLocationRequired(true);
    }
    if (!selectedRating) {
      setRatingRequired(true);
    }
    if (selectedReviewOption.length < 1) {
      setReviewRequired(true);
    }

    // if (!selectedTemplate.length || selectedLocation !== undefined) {
    //   toast.warn("please select all the fields");
    //   return;
    // }
    // let temp = [...productFilter];

    // Extracting ids from location and template to send to db =====================
    let templateIds = [];
    selectedTemplate?.map((template) => {
      templateIds.push(template?.id);
    });

    let locationIds = [];
    selectedLocation?.map((location) => {
      locationIds.push(location?.id);
    });
    // ================================================================
    let defaultPromptWithText = `${t(
      "promptText_default_present1"
    )} {{-client-}} ${t("promptText_default_present2")} {{-locationName-}} ${t(
      "promptText_default_present3"
    )} {{-cityName-}} ${t(
      "promptText_default_present4"
    )} {{businessEmail}}) ${t(
      "promptText_default_present6"
    )} {{-ratingStars-}} ${t(
      "promptText_default_present7"
    )} {{-reviewText-}}, ${t("promptText_default_present8")}`;
    let defaultPromptWithoutText = `${t(
      "promptText_default_Notpresent1"
    )} {{-client-}} ${t(
      "promptText_default_Notpresent2"
    )} {{-locationName-}} ${t(
      "promptText_default_present3"
    )} {{-cityName-}} ${t(
      "promptText_default_present4"
    )} {{businessEmail}}) ${t(
      "promptText_default_present6"
    )} {{-ratingStars-}}, ${t(
      "promptText_default_Notpresent8"
    )} {{-languageName-}}, ${t("promptText_default_Notpresent9")}`;

    const promptWithTextProperties = {
      responseTone: formalChecked
        ? "formal"
        : friendlyChecked
        ? "friendly"
        : "reviewTone",
      style: excludeChecked ? "exclude" : includeChecked ? "include" : null,
      keywords: tags,
      length: shortChecked ? "short" : "detailed",
      signatures: yesChecked ? "yes" : "no",
      language: userLanguageChecked
        ? "userLanguage"
        : reviewLanguageChecked
        ? "reviewLanguage"
        : "more",
      languageName: moreLanguageChecked ? userLanguage : null,
      extraData: extraData,
      pronoun: singleChecked ? "single" : multiChecked ? "multi" : null,
    };
    const promptWithOutTextProperties = {
      responseTone: formalCheckedTwo
        ? "formal"
        : friendlyCheckedTwo
        ? "friendly"
        : "reviewTone",
      style: excludeCheckedTwo ? "exclude" : includeChecked ? "include" : null,
      keywords: tagsNotPresent,
      length: shortCheckedTwo ? "short" : "detailed",
      signatures: yesCheckedTwo ? "yes" : "no",
      language: userLanguageCheckedTwo ? "userLanguage" : "more",
      languageName: moreLanguageCheckedTwo ? userLanguageTwo : null,
      extraData: extraDataTwo,
      pronoun: singleCheckedTwo ? "single" : multiCheckedTwo ? "multi" : null,
    };

    const data = {
      title: title,
      templateIds: aiReview === true ? templateIds : null,
      locationIds: locationIds,
      ratingStar: selectedRating,
      reviewTextPresent: reviewPresent,
      reviewTextNotPresent: reviewNotPresent,
      isSupervised: aiReview,
      defaultPrompt: selectedPrmopt === "default" ? true : false,
      promptWithText:
        selectedPrmopt === "default"
          ? defaultPromptWithText
          : textPresentPrompt,
      promptWithOutText:
        selectedPrmopt === "default"
          ? defaultPromptWithoutText
          : textNotPresentPrompt,
      promptWithTextProperties:
        selectedPrmopt === "default" ? null : promptWithTextProperties,
      promptWithOutTextProperties:
        selectedPrmopt === "default" ? null : promptWithOutTextProperties,
    };

    if (aiReview === true) {
      if (
        data?.templateIds?.length > 0 &&
        data?.title !== "" &&
        data?.title !== null &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setIsLoading(true);
          const res = await api.post(`/rule`, data);

          if (res.status === 200) {
            setIsLoading(false);
            setErrors("");
            //   setMessage(res.data.message);
            toast.success(t("Rule_added_successfully"));
            navigate("/user/rules");
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          setErrors(error?.response?.data?.message || "Something went wrong");
          setErrorRules(error?.response?.data?.data);
          //  toast.error(error?.response?.data?.message);
        }
      }
    } else {
      if (
        //  data?.templateIds !== null &&
        data?.title !== "" &&
        data?.title !== null &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setIsLoading(true);
          const res = await api.post(`/rule`, data);

          if (res.status === 200) {
            setIsLoading(false);
            setErrors("");
            //   setMessage(res.data.message);
            toast.success("Rule added successfully");
            navigate("/user/rules");
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          setErrors(error?.response?.data?.message || "Something went wrong");
          setErrorRules(error?.response?.data?.data);
          //  toast.error(error?.response?.data?.message);
        }
      }
    }

    // if (

    // data.templateIds !== null &&
    // data.title !== null &&
    // data.locationIds.length > 0 &&
    // data.ratingStar.length > 0 &&

    //  selectedRating &&
    // selectedReviewOption.length > 0

    //)
  };

  const handleAddTemplate = () => {
    navigate("/user/template");
  };
  const handleSuperviseTemplate = () => {
    setAIReview(true);
  };

  const handleUnsuperviseTemplate = () => {
    setAIReview(false);
  };
  const handleOpenPromptModel = () => {
    setOpenPrmoptModel(true);
  };
  const handleClosePromptModel = () => {
    setOpenPrmoptModel(false);
    handleReset();
  };
  const handleReset = () => {
    if (id === undefined) {
      setTags([]);
      setSingleChecked(false);
      setSingleCheckedTwo(false);
      setMultiChecked(false);
      setMultiCheckedTwo(false);
      setSelectAllPresent(false);
      setSelectAllNotPresent(false);
      setTagsNotPresent([]);
      setFormalChecked(true);
      setFormalCheckedTwo(true);
      setReviewToneChecked(false);
      setReviewToneCheckedTwo(false);
      setFriendlyChecked(false);
      setFriendlyCheckedTwo(false);
      setIncludeChecked(false);
      setIncludeCheckedTwo(false);
      setExcludeChecked(false);
      setExcludeCheckedTwo(false);
      setYesChecked(false);
      setYesCheckedTwo(false);
      setNoChecked(true);
      setNoCheckedTwo(true);
      setMoreLanguageChecked(false);
      setMoreLanguageCheckedTwo(false);
      setUserLanguageChecked(false);
      setUserLanguageCheckedTwo(true);
      setUserLanguage("");
      setUserLanguageTwo("");
      setReviewLanguageChecked(true);
      setShortChecked(true);
      setDetailedChecked(false);
      setShortCheckedTwo(true);
      setDetailedCheckedTwo(false);
      setExtraData("");
      setExtraDataTwo("");
      setDisplayToast(false);
      setModelNotPresentPrompt(textNotPresentPrompt);
      setModelPresentPrompt(textPresentPrompt);
      setUserTranslationLanguage(i18nextLng);
      setUserTranslationLanguageTwo(i18nextLng);
    } else {
      if (dataToEdit?.defaultPrompt === false) {
        setSelectedPrompt("custom");
        setModelNotPresentPrompt(
          dataToEdit?.promptWithOutText !== null
            ? dataToEdit?.promptWithOutText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_Notpresent2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-localLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} ${
                tagsNotPresent?.length > 0 ? tagsNotPresent?.toString() : ""
              } ${t("promptText_custom_present6")} {{-shortResponse-}}, ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
        setTextNotPresentPrompt(
          dataToEdit?.promptWithOutText !== null
            ? dataToEdit?.promptWithOutText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_Notpresent2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-localLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} ${
                tagsNotPresent?.length > 0 ? tagsNotPresent?.toString() : ""
              } ${t("promptText_custom_present6")} {{-shortResponse-}}, ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
        setModelPresentPrompt(
          dataToEdit?.promptWithText !== null
            ? dataToEdit?.promptWithText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_present2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}} ${t(
                "promptText_default_present7"
              )} {{-reviewText-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-reviewer'sLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} {{${
                tags?.length > 0 ? tags?.toString() : ""
              }}} ${t("promptText_custom_present6")} {{-shortResponse-}},  ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
        setTextPresentPrompt(
          dataToEdit?.promptWithText !== null
            ? dataToEdit?.promptWithText
            : `${t("promptText_default_present1")} {{-client-}} ${t(
                "promptText_default_present2"
              )} {{-locationName-}} ${t(
                "promptText_default_present3"
              )} {{-cityName-}} ${t(
                "promptText_default_present4"
              )} {{businessEmail}}) ${t(
                "promptText_default_present6"
              )} {{-ratingStars-}} ${t(
                "promptText_default_present7"
              )} {{-reviewText-}}. ${t(
                "Using_the_pronoun"
              )} {{-selectedPronoun-}}, ${t(
                "promptText_custom_present1"
              )} {{-reviewer'sLanguage-}}, ${t(
                "promptText_custom_present2"
              )} {{-formalTone-}} ${t(
                "promptText_custom_present3"
              )} {{-ratingStars-}} ${t(
                "promptText_custom_present4"
              )} {{-excludePhrase-}} ${t("likeText")} {{${
                tags?.length > 0 ? tags?.toString() : ""
              }}} ${t("promptText_custom_present6")} {{-shortResponse-}},  ${t(
                "promptText_custom_and"
              )} {{-withOut-}} ${t(
                "promptText_custom_present7"
              )} {{-selectedExtras-}}. ${t("promptText_custom_present9")} `
        );
      } else {
        setSelectedPrompt("default");
      }

      if (dataToEdit?.promptWithTextProperties !== null) {
        setExtraData(dataToEdit?.promptWithTextProperties?.extraData);
        setTags(dataToEdit?.promptWithTextProperties?.keywords);
        if (dataToEdit?.promptWithTextProperties?.responseTone === "formal") {
          setFormalChecked(true);
          setFriendlyChecked(false);
          setReviewToneChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.responseTone === "friendly"
        ) {
          setFormalChecked(false);
          setFriendlyChecked(true);
          setReviewToneChecked(false);
        } else {
          setFormalChecked(false);
          setFriendlyChecked(false);
          setReviewToneChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.pronoun === null) {
          setMultiChecked(false);
          setSingleChecked(false);
        } else if (dataToEdit?.promptWithTextProperties?.pronoun === "single") {
          setMultiChecked(false);
          setSingleChecked(true);
        } else {
          setMultiChecked(true);
          setSingleChecked(false);
        }
        if (dataToEdit?.promptWithTextProperties?.style === null) {
          setExcludeChecked(false);
          setIncludeChecked(false);
        } else if (dataToEdit?.promptWithTextProperties?.style === "exclude") {
          setExcludeChecked(true);
          setIncludeChecked(false);
        } else {
          setExcludeChecked(false);
          setIncludeChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.length === "short") {
          setShortChecked(true);
          setDetailedChecked(false);
        } else {
          setShortChecked(false);
          setDetailedChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.signatures === "yes") {
          setYesChecked(true);
          setNoChecked(false);
        } else {
          setYesChecked(false);
          setNoChecked(true);
        }
        if (dataToEdit?.promptWithTextProperties?.language === "userLanguage") {
          setUserLanguageChecked(true);
          setReviewLanguageChecked(false);
          setMoreLanguageChecked(false);
        } else if (
          dataToEdit?.promptWithTextProperties?.language === "reviewLanguage"
        ) {
          setUserLanguageChecked(false);
          setReviewLanguageChecked(true);
          setMoreLanguageChecked(false);
        } else {
          setUserLanguageChecked(false);
          setReviewLanguageChecked(false);
          setMoreLanguageChecked(true);
          setUserLanguage(dataToEdit?.promptWithTextProperties?.languageName);
        }
      }
      if (dataToEdit?.promptWithOutTextProperties !== null) {
        setExtraDataTwo(dataToEdit?.promptWithOutTextProperties?.extraData);
        setTags(dataToEdit?.promptWithOutTextProperties?.keywords);
        if (
          dataToEdit?.promptWithOutTextProperties?.responseTone === "formal"
        ) {
          setFormalCheckedTwo(true);
          setFriendlyCheckedTwo(false);
          setReviewToneCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.responseTone === "friendly"
        ) {
          setFormalCheckedTwo(false);
          setFriendlyCheckedTwo(true);
          setReviewToneCheckedTwo(false);
        } else {
          setFormalCheckedTwo(false);
          setFriendlyCheckedTwo(false);
          setReviewToneCheckedTwo(true);
        }
        if (dataToEdit?.promptWithOutTextProperties?.style === "exclude") {
          setExcludeCheckedTwo(true);
          setIncludeCheckedTwo(false);
        } else {
          setExcludeCheckedTwo(false);
          setIncludeCheckedTwo(true);
        }
        if (dataToEdit?.promptWithOutTextProperties?.length === "short") {
          setShortCheckedTwo(true);
          setDetailedCheckedTwo(false);
        } else {
          setShortCheckedTwo(false);
          setDetailedCheckedTwo(true);
        }
        if (dataToEdit?.promptWithOutTextProperties?.signatures === "yes") {
          setYesCheckedTwo(true);
          setNoCheckedTwo(false);
        } else {
          setYesCheckedTwo(false);
          setNoCheckedTwo(true);
        }
        if (
          dataToEdit?.promptWithOutTextProperties?.language === "userLanguage"
        ) {
          setUserLanguageCheckedTwo(true);
          //  setReviewLanguageChecked(false);
          setMoreLanguageCheckedTwo(false);
        } else if (
          dataToEdit?.promptWithOutTextProperties?.language === "reviewLanguage"
        ) {
          setUserLanguageCheckedTwo(false);
          //  setReviewLanguageChecked(true);
          setMoreLanguageCheckedTwo(false);
        } else {
          setUserLanguageCheckedTwo(false);
          //  setReviewLanguageChecked(false);
          setMoreLanguageCheckedTwo(true);
          setUserLanguage(
            dataToEdit?.promptWithOutTextProperties?.languageName
          );
        }
      }
    }
  };
  const handleSavePrompt = () => {
    let tagsError = false;
    let tagsErrorTwo = false;
    let langError = false;
    let langErrorTwo = false;
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      (excludeChecked || includeChecked) &&
      tags?.length === 0
    ) {
      setTagsRequired(true);
      setScrollStateTags(true);

      tagsError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      (excludeCheckedTwo || includeCheckedTwo) &&
      tagsNotPresent?.length === 0
    ) {
      setTagsRequiredTwo(true);
      setScrollStateTagsTwo(true);

      tagsErrorTwo = true;
    } else {
      setTagsRequired(false);
      setScrollStateTags(false);
      setScrollStateTagsTwo(false);

      tagsError = false;
      setTagsRequiredTwo(false);
      tagsErrorTwo = false;
    }
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      moreLanguageChecked &&
      userLanguage?.length === 0
    ) {
      setLanguageRequired(true);
      setScrollStateLang(true);

      langError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      moreLanguageCheckedTwo &&
      userLanguageTwo?.length === 0
    ) {
      setLanguageRequiredTwo(true);
      setScrollStateLangTwo(true);
      langErrorTwo = true;
    } else {
      setLanguageRequiredTwo(false);
      setScrollStateLangTwo(false);
      langError = false;
      setLanguageRequired(false);
      langErrorTwo = false;
    }
    if (
      tagsError === false &&
      tagsErrorTwo === false &&
      langError === false &&
      langErrorTwo === false
    ) {
      setOpenPrmoptModel(false);
      setTextPresentPrompt(modelPresentPrompt);
      setTextNotPresentPrompt(modelNotPresentPrompt);
    }
  };

  const handleClickPrmoptSelection = (key) => {
    setSelectedPrompt(key);
    if (key === "custom") {
      // handleOpenPromptModel();
    }
  };

  function handleDisplayNames(locationId) {
    let locationName = "";

    if (allLocations && allLocations?.results?.length > 0) {
      const location = allLocations?.results?.find(
        (item) => item?.id === locationId
      );
      locationName = location ? location.name : "";
    }

    return locationName;
  }
  function getLocation(locationId) {
    let locationName = "";

    if (allLocations && allLocations?.results?.length > 0) {
      const location = allLocations?.results?.find(
        (item) => item?.id === locationId
      );
      locationName = location ? location : "";
    }

    return locationName;
  }
  const handleOpenUpdateModel = () => {
    if (displayWarning) {
      setOpenUpdateModel(true);
    } else {
      setOpenUpdateModel(false);
      updateRule();
    }
  };
  const handleCloseUpdateModel = () => {
    setOpenUpdateModel(false);
  };

  const handleCloseEmailWaring = () => {
    setDisplayEmailWarning(false);
  };

  const handleOpenAddEmailModel = () => {
    setOpenAddEmailModel(true);
  };
  const handleCloseAddEmailModel = () => {
    setOpenAddEmailModel(false);
  };

  const handleOpenAddBulkEmailModel = () => {
    setOpenAddBulkEmailModel(true);
    // setOpenAddEmailModel(false);
  };
  const handleCloseAddBulkEmailModel = () => {
    setOpenAddBulkEmailModel(false);
  };

  return (
    <>
      {openAddEmailModel && (
        <AddBusinessModel
          open={openAddEmailModel}
          handleCloseAddEmailModel={handleCloseAddEmailModel}
          handleOpenAddBulkEmailModel={handleOpenAddBulkEmailModel}
          setAllLocations={setAllLocations}
          setBusinessEmailLocations={setBusinessEmailLocations}
          setDisplayEmailWarning={setDisplayEmailWarning}
          setSelectAllLocations={setSelectAll}
          setSelectedLocation={setSelectedLocation}
          setLocationRequired={setLocationRequired}
          selectedLocation={selectedLocation}
        />
      )}
      {openAddBulkEmailModel && (
        <AddBulkEmailModel
          open={openAddBulkEmailModel}
          handleCloseAddEmailModel={handleCloseAddBulkEmailModel}
          handleOpenAddBulkEmailModel={handleOpenAddBulkEmailModel}
          setAllLocations={setAllLocations}
          setBusinessEmailLocations={setBusinessEmailLocations}
          setDisplayEmailWarning={setDisplayEmailWarning}
          setSelectAllLocations={setSelectAll}
          handleCloseModel={handleCloseAddEmailModel}
          setLocationsRequired={setLocationRequired}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="add-rule-box1">
            <TitleHeader
              title={t("AutoRule_Title")}
              //  subHeading={t("Rule_Edit")}
              subHeading={""}
              showButton={true}
              createButton={false}
              onClick={id !== undefined ? handleOpenUpdateModel : createRule}
            />
          </Box>
          {errors && (
            <Grid container className={classes.mainContainer}>
              <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                <ErrorOutlineIcon className={classes.errorIcon} />
              </Grid>
              <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.errorText}>
                    {/* {errors} */}
                  </Typography>
                  {errorRules?.length > 0 ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {errorRules?.map((item, index) => (
                        <Typography key={index} className={classes.errorText}>
                          {"•" + " " + item}
                        </Typography>
                      ))}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.errorText}>
                        {"•" + " " + errors}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Paper borderRadius="12px">
            <Box className={classes.addRuleMainBox}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {editData ? (
                  <>
                    <Box>
                      <Typography className={classes.titleRule}>
                        {" "}
                        {t("Title")}{" "}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          // disabled={loading}
                          required
                          placeholder={t("Enter_Title")}
                          defaultValue={title}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              setEditData(false);
                            }
                          }}
                          error={titleError}
                          onChange={(event) => {
                            if (event.target.value.length === 0) {
                              setTitleError(true);
                            } else {
                              setTitleError(false);
                            }
                            setTitle(event.target.value);
                          }}
                          fullWidth
                          variant="outlined"
                        />

                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setEditData(!editData);
                          }}
                          disabled={!title}
                        >
                          {" "}
                          <CheckIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.editTitle}>
                        {" "}
                        {title}
                      </Typography>
                      <CustomWidthTooltip title={t("Edit_Title")}>
                        <IconButton
                          aria-label="edit"
                          sx={{ fontSize: "14px" }}
                          onClick={() => {
                            setEditData(!editData);
                          }}
                        >
                          {" "}
                          <Edit />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Grid>
                  </>
                )}
              </Box>

              <LoadingButton
                name="Save"
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  titleError ||
                  locationRequired ||
                  reviewRequired ||
                  ratingRequired ||
                  (aiReview && templateRequired)
                }
                onClick={id !== undefined ? handleOpenUpdateModel : createRule}
                loading={isLoading}
              >
                {id !== undefined ? <>{t("Update")} </> : <>{t("Save")}</>}
              </LoadingButton>
            </Box>

            <Box className={classes.divider}>
              <Divider />
            </Box>

            {displayWarning && (
              <Box className={classes.toast}>
                <Box className={classes.toastBox}>
                  <Box className={classes.toastBoxBorder}></Box>
                  <Box className={classes.toastBoxContent}>
                    <Box className={classes.toastBoxText}>
                      <WarningAmberOutlined sx={{ color: "#FAB431" }} />
                      <Box sx={{ display: "flex" }}>
                        <Typography>{t("location_error_text")}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {businessEmailLocations?.length > 0 && displayEmailWarning && (
              <Box className={classes.toast}>
                <Box className={classes.toastBox}>
                  <Box className={classes.toastBoxBorder}></Box>
                  <Box className={classes.toastBoxContent}>
                    <Box className={classes.toastBoxText}>
                      <WarningAmberOutlined sx={{ color: "#FAB431" }} />
                      <Box>
                        <Typography>
                          {t("businessEmail_alert")}{" "}
                          {businessEmailLocations?.length > 2 ? (
                            <>
                              {businessEmailLocations
                                .slice(0, 2)
                                .map((item) => item?.internalName)
                                .join(", ")}{" "}
                              {t("promptText_custom_and")}{" "}
                              <span style={{ fontWeight: 700 }}>
                                +
                                {`${businessEmailLocations?.length - 2} ${
                                  businessEmailLocations?.length - 2 === 1
                                    ? t("otherText")
                                    : t("othersText")
                                }`}
                              </span>
                            </>
                          ) : (
                            businessEmailLocations
                              .map((item) => item?.internalName)
                              .join(", ")
                          )}
                          .
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex" }}>
                        <CommonButton
                          label={t("Ignore")}
                          customHeight={true}
                          displayWhite="true"
                          onSubmit={handleCloseEmailWaring}
                        />
                        <CommonButton
                          label={t("Add")}
                          onSubmit={handleOpenAddEmailModel}
                          customHeight={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            <Box className={classes.addRuleLocation}>
              <Typography variant="h4">{t("Locations")}</Typography>
            </Box>
            <Box className={classes.addRuleLocationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Typography sx={{ padding: "8px 0" }}>
                  {t("Location_title")}
                </Typography>

                {id === undefined ? (
                  <Autocomplete
                    multiple
                    error={locationRequired}
                    disablePortal
                    id="combo-box-demo"
                    disableCloseOnSelect
                    options={
                      allLocations?.results?.length > 0
                        ? allLocations?.results
                        : []
                    }
                    //  disabled={disableLocations}
                    value={selectedLocation}
                    onChange={(event, value) => {
                      const filtered = allLocations?.results?.filter(
                        (item, index) =>
                          item.businessEmail !== null &&
                          item.businessEmail !== ""
                      );
                      // Check if 'All' option is clicked
                      if (value?.length > 0) {
                        setLocationRequired(false);
                      } else {
                        setLocationRequired(true);
                      }

                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected
                        if (filtered.length === selectedLocation?.length) {
                          setSelectedLocation([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setSelectedLocation(filtered);

                          const defaultlocation = filtered?.find(
                            (item, index) => item.defaultLocationId !== null
                          );
                          if (
                            defaultlocation !== null &&
                            defaultlocation !== undefined
                          ) {
                            setDefaultLocation(defaultlocation);
                          } else {
                            setDefaultLocation(filtered[0]);
                          }
                          //  setLocationRequired(false);
                        }
                      } else {
                        const filteredAllLoc = allLocations?.results?.filter(
                          (item, index) =>
                            item.businessEmail !== null &&
                            item.businessEmail !== ""
                        );
                        const filtered = value?.filter(
                          (item, index) =>
                            item.businessEmail !== null &&
                            item.businessEmail !== ""
                        );
                        setSelectedLocation(filtered);

                        const defaultlocation = value?.find(
                          (item, index) => item.defaultLocationId !== null
                        );
                        if (
                          defaultlocation !== null &&
                          defaultlocation !== undefined
                        ) {
                          setDefaultLocation(defaultlocation);
                        } else {
                          setDefaultLocation(value[0]);
                        }
                        if (value?.length === filteredAllLoc?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allLocations?.results?.length > 0) {
                        return [
                          { id: 0, internalName: "Select All" },
                          ...filtered,
                        ];
                      } else {
                        return [];
                      }
                    }}
                    defaultValue={selectedLocation}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    sx={{ minWidth: 300 }}
                    /*   getOptionDisabled={(option) => {
                      if (option?.id === 0) {
                        return false;
                      }
                      if (
                        businessEmailLocations?.find(
                          (item) => item?.id === option?.id
                        )
                      ) {
                        return true;
                      }

                      return false;
                    }} */
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          disabled={
                            businessEmailLocations?.find(
                              (item) => item?.id === option?.id
                            ) !== undefined
                          }
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          id="select-all-checkbox"
                          checked={
                            (selectAll && option.id === 0) ||
                            businessEmailLocations?.find(
                              (item) => item?.id === option?.id
                            ) === false ||
                            selected
                          }
                        />

                        <Grid
                          container
                          style={{
                            justifyContent: "space-between",
                            marginTop: businessEmailLocations?.find(
                              (item, index) => item.id === option?.id
                            )
                              ? "6px"
                              : "0px",
                          }}
                        >
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <span
                              style={{
                                textAlign: "start",
                                color: businessEmailLocations?.find(
                                  (item, index) => item.id === option?.id
                                )
                                  ? "#BBBDC1"
                                  : "#1B2430",
                              }}
                            >
                              {`${option?.internalName} ${
                                option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? "(" + option?.addressLine1 + "," + " "
                                  : ""
                              } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={3}
                              sm={3}
                              md={3}
                              lg={3}
                              sx={{ textAlign: "end" }}
                            >
                              <span
                                style={{
                                  textAlign: "center",
                                  height: "80px",
                                  // marginLeft: "8px",
                                  // marginRight: "18px",
                                  background: "#FFE3E3",
                                  padding: "3px 6px 3px 6px",
                                  borderRadius: "800px",
                                  fontSize: "10px",

                                  color: "#FF1616",
                                }}
                              >{`${t("Business email missing")}`}</span>
                            </Grid>
                          )}
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={1}
                              sm={1}
                              md={1}
                              lg={1}
                              sx={{ textAlign: "center" }}
                            >
                              <BootstrapTooltip title={t("add_business_email")}>
                                <AddCircleIcon
                                  onClick={() => handleOpenAddEmailModel()}
                                  sx={{ color: "#06BDFF", cursor: "pointer" }}
                                />
                              </BootstrapTooltip>
                            </Grid>
                          )}
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        error={locationRequired}
                        placeholder={t("Select_Location")}
                        //  defaultValue={{ selectedLocation }}
                      />
                    )}
                  ></Autocomplete>
                ) : (
                  <Autocomplete
                    multiple
                    error={locationRequired}
                    disablePortal
                    clearIcon={<Box sx={{ display: "none" }}></Box>}
                    id="combo-box-demo"
                    disableCloseOnSelect
                    options={locationsToEdit?.length > 0 ? locationsToEdit : []}
                    // disabled={disableLocations}
                    value={selectedLocation}
                    onChange={(event, value) => {
                      // Check if 'All' option is clicked
                      if (value?.length > 0) {
                        setLocationRequired(false);
                      } else {
                        setLocationRequired(true);
                      }

                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected
                        let arr = [...alreadySelectedLocation];

                        const results = locationsToEdit.filter(
                          ({ id: id1 }) =>
                            !alreadySelectedLocation?.some(
                              ({ id: id2 }) => id2 === id1
                            )
                        );

                        const concatArr = results.concat(arr);

                        if (concatArr?.length !== selectedLocation?.length) {
                          setSelectedLocation(concatArr);
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                          setSelectedLocation(arr);
                          const defaultlocation = locationsToEdit?.find(
                            (item, index) => item.defaultLocationId !== null
                          );
                          if (
                            defaultlocation !== null &&
                            defaultlocation !== undefined
                          ) {
                            setDefaultLocation(defaultlocation);
                          } else {
                            setDefaultLocation(locationsToEdit[0]);
                          }
                          setLocationRequired(false);
                        }
                      } else {
                        let arr = [...selectedLocation];
                        let newSelectedArr = [];
                        let addedIds = new Set();

                        value?.forEach((item) => {
                          let found = false;

                          arr.forEach((itemTwo) => {
                            if (item?.id === itemTwo?.id) {
                              if (!addedIds.has(itemTwo?.id)) {
                                newSelectedArr.push(itemTwo);
                                addedIds.add(itemTwo?.id);
                              }
                              found = true;
                            }
                          });

                          if (!found) {
                            const findItem = allLocations?.results?.find(
                              (loc) => loc?.id === item?.id
                            );

                            if (
                              findItem !== undefined &&
                              findItem?.businessEmail !== null &&
                              findItem?.businessEmail !== ""
                            ) {
                              if (!addedIds.has(item?.id)) {
                                newSelectedArr.push(item);
                                addedIds.add(item?.id);
                              }
                            }
                          }
                        });

                        setSelectedLocation(newSelectedArr);
                        const defaultlocation = newSelectedArr?.find(
                          (item, index) => item.defaultLocationId !== null
                        );
                        if (
                          defaultlocation !== null &&
                          defaultlocation !== undefined
                        ) {
                          setDefaultLocation(defaultlocation);
                        } else {
                          setDefaultLocation(newSelectedArr[0]);
                        }
                        if (
                          newSelectedArr?.length === locationsToEdit?.length
                        ) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allLocations?.results?.length > 0) {
                        return [
                          { id: 0, internalName: "Select All" },
                          ...filtered,
                        ];
                      } else {
                        return [];
                      }
                    }}
                    defaultValue={selectedLocation}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    getOptionDisabled={(option) => {
                      if (option?.id === 0) {
                        return false;
                      }

                      if (
                        allLocations?.results?.find(
                          (item) => item?.id === option?.id
                        )
                      ) {
                        return false;
                      }

                      return true;
                    }}
                    sx={{ minWidth: 300 }}
                    // value={locationId ? locationId : []}
                    // onChange={(e, value) => {
                    //   setLocationRequired(false);

                    //   setSelectedLocation(value);
                    // }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          style={{
                            background: "#EBEBEC",
                            border:
                              businessEmailLocations?.find(
                                (item) => item?.id === option?.id
                              ) === undefined
                                ? "none"
                                : "1px solid #FFA2A2",
                            borderRadius: "800px",
                          }}
                          label={
                            option?.internalName
                              ? option?.internalName +
                                " " +
                                "(" +
                                (option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? option?.addressLine1 + "," + " "
                                  : "") +
                                (option?.city ? option?.city : "") +
                                ")"
                              : ""
                          }
                          {...getTagProps({ index })}
                          disabled={
                            allLocations?.results?.find(
                              (item) => item?.id === option?.id
                            )
                              ? false
                              : true
                          }
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          id="select-all-checkbox"
                          checked={selectAll || selected}
                          disabled={
                            option.id === 0 ||
                            (allLocations?.results?.find(
                              (item) => item?.id === option?.id
                            ) &&
                              businessEmailLocations?.find(
                                (item) => item?.id === option?.id
                              ) === undefined)
                              ? false
                              : true
                          }
                          // checked={selected}
                        />
                        <Grid
                          container
                          style={{
                            justifyContent: "space-between",
                            marginTop: businessEmailLocations?.find(
                              (item, index) => item.id === option?.id
                            )
                              ? "6px"
                              : "0px",
                          }}
                        >
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <span
                              style={{
                                textAlign: "start",
                                color: businessEmailLocations?.find(
                                  (item, index) => item.id === option?.id
                                )
                                  ? "#BBBDC1"
                                  : "#1B2430",
                              }}
                            >
                              {`${option?.internalName} ${
                                option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? "(" + option?.addressLine1 + "," + " "
                                  : ""
                              } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={3}
                              sm={3}
                              md={3}
                              lg={3}
                              sx={{ textAlign: "end" }}
                            >
                              <span
                                style={{
                                  textAlign: "center",
                                  height: "80px",
                                  // marginLeft: "8px",
                                  // marginRight: "18px",
                                  background: "#FFE3E3",
                                  padding: "3px 6px 3px 6px",
                                  borderRadius: "800px",
                                  fontSize: "10px",

                                  color: "#FF1616",
                                }}
                              >{`${t("Business email missing")}`}</span>
                            </Grid>
                          )}
                          {businessEmailLocations?.find(
                            (item, index) => item.id === option?.id
                          ) && (
                            <Grid
                              item={1}
                              sm={1}
                              md={1}
                              lg={1}
                              sx={{ textAlign: "center" }}
                            >
                              <BootstrapTooltip title={t("add_business_email")}>
                                <AddCircleIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenAddEmailModel();
                                  }}
                                  sx={{ color: "#06BDFF", cursor: "pointer" }}
                                />
                              </BootstrapTooltip>
                            </Grid>
                          )}
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        error={locationRequired}
                        placeholder={t("Select_Location")}
                        //  defaultValue={{ selectedLocation }}
                      />
                    )}
                  ></Autocomplete>
                )}
              </Paper>
            </Box>
            <Box className={classes.divider}>
              <Divider />
            </Box>

            <Box className={classes.addRuleLocation}>
              <Typography variant="h4">{t("AI_Review_Response")}</Typography>
            </Box>
            <Box className={classes.addRuleLocationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={6} lg={6}>
                    <Typography sx={{ paddingTop: "30px" }}>
                      {t("Review_subhead")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} lg={6}>
                    <>
                      <Grid container spacing={2} sx={{ paddingTop: "8px" }}>
                        <BootstrapTooltip title={t("Unsupervised_text")}>
                          <Grid
                            item
                            xs={5.5}
                            sm={5.5}
                            md={5.5}
                            lg={5.5}
                            sx={{
                              backgroundColor:
                                aiReview === false ? "#E0F7FF" : "white",
                              border:
                                aiReview === false
                                  ? "1px solid #06BDFF"
                                  : "1px solid #E0E0E0",
                              borderRadius: "8px",
                            }}
                            onClick={() => handleUnsuperviseTemplate()}
                          >
                            <Box className={classes.skullBox}>
                              <img src={Skull} alt="Skull Icon" />
                            </Box>
                            <Typography className={classes.unsuperText}>
                              {t("Unsupervised")}
                            </Typography>
                          </Grid>
                        </BootstrapTooltip>

                        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
                        <BootstrapTooltip
                          title={
                            allTemplate?.results?.length === 0
                              ? t("tooltipTextRule")
                              : t("Supervised_text")
                          }
                        >
                          <Grid
                            item
                            xs={5.5}
                            sm={5.5}
                            md={5.5}
                            lg={5.5}
                            sx={{
                              backgroundColor:
                                allTemplate?.results?.length === 0
                                  ? "#F6F6F7"
                                  : aiReview === true
                                  ? "#E0F7FF"
                                  : "#FFFF",
                              border:
                                aiReview === true
                                  ? "1px solid #06BDFF"
                                  : "1px solid #E0E0E0",
                              borderRadius: "8px",
                            }}
                            onClick={() => {
                              if (allTemplate?.results?.length > 0) {
                                handleSuperviseTemplate();
                              }
                            }}
                          >
                            <Box
                              sx={{
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                paddingY: "8px",
                                height: "40px",
                              }}
                            >
                              <img
                                style={{
                                  filter:
                                    allTemplate?.results?.length === 0
                                      ? "blur(2px)"
                                      : "none",
                                }}
                                src={Person}
                                alt="Person Icon"
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontWeight: "500",
                                cursor: "pointer",
                                fontSize: "1.125rem",
                                justifyContent: "center",
                                display: "flex",
                                paddingBottom: "8px",
                                color:
                                  allTemplate?.results?.length === 0
                                    ? "gray"
                                    : "black",
                              }}
                            >
                              {t("Supervised")}
                            </Typography>
                          </Grid>
                        </BootstrapTooltip>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            <Box className={classes.divider}>
              <Divider />
            </Box>

            <Box className={classes.addRuleLocation}>
              <Typography variant="h4">{t("Conditions")}</Typography>
            </Box>
            <Box className={classes.Condition} borderColor="primary.main">
              <Typography variant="h6" marginBottom="2rem">
                {/* {t("Contition_1")} */}
              </Typography>
              <Paper padding="3rem">
                <Grid
                  className={classes.Rating}
                  // sx={{ marginBottom: "1rem" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={6}
                  background="blue"
                >
                  <Grid item md={2}>
                    <Box marginLeft="3rem">
                      <Typography>{t("Rating")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <Box>
                      <Autocomplete
                        error={ratingRequired}
                        onChange={(e, value) => {
                          setRatingRequired(false);
                          setSelectedRating(value);
                        }}
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) =>
                          option.charAt(0).toUpperCase() + option.slice(1)
                        }
                        options={Ratings}
                        sx={{ width: "100%" }}
                        defaultValue={selectedRating && selectedRating}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={ratingRequired}
                            placeholder={t("Select_Rating")}
                            defaultValue={{ selectedRating }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid it md={2}>
                    {/* <Box>
                    <Typography>Rating text is</Typography>
                  </Box> */}
                  </Grid>
                  <Grid item md={2}>
                    <Box className={classes.Reviews}>
                      <Typography>{t("Review_text")}</Typography>
                    </Box>
                  </Grid>

                  <Grid item md={8}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Autocomplete
                        multiple
                        // error={locationRequired}
                        disablePortal
                        id="combo-box-demo"
                        disableCloseOnSelect
                        options={state}
                        value={selectedReviewOption}
                        onChange={(e, valueNew) => {
                          setReviewRequired(false);
                          handleReviewText(valueNew);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        getOptionLabel={(option) => option.label}
                        sx={{ minWidth: 300 }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              // checked={selected}
                            />
                            {option.label}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            name="language"
                            {...params}
                            error={reviewRequired}
                            placeholder={t("Select_Review_Text")}
                          />
                        )}
                      ></Autocomplete>
                      {/*  <Autocomplete
                        multiple
                        disablePortal

                        value={selectedReviewOption}
                        disableCloseOnSelect
                        id="combo-box-demo"
                        onChange={(e, valueNew) => {
                          setReviewRequired(false);
                          handleReviewText(valueNew);
                        }}
                        options={state}
                        // isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              // onChange={(e) => {}}


                            />
                            {option.label}
                          </li>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={reviewRequired}
                            placeholder={t("Select_Review_Text")}
                          />
                        )}
                      /> */}
                    </Box>
                  </Grid>
                  <Grid it md={2}>
                    {/* <Box>
                    <Typography>Met all the conditions</Typography>{" "}
                  </Box> */}
                  </Grid>
                </Grid>
              </Paper>

              {aiReview === true ? (
                <>
                  {/* <Box>
                      <Typography>{t("Auto_Reply")}</Typography>
                    </Box> */}
                  <Paper padding="1rem">
                    <Box className={classes.Auto_Reply}>
                      <Grid item md={3}>
                        <Typography>{t("Auto_Reply")}</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Box>
                          {allTemplate?.results?.length > 0 ? (
                            <Autocomplete
                              error={templateRequired}
                              multiple
                              disablePortal
                              id="combo-box-demo"
                              options={allTemplate?.results}
                              disableCloseOnSelect
                              onChange={(e, value) => {
                                setTemplateRequired(false);
                                // handleChangeTemplate(value);
                                setSelectedTemplate(value);
                              }}
                              getOptionLabel={(option) => option?.title}
                              defaultValue={
                                selectedTemplate && selectedTemplate
                              }
                              sx={{ width: "100%" }}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      // checked={selected}
                                      checked={
                                        selectedTemplate?.findIndex(
                                          (item) => item?.id === option?.id
                                        ) >= 0
                                      }
                                    />
                                    {option?.title}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={templateRequired}
                                  placeholder={t("Select_Template")}
                                />
                              )}
                            />
                          ) : (
                            <Typography>
                              No template found. Click
                              <Link
                                sx={{ cursor: "pointer", color: " #0638C1" }}
                                onClick={() => handleAddTemplate()}
                              >
                                {" "}
                                here{" "}
                              </Link>
                              to add.
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Box>
                  </Paper>
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box className={classes.divider}>
              <Divider />
            </Box>

            {aiReview === false &&
              selectedReviewOption?.length > 0 &&
              selectedLocation?.length > 0 && (
                <>
                  <Box className={classes.addRuleLocation}>
                    <Typography variant="h4">
                      {t("rule_prompt_heading_text")}
                    </Typography>
                  </Box>

                  <Box className={classes.addRuleLocationTitle}>
                    <Paper sx={{ padding: "16px" }}>
                      <Grid container>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          sx={{
                            backgroundColor:
                              selectedPrmopt === "default"
                                ? "#E0F7FF"
                                : "white",
                            border:
                              selectedPrmopt === "default"
                                ? "1px solid #06BDFF"
                                : "1px solid #E0E0E0",
                            borderRadius: "8px",
                            height: "72px",
                            alignItems: "center",
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => handleClickPrmoptSelection("default")}
                        >
                          <Typography className={classes.unsuperTextDefault}>
                            {t("defualt_prompt")}
                          </Typography>
                        </Grid>
                        <Grid xs={0.2} md={0.2} sm={0.2} lg={0.2}></Grid>

                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          sx={{
                            backgroundColor:
                              selectedPrmopt === "custom" ? "#E0F7FF" : "#FFFF",
                            border:
                              selectedPrmopt === "custom"
                                ? "1px solid #06BDFF"
                                : "1px solid #E0E0E0",
                            borderRadius: "8px",
                            height: "72px",
                            alignItems: "center",
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            handleClickPrmoptSelection("custom");
                          }}
                        >
                          <Typography className={classes.unsuperTextDefault}>
                            {t("Customised_prompt")}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        {selectedReviewOption?.some(
                          (item) => item?.label === "Present"
                        ) && (
                          <>
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                              <Typography
                                variant="h4"
                                paddingTop="16px"
                                paddingBottom="8px"
                                // marginLeft="1rem"
                              >
                                {t("prompt_text_present_heading")}
                              </Typography>
                            </Grid>
                            {selectedPrmopt === "custom" &&
                              (selectedReviewOption?.length === 2 ||
                                selectedReviewOption?.length === 1) && (
                                <Grid
                                  item
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  sx={{ textAlign: "right" }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      color: "#06BDFF",
                                      cursor: "pointer",
                                      paddingTop: "5px",
                                      textAlign: "right",
                                      display: "flex",
                                      fontWeight: 700,
                                      justifyContent: "end",
                                      marginTop: "16px",
                                    }}
                                    onClick={handleOpenPromptModel}
                                  >
                                    {t("Edit_Prompt")}
                                    {/*  <EditIcon sx={{ marginLeft: "3px" }} /> */}
                                  </Typography>
                                </Grid>
                              )}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{
                                border: "1px solid #E0E0E0",
                                padding: "12px",
                                borderRadius: "8px",
                              }}
                            >
                              {selectedPrmopt === "default" ? (
                                <Typography>{`${t(
                                  "promptText_default_present1"
                                )} {{-client-}} ${t(
                                  "promptText_default_present2"
                                )} {{-locationName-}} ${t(
                                  "promptText_default_present3"
                                )} {{-cityName-}} ${t(
                                  "promptText_default_present4"
                                )} {{businessEmail}}) ${t(
                                  "promptText_default_present6"
                                )} {{-ratingStars-}} ${t(
                                  "promptText_default_present7"
                                )} {{-reviewText-}}, ${t(
                                  "promptText_default_present8"
                                )}`}</Typography>
                              ) : (
                                <Typography>{textPresentPrompt}</Typography>
                              )}
                            </Grid>
                          </>
                        )}
                        {selectedReviewOption?.some(
                          (item) => item?.label === "Not Present"
                        ) && (
                          <>
                            {" "}
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                              <Typography
                                variant="h4"
                                paddingTop="16px"
                                paddingBottom="8px"
                                // marginLeft="1rem"
                              >
                                {t("prompt_text_not_present")}
                              </Typography>
                            </Grid>
                            {selectedPrmopt === "custom" &&
                              selectedReviewOption?.length === 1 && (
                                <Grid
                                  item
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  sx={{ textAlign: "right" }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      color: "#06BDFF",
                                      cursor: "pointer",
                                      paddingTop: "5px",
                                      textAlign: "right",
                                      display: "flex",
                                      fontWeight: 700,
                                      justifyContent: "end",
                                      marginTop: "16px",
                                    }}
                                    onClick={handleOpenPromptModel}
                                  >
                                    {t("Edit_Prompt")}
                                    {/*  <EditIcon sx={{ marginLeft: "3px" }} /> */}
                                  </Typography>
                                </Grid>
                              )}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{
                                border: "1px solid #E0E0E0",
                                padding: "12px",
                                borderRadius: "8px",
                              }}
                            >
                              {selectedPrmopt === "default" ? (
                                <Typography>
                                  {`${t(
                                    "promptText_default_Notpresent1"
                                  )} {{-client-}} ${t(
                                    "promptText_default_Notpresent2"
                                  )} {{-locationName-}} ${t(
                                    "promptText_default_present3"
                                  )} {{-cityName-}} ${t(
                                    "promptText_default_present4"
                                  )} {{businessEmail}}) ${t(
                                    "promptText_default_present6"
                                  )} {{-ratingStars-}}, ${t(
                                    "promptText_default_Notpresent8"
                                  )} {{-languageName-}}, ${t(
                                    "promptText_default_Notpresent9"
                                  )}`}
                                </Typography>
                              ) : (
                                <Typography>{textNotPresentPrompt}</Typography>
                              )}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Paper>
                  </Box>
                </>
              )}
          </Paper>
        </>
      )}
      <PostAIModal
        onCancel={handleClosePromptModel}
        openDefaultModel={openPrmoptModel}
        textPresentPrompt={textPresentPrompt}
        setTextPresentPrompt={setTextPresentPrompt}
        tags={tags}
        setTags={setTags}
        textNotPresentPrompt={textNotPresentPrompt}
        setTextNotPresentPrompt={setTextNotPresentPrompt}
        tagsNotPresent={tagsNotPresent}
        setTagsNotPresent={setTagsNotPresent}
        selectedReviewOption={selectedReviewOption}
        formalChecked={formalChecked}
        setFormalChecked={setFormalChecked}
        reviewToneChecked={reviewToneChecked}
        setReviewToneChecked={setReviewToneChecked}
        friendlyChecked={friendlyChecked}
        setFriendlyChecked={setFriendlyChecked}
        includeChecked={includeChecked}
        setIncludeChecked={setIncludeChecked}
        excludeChecked={excludeChecked}
        setExcludeChecked={setExcludeChecked}
        yesChecked={yesChecked}
        setYesChecked={setYesChecked}
        noChecked={noChecked}
        setNoChecked={setNoChecked}
        moreLanguageChecked={moreLanguageChecked}
        setMoreLanguageChecked={setMoreLanguageChecked}
        userLanguageChecked={userLanguageChecked}
        setUserLanguageChecked={setUserLanguageChecked}
        userLanguage={userLanguage}
        setUserLanguage={setUserLanguage}
        reviewLanguageChecked={reviewLanguageChecked}
        setReviewLanguageChecked={setReviewLanguageChecked}
        shortChecked={shortChecked}
        setShortChecked={setShortChecked}
        detailedCheked={detailedCheked}
        setDetailedChecked={setDetailedChecked}
        extraData={extraData}
        setExtraData={setExtraData}
        formalCheckedTwo={formalCheckedTwo}
        setFormalCheckedTwo={setFormalCheckedTwo}
        reviewToneCheckedTwo={reviewToneCheckedTwo}
        setReviewToneCheckedTwo={setReviewToneCheckedTwo}
        friendlyCheckedTwo={friendlyCheckedTwo}
        setFriendlyCheckedTwo={setFriendlyCheckedTwo}
        includeCheckedTwo={includeCheckedTwo}
        setIncludeCheckedTwo={setIncludeCheckedTwo}
        excludeCheckedTwo={excludeCheckedTwo}
        setExcludeCheckedTwo={setExcludeCheckedTwo}
        yesCheckedTwo={yesCheckedTwo}
        setYesCheckedTwo={setYesCheckedTwo}
        noCheckedTwo={noCheckedTwo}
        setNoCheckedTwo={setNoCheckedTwo}
        moreLanguageCheckedTwo={moreLanguageCheckedTwo}
        setMoreLanguageCheckedTwo={setMoreLanguageCheckedTwo}
        userLanguageCheckedTwo={userLanguageCheckedTwo}
        setUserLanguageCheckedTwo={setUserLanguageCheckedTwo}
        userLanguageTwo={userLanguageTwo}
        setUserLanguageTwo={setUserLanguageTwo}
        shortCheckedTwo={shortCheckedTwo}
        setShortCheckedTwo={setShortCheckedTwo}
        detailedChekedTwo={detailedChekedTwo}
        setDetailedCheckedTwo={setDetailedCheckedTwo}
        extraDataTwo={extraDataTwo}
        setExtraDataTwo={setExtraDataTwo}
        handleSavePrompt={handleSavePrompt}
        Ratings={selectedRating}
        selectedLocation={selectedLocation}
        setDisplayToast={setDisplayToast}
        displayToast={displayToast}
        selectedRating={selectedRating}
        modelPresentPrompt={modelPresentPrompt}
        setModelPresentPrompt={setModelPresentPrompt}
        modelNotPresentPrompt={modelNotPresentPrompt}
        setModelNotPresentPrompt={setModelNotPresentPrompt}
        userTranslationLanguage={userTranslationLanguage}
        setUserTranslationLanguage={setUserTranslationLanguage}
        userTranslationLanguageTwo={userTranslationLanguageTwo}
        setUserTranslationLanguageTwo={setUserTranslationLanguageTwo}
        selectAllPresent={selectAllPresent}
        setSelectAllPresent={setSelectAllPresent}
        selectAllNotPresent={selectAllNotPresent}
        setSelectAllNotPresent={setSelectAllNotPresent}
        handleDisplayNames={handleDisplayNames}
        getLocation={getLocation}
        defaultLocation={defaultLocation}
        singleChecked={singleChecked}
        setSingleChecked={setSingleChecked}
        singleCheckedTwo={singleCheckedTwo}
        setSingleCheckedTwo={setSingleCheckedTwo}
        multiChecked={multiChecked}
        setMultiChecked={setMultiChecked}
        setMultiCheckedTwo={setMultiCheckedTwo}
        multiCheckedTwo={multiCheckedTwo}
        tagsRequired={tagsRequired}
        setTagsRequired={setTagsRequired}
        setTagsRequiredTwo={setTagsRequiredTwo}
        tagsRequiredTwo={tagsRequiredTwo}
        tagsGridRef={tagsGridRef}
        tagsGridRefTwo={tagsGridRefTwo}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        langGridRef={langGridRef}
        langGridRefTwo={langGridRef}
        setLanguageRequired={setLanguageRequired}
        languageRequired={languageRequired}
        languageRequiredTwo={languageRequiredTwo}
        setLanguageRequiredTwo={setLanguageRequiredTwo}
        scrollStateLang={scrollStateLang}
        setScrollStateLang={setScrollStateLang}
        scrollStateLangTwo={scrollStateLangTwo}
        setScrollStateLangTwo={setScrollStateLangTwo}
        scrollStateTags={scrollStateTags}
        setScrollStateTags={setScrollStateTags}
        scrollStateTagsTwo={scrollStateTagsTwo}
        setScrollStateTagsTwo={setScrollStateTagsTwo}
      />
      {openUpdateModel && (
        <Dialog
          open={openUpdateModel}
          onClose={handleCloseUpdateModel}
          sx={{ "& .MuiDialog-paper": { width: "550px", height: "355px" } }}
        >
          <DialogTitle>
            <Box className={classes.dialogBox}>
              <IconButton
                autoFocus
                onClick={handleCloseUpdateModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogBoxContent}>
            <WarningAmberOutlined
              sx={{ color: "#FAB431", height: "80px", width: "80px" }}
            />
            <Typography className={classes.dialogBoxHeading}>
              {t("Save_Changes")}
            </Typography>
            <Typography className={classes.dialogBoxText}>
              {t("save_location_changes")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: "20px" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CommonButton
                  displayWhite="true"
                  onSubmit={handleCloseUpdateModel}
                  label={"No"}
                />

                <CommonButton
                  onSubmit={updateRule}
                  label={"Yes"}
                  isLoading={updateLoading}
                  disabled={updateLoading}
                  className={classes.PostAiButton}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddRule;
