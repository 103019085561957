import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  tooltipClasses,
  Typography,
  Paper,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

import AddIcon from "@mui/icons-material/Add";

import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components/macro";
import Collapsible from "../../../../../components/CustomComponents/Collapsible.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useStyles } from "../../styles/style.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "30px",
  padding: "6px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}));
const SmallCheckbox = styled(Checkbox)({
  // padding: "0px 12px 0px 0px",
  width: "12px",
  height: "12px",
  "& .MuiSvgIcon-root": {
    fontSize: "16px", // Adjust the size of the icon inside the checkbox
  },
});

const FilterModal = ({
  allLocations,
  openModalFilter,
  handleCloseModalFilter,
  allGroups,
  countries,
  locationAddPermission,
  locationViewPermission,
  getCountryWiseLocations,
  setSelectedLocationsBulkUpdate,
  selectedLocationsBulkUpdate,
  title,
  subTitle,
  handleOpenBulkEditModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [isSorted, setIsSorted] = useState("");
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [modellocations, setModelLocations] = useState([]);
  const [modelCountries, setModelCountries] = useState([]);
  const [selectAllModelCountries, setSelectAllModelCountries] = useState(false);
  const [selectedGroupsModel, setSelectedGroupsModel] = useState([]);
  const [oldGroupsModel, setOldGroupsModel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandAllPanels, setExpandAllPanels] = useState(true);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const [selectAllGroupsModel, setSelectAllGroupsModel] = useState(false);
  const [modelOldCountries, setModelOldCountries] = useState(modelCountries);

  useEffect(() => {
    if (selectedGroupsModel?.length > 0) {
      if (!expandedPanels.includes("groups")) {
        expandedPanels.push("groups");
      }
      // handleExpandPanel(undefined, true, "groups");
    } else {
      setExpandedPanels(expandedPanels?.filter((item) => item !== "groups"));
    }
  }, [selectedGroupsModel]);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorBulk(null);
  };
  const handleSorted = (arr, ele) => {
    if (isSorted === "asc") {
      const sortedArr = arr.sort((a, b) => a[ele].localeCompare(b[ele]));
      return sortedArr;
    } else if (isSorted === "desc") {
      const sortedArr = arr.sort((a, b) => b[ele].localeCompare(a[ele]));
      return sortedArr;
    } else {
      return arr;
    }
  };

  function checkCountriesExistence(locationArray, arr) {
    return locationArray?.every((loc) =>
      arr.some((elem) => elem.id === loc.id)
    );
  }

  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }

    let uniqueArray = [...new Set(_expandedPanels)];
    setExpandedPanels(uniqueArray);
  };

  const resetHandler = () => {
    setModelLocations([]);
    setSelectAllGroupsModel(false);
    setSelectAllFeatures(false);
    setSelectAllModelCountries(false);
    setModelCountries([]);
    setSelectedGroupsModel([]);
  };
  const handleChangeCountriesForModel = (value) => {
    setModelCountries(value);

    handleChangeLocationsBySelectingCountries(value);
    /*   } else {
      console.log(modelOldCountries, "oldCountries");
      handleChangeLocationsBySelectingCountries(modelOldCountries);
    } */

    if (value?.length === countries?.length) {
      setSelectAllModelCountries(true);
    } else {
      setSelectAllModelCountries(false);
    }
  };

  const handleSubmitFilters = () => {
    setSelectedLocationsBulkUpdate(modellocations);
    //  handleCloseModalFilter();
    handleOpenBulkEditModel();
    setExpandedPanels([]);
  };

  function handleDisplayDot(countriesArr, locationsArr) {
    let name = countriesArr?.countryName;
    let countriesLocations = countriesArr?.locationsArray;
    const ids1 = new Set(countriesLocations?.map((obj) => obj.id));

    for (let obj of locationsArr) {
      if (ids1.has(obj?.id)) {
        return true;
      }
    }

    return false;
  }

  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item?.locationsArray?.some((loc) => loc.id === targetId)
    );
  }
  function filterArrayByIdForGroups(inputArray, targetId) {
    return inputArray.filter((item) =>
      item?.groups?.some((loc) => loc.id === targetId)
    );
  }
  const handleChangeGroupsModel = (value) => {
    setSelectedGroupsModel(value);
    if (value?.length === allGroups?.rows?.length) {
      setSelectAllGroupsModel(true);
    } else {
      setSelectAllGroupsModel(false);
    }

    let newLocations = [...modellocations];
    const results = oldGroupsModel.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((location) => {
            if (
              groupItem?.locationId === location?.id &&
              isItemFoundInCountries(location?.id) === false
            ) {
              newLocations?.push(location);
            } else {
              // setSelectAll(false);
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  modellocations?.map((location) => {
                    const filteredArray = filterArrayByIdForGroups(
                      value,
                      location?.id
                    );
                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInCountries(location?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          modellocations?.map((location) => {
            if (
              groupItem?.locationId === location?.id &&
              isItemFoundInCountries(location?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== location?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicates(newLocations);

    setModelLocations(uniqueData);

    setOldGroupsModel(value);
  };
  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }

  const handleChangeGroupsForLocationsModel = (locations) => {
    if (modellocations?.length > 0 || selectedGroupsModel?.length > 0) {
      const filteredArray2 = selectedGroupsModel?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectedGroupsModel(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      // setLocationError(false);
    } else {
      //  setSelectAllGroups(false);
      // /  setLocationError(true);
      setSelectedGroupsModel([]);
    }
  };
  const handleChangeCountriesForLocations = (locations) => {
    if (locations?.length > 0 || modelCountries?.length > 0) {
      const filteredArray2 = modelCountries?.filter((item) =>
        item?.locationsArray
          ? checkCountriesExistence(item?.locationsArray, locations)
          : true
      );
      setSelectAllModelCountries(false);
      setModelCountries(filteredArray2 || []);
      // setLocationError(false);
    } else {
      setModelCountries([]);
    }
  };

  const handleChangeLocationModel = (loc) => {
    setModelLocations(loc);
  };

  const handleExpandAllPanels = () => {
    const arr = [...expandedPanels];
    countries?.map((item) => arr.push(item?.countryName));
    const uniqueArray = [...new Set(arr)];
    setExpandedPanels(uniqueArray);

    setExpandAllPanels(false);
  };

  const handleCollapseAllPanelsPanels = () => {
    const filtered = expandedPanels?.filter(
      (item) => item === "groups" || item === "countries"
    );
    setExpandedPanels(filtered);
    setExpandAllPanels(true);
  };

  const handleChangeSelectAllLocations = (e) => {
    setSelectAllFeatures(e.target.checked);
    if (e.target.checked) {
      setModelLocations(allLocations);

      handleExpandAllPanels();
    } else {
      setModelLocations([]);
      handleCollapseAllPanelsPanels();
    }
  };
  const handleSelectAllGroups = (e) => {
    setSelectAllGroupsModel(e.target.checked);
    if (e.target.checked) {
      let arr = [];
      allGroups?.rows?.map((item) => {
        arr.push(item);
      });
      handleChangeGroupsModel(arr);
      expandedPanels?.push("groups");
    } else {
      handleChangeGroupsModel([]);
      handleCollapseAllPanelsPanels();
    }
  };
  const handleChangeAllCountryLocations = (e, locationsArr, countryName) => {
    if (e.target.checked) {
      let arr = [...modellocations];
      let mergedArr = arr.concat(locationsArr);

      const uniqueData = removeDuplicates(mergedArr);
      setModelLocations(uniqueData);
    } else {
      const filtered = modellocations?.filter(
        (item) => item?.countryCode !== countryName
      );
      setModelLocations(filtered);
    }
  };

  const handleCheckForAllLocations = (countryLocations, selectedLocations) => {
    const array2IdSet = new Set(selectedLocations?.map((obj) => obj?.id));
    if (countryLocations?.length > 0) {
      return countryLocations?.every((obj) => array2IdSet.has(obj?.id));
    } else {
      return false;
    }
  };

  function removeDuplicateLocaions(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };
  const isItemFoundInCountries = (locationId) => {
    let isFound = false;
    if (modelCountries?.length > 0) {
      modelCountries?.map((item) => {
        item?.locationsArray?.map((itemTwo) => {
          if (itemTwo?.id === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };
  const isItemFoundInGroups = (locationId) => {
    let isFound = false;

    if (selectedGroupsModel?.length > 0) {
      selectedGroupsModel?.map((item) => {
        item?.groups?.map((itemTwo) => {
          if (itemTwo?.locationId === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeLocationsBySelectingCountries = (value) => {
    let newLocations = [...modellocations];
    const results = modelOldCountries.filter(
      ({ countryName: id1 }) =>
        !value.some(({ countryName: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((itemOne) => {
        itemOne?.locationsArray?.map((locationItem) => {
          allLocations?.map((locItem) => {
            if (
              locationItem?.id === locItem?.id &&
              isItemFoundInGroups(locItem?.id) === false
            ) {
              newLocations?.push(locItem);
            } else {
              // setSelectAll(false);
              results?.map((item) => {
                item?.locationsArray?.map((locItemTwo) => {
                  modellocations?.map((locationItem) => {
                    const filteredArray = filterArrayById(
                      value,
                      locationItem?.id
                    );
                    if (
                      locItemTwo?.id === locationItem?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInGroups(locationItem?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== locationItem?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((item) => {
        item?.locationsArray?.map((locItem) => {
          modellocations?.map((locationItem) => {
            if (
              locItem?.id === locationItem?.id &&
              isItemFoundInGroups(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);

    setModelLocations(uniqueData);

    setModelOldCountries(value);
  };

  const handleSelectAllCountries = (e) => {
    setSelectAllModelCountries(e.target.checked);
    if (e.target.checked) {
      setModelLocations(allLocations);
      setModelCountries(countries);
      handleChangeLocationsBySelectingCountries(countries);
    } else {
      setModelCountries([]);
      handleChangeLocationsBySelectingCountries([]);
    }
  };
  return (
    <Modal
      open={openModalFilter}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container>
        <Grid
          item
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className={classes.styleMainModelContainer}>
            <Box
              sx={{
                pb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography className={classes.mainContainerHeading}>
                  {title}
                </Typography>
                <Typography className={classes.subHeadingStyle}>
                  {subTitle}
                </Typography>
              </Box>

              <IconButton
                autoFocus
                onClick={() => {
                  resetHandler();
                  handleCloseModalFilter();
                  setExpandedPanels([]);
                }}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box className={classes.contentContainer}>
              {/*    <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <CommonButton
                  type="submit"
                  isLoading={false}
                  disabled={false}
                  onSubmit={handleClickMenu}
                  label={
                    isSorted === "asc"
                      ? t("sortA-Z")
                      : isSorted === "desc"
                      ? t("sortZ-A")
                      : t("Sort_By")
                  }
                  icon={<KeyboardArrowDownIcon />}
                  leftMargin={true}
                  customHeight={true}
                  displayWhite={"true"}
                  iconLocation={true}
                  aria-owns={anchorBulk ? "simple-menu" : undefined}
                  aria-haspopup="true"
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorBulk}
                  open={Boolean(anchorBulk)}
                  onClose={handleClose}
                  PaperProps={{
                    sx: {
                      background: "#EBEBEC",
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#DBDBDC",
                      },
                    }}
                    onClick={(e) => {
                      setIsSorted("asc");
                      handleClose();
                    }}
                  >
                    {t("sortA-Z")}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#DBDBDC",
                      },
                    }}
                    onClick={(e) => {
                      setIsSorted("desc");
                      handleClose();
                    }}
                  >
                    {t("sortZ-A")}
                  </MenuItem>
                </Menu>
              </Box> */}

              <>
                <Box>
                  <Collapsible
                    title={t("groups_label")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("groups")}
                    id={"groups"}
                    displayGroupSelectAll={true}
                    handleSelectAllGroups={handleSelectAllGroups}
                    selectedGroupsModel={selectedGroupsModel}
                    selectAllGroups={selectAllGroupsModel}
                    setExpandedPanels={setExpandedPanels}
                    countryNameStyling={selectedGroupsModel?.length > 0}
                    ComponentToRender={
                      <Grid container sx={{ gap: "10px" }}>
                        {allGroups !== null && allGroups?.rows?.length > 0 ? (
                          handleSorted(allGroups?.rows, "name")?.map((item) => (
                            <Item
                              onClick={(e) => {
                                let arr = [...selectedGroupsModel];
                                const selectedgroupFiltered = arr.find(
                                  (group) => group?.id == item?.id
                                );
                                if (selectedgroupFiltered == undefined) {
                                  arr.push(item);
                                  handleChangeGroupsModel(arr);
                                } else {
                                  const filtered = arr.filter(
                                    (group) => group?.id !== item?.id
                                  );
                                  handleChangeGroupsModel(filtered);
                                }
                              }}
                              sx={{
                                padding: "7px 8px 7px 8px",
                                gap: "4px",
                                border: selectedGroupsModel?.find(
                                  (group) => group?.id === item?.id
                                )
                                  ? "1px solid #06BDFF"
                                  : "1px solid #EBEBEC",
                                borderRadius: "800px",
                              }}
                            >
                              <Checkbox
                                checked={
                                  selectedGroupsModel?.length > 0 &&
                                  selectedGroupsModel?.find(
                                    (group) => group?.id === item?.id
                                  ) !== undefined
                                }
                                /*   onChange={(e) => {
                                  let arr = [...selectedGroupsModel];
                                  if (e.target.checked) {
                                    arr.push(item);
                                    handleChangeGroupsModel(arr);
                                  } else {
                                    const filtered = arr.filter(
                                      (group) => group?.id !== item?.id
                                    );
                                    handleChangeGroupsModel(filtered);
                                  }
                                }} */
                                size="small"
                                sx={{
                                  paddingLeft: "2px",
                                  paddingRight: "2px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  color: "#1B2430",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.name}
                              </Typography>
                            </Item>
                          ))
                        ) : (
                          <Grid
                            container
                            sx={{
                              height: "200px",
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid item sm={8} md={8} lg={8}>
                              <Typography
                                sx={{
                                  lineHeight: "28px",
                                  fontWeight: 700,
                                  fontSize: "20px",
                                  color: "#1b2430",
                                  marginTop: "30px",
                                  marginBottom: "15px",
                                }}
                              >
                                {t("No_Groups_Found")}
                              </Typography>
                              <Typography
                                sx={{
                                  lineHeight: "24px",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  color: "#1B2430",

                                  marginBottom: "15px",
                                }}
                              >
                                {t("create_groups_text")}
                              </Typography>
                              {locationAddPermission &&
                              locationViewPermission ? (
                                <CommonButton
                                  onSubmit={handleNavigationToGroups}
                                  customHeight={true}
                                  label={t("create_groups")}
                                  icon={<AddIcon sx={{ color: "#FFFF" }} />}
                                />
                              ) : (
                                <BootstrapTooltip
                                  title={t("authorized_access")}
                                >
                                  <span>
                                    <CommonButton
                                      disabled={true}
                                      onSubmit={handleNavigationToGroups}
                                      customHeight={true}
                                      label={t("create_groups")}
                                      icon={<AddIcon sx={{ color: "#FFFF" }} />}
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    }
                  />
                </Box>
                <Grid>
                  <Collapsible
                    title={t("countries")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("countries")}
                    id={"countries"}
                    countryNameStyling={
                      modelCountries?.length > 0 ? true : false
                    }
                    expandedPanels={expandedPanels}
                    setExpandedPanels={setExpandedPanels}
                    modelCountries={modelCountries}
                    defaultPreventions={true}
                    handleSelectAllGroups={handleSelectAllCountries}
                    selectAllGroups={selectAllModelCountries}
                    displayGroupSelectAll={true}
                    ComponentToRender={
                      <Grid container sx={{ gap: "10px" }}>
                        {countries !== null &&
                          countries?.length > 0 &&
                          handleSorted(countries, "countryName")?.map(
                            (item, i) => (
                              <Item
                                key={i}
                                sx={{
                                  border:
                                    modelCountries.find(
                                      (con) =>
                                        con?.countryName === item?.countryName
                                    ) !== undefined
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                }}
                                onClick={() => {
                                  const itemFound = modelCountries?.find(
                                    (itemTwo) =>
                                      itemTwo?.countryName ===
                                        item?.countryName ||
                                      itemTwo?.countryCode === item?.countryCode
                                  );
                                  let filtered = [...modelCountries];
                                  if (itemFound !== undefined) {
                                    filtered = modelCountries?.filter(
                                      (itemThree) =>
                                        itemThree?.countryName !==
                                        item?.countryName
                                    );
                                    handleChangeCountriesForModel(filtered);
                                  } else {
                                    filtered?.push(item);
                                    handleChangeCountriesForModel(filtered);
                                  }
                                }}
                              >
                                <Checkbox
                                  checked={
                                    modelCountries.find(
                                      (con) =>
                                        con?.countryName === item?.countryName
                                    ) !== undefined
                                  }
                                  size="small"
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#1B2430",
                                  }}
                                >
                                  {item?.countryName}
                                </Typography>
                              </Item>
                            )
                          )}
                      </Grid>
                    }
                  />
                </Grid>

                <Grid
                  container
                  sx={{
                    padding: "8px",
                    border: "1px solid #EBEBEC",
                    borderRadius: "8px",
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 600, fontSize: "1.125rem" }}
                      >
                        {t("Locations")}
                      </Typography>

                      <Box>
                        <SmallCheckbox
                          name="selectAll"
                          color="primary"
                          checked={
                            selectAllFeatures ||
                            modellocations?.length === allLocations?.length
                          }
                          // disabled={defaultRole}
                          onChange={(e) => handleChangeSelectAllLocations(e)}
                          sx={{ marginRight: "4px" }}
                        />
                        {t("All_locationss")}
                        {expandAllPanels ? (
                          <IconButton onClick={handleExpandAllPanels}>
                            <ExpandMoreIcon />
                          </IconButton>
                        ) : (
                          <IconButton onClick={handleCollapseAllPanelsPanels}>
                            <ExpandLessIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid container sx={{ gap: "10px" }}>
                    {countries?.length > 0 &&
                      handleSorted(countries, "countryName")?.map((item) => (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {" "}
                          <Collapsible
                            title={item?.countryName}
                            handleChange={handleExpandPanel}
                            expanded={expandedPanels?.includes(
                              item?.countryName
                            )}
                            expandedPanels={expandedPanels}
                            setExpandedPanels={setExpandedPanels}
                            id={item?.countryName}
                            countryNameStyling={handleDisplayDot(
                              item,
                              modellocations
                            )}
                            displaySelectAll={true}
                            itemData={item}
                            handleCheckForAllLocations={
                              handleCheckForAllLocations
                            }
                            handleChangeAllCountryLocations={
                              handleChangeAllCountryLocations
                            }
                            modellocations={modellocations}
                            countriesArr={item}
                            ComponentToRender={
                              <Grid container sx={{ gap: "10px" }}>
                                {item?.locationsArray?.length > 0 &&
                                  handleSorted(
                                    item?.locationsArray,
                                    "internalName"
                                  )?.map((itemTwo) => (
                                    <Item
                                      onClick={(e) => {
                                        console.log("hereee in onChange");
                                        let arr = [...modellocations];

                                        const selectedItem = arr?.find(
                                          (loc) => loc?.id === itemTwo?.id
                                        );
                                        if (selectedItem === undefined) {
                                          arr.push(itemTwo);
                                          handleChangeLocationModel(arr);

                                          handleChangeGroupsForLocationsModel(
                                            arr
                                          );
                                          handleChangeCountriesForLocations(
                                            arr
                                          );
                                        } else {
                                          const filtered = arr.filter(
                                            (loc) => loc?.id !== itemTwo?.id
                                          );
                                          handleChangeLocationModel(filtered);

                                          handleChangeGroupsForLocationsModel(
                                            filtered
                                          );
                                          handleChangeCountriesForLocations(
                                            arr
                                          );
                                        }
                                      }}
                                      style={{
                                        padding: "12px 8px 12px 8px",
                                        gap: "4px",
                                        border: modellocations?.find(
                                          (item) => item?.id === itemTwo?.id
                                        )
                                          ? "1px solid #06BDFF"
                                          : "1px solid #BBBDC1",
                                        borderRadius: "800px",
                                        background: modellocations?.find(
                                          (item) => item?.id === itemTwo?.id
                                        )
                                          ? "#FFF"
                                          : "#FFF",

                                        //  height: "45px",
                                      }}
                                    >
                                      <Checkbox
                                        checked={
                                          modellocations?.find(
                                            (loc) => loc?.id === itemTwo?.id
                                          ) !== undefined
                                            ? true
                                            : false
                                        }
                                        /*    onChange={(e) => {
                                          let arr = [...modellocations];
                                          if (e.target.checked) {
                                            arr.push(itemTwo);
                                            handleChangeLocationModel(arr);

                                            handleChangeGroupsForLocationsModel(
                                              arr
                                            );
                                            handleChangeCountriesForLocations(
                                              arr
                                            );
                                          } else {
                                            const filtered = arr.filter(
                                              (loc) => loc?.id !== itemTwo?.id
                                            );
                                            handleChangeLocationModel(filtered);

                                            handleChangeGroupsForLocationsModel(
                                              filtered
                                            );
                                            handleChangeCountriesForLocations(
                                              arr
                                            );
                                          }
                                        }} */
                                        size="small"
                                        sx={{
                                          paddingLeft: "2px",
                                          paddingRight: "2px",
                                        }}
                                      />

                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          lineHeight: "18px",
                                          color: modellocations?.find(
                                            (item) => item?.id === itemTwo?.id
                                          )
                                            ? "#1B2430"
                                            : "#1B2430",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {itemTwo.internalName +
                                          (itemTwo?.city !== null &&
                                            itemTwo?.city !== "" &&
                                            " " + "(" + itemTwo?.city + ")")}
                                      </Typography>
                                      {/*   <span
                                        style={{
                                          marginLeft: "10px",
                                          color:
                                            itemTwo?.socialLink?.length === 0 &&
                                            itemTwo?.status === "approved"
                                              ? "#FC3652"
                                              : itemTwo?.status === "requested"
                                              ? "#99cc33"
                                              : itemTwo?.socialLink?.length >
                                                  0 &&
                                                itemTwo?.socialLink?.find(
                                                  (item) =>
                                                    item?.type === "google" &&
                                                    item?.statusOfGoogleInsight ===
                                                      "error"
                                                )
                                              ? "#F86F35"
                                              : itemTwo?.socialLink?.length >
                                                  0 &&
                                                itemTwo?.socialLink?.find(
                                                  (item) =>
                                                    item?.type === "google" &&
                                                    item?.statusOfGoogleInsight ===
                                                      "inProcess"
                                                ) !== undefined
                                              ? "#ffcc00"
                                              : itemTwo?.socialLink?.length >
                                                  0 &&
                                                itemTwo?.socialLink?.find(
                                                  (item) =>
                                                    item?.type === "google" &&
                                                    item?.validToken === false
                                                ) !== undefined
                                              ? "#cc3300"
                                              : "",
                                        }}
                                      >
                                        {itemTwo?.socialLink?.length === 0 &&
                                        itemTwo?.status !== "requested" ? (
                                          <BootstrapTooltip
                                            title={t(
                                              "Connect_With_Google_Text"
                                            )}
                                          >
                                            <IconButton
                                              aria-label="edit"
                                              size="small"
                                            >
                                              <GoogleIcon
                                                sx={{
                                                  color: "#8D9298",
                                                }}
                                              />
                                            </IconButton>
                                          </BootstrapTooltip>
                                        ) : (
                                          ""
                                        )}

                                        {itemTwo?.status === "requested" &&
                                        itemTwo?.socialLink?.length === 0
                                          ? "• " + `${t("requested_state")}`
                                          : ""}

                                        {itemTwo?.status !== "requested" &&
                                        itemTwo?.socialLink?.length > 0 &&
                                        itemTwo?.socialLink?.find(
                                          (item) =>
                                            item?.type === "google" &&
                                            item?.validToken === true
                                        ) !== undefined ? (
                                          <BootstrapTooltip
                                            title={t("Connected_with_google")}
                                          >
                                            <IconButton
                                              aria-label="edit"
                                              size="small"
                                            >
                                              <GoogleIcon
                                                sx={{
                                                  color: "#34A853",
                                                }}
                                              />
                                            </IconButton>
                                          </BootstrapTooltip>
                                        ) : (
                                          ""
                                        )}

                                        {itemTwo?.status !== "requested" &&
                                        itemTwo?.socialLink?.length > 0 &&
                                        itemTwo?.socialLink?.find(
                                          (item) =>
                                            item?.type === "google" &&
                                            item?.validToken === false
                                        ) !== undefined ? (
                                          <BootstrapTooltip
                                            title={t("token_expired")}
                                          >
                                            <IconButton
                                              aria-label="edit"
                                              size="small"
                                            >
                                              <GoogleIcon
                                                sx={{
                                                  color: "#FF1616",
                                                }}
                                              />
                                            </IconButton>
                                          </BootstrapTooltip>
                                        ) : (
                                          ""
                                        )}
                                      </span> */}
                                    </Item>
                                  ))}
                              </Grid>
                            }
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </>
            </Box>
            <Divider />

            <Grid container sx={{ paddingTop: "10px", paddingBottom: "16px" }}>
              <Grid item md={6}></Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    justifyContent: "flex",
                    float: "right",
                  }}
                >
                  <CommonButton
                    displayBlack="true"
                    icon={<RefreshIcon />}
                    label={t("Reset")}
                    customHeight={true}
                    onSubmit={() => {
                      resetHandler();
                      //  handleCloseModalFilter();
                    }}
                  />
                  &nbsp;&nbsp;
                  <CommonButton
                    type="submit"
                    isLoading={loading}
                    disabled={loading || modellocations?.length === 0}
                    onSubmit={handleSubmitFilters}
                    label={t("proceede")}
                    leftMargin={true}
                    customHeight={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FilterModal;

/* openModalFilter && (
  <FilterModal
    open={openModalFilter}
    close={setOpenModalFilter}
    allGroups={allGroups}
  />
); */
